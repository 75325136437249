<template>
  <div class="category-details-page">
    <div class="con">
      <div class="home-menu">
        <form class="search">
          <img src="../assets/icons/search_black_24dp.svg" alt="" />
          <input type="text" name="" placeholder="Search" id="" />
        </form>
        <div class="menu-btn">
          <img src="../assets/icons/tune_black_24dp.svg" alt="" />
        </div>
      </div>
      <div class="categories-filter">
        <p><b>753</b> services found</p>
        <div class="flex">
          <input type="radio" name="filter" id="first" />
          <label for="first" class="tab">Rating</label>
          <input type="radio" name="filter" id="second" />
          <label for="second" class="tab">Location</label>
          <input type="radio" name="filter" id="third" />
          <label for="third" class="tab">Newest</label>
        </div>
      </div>
      <Loader v-if="showLoader" />
      <div v-if="services && !showLoader" class="services">
        <Service :services="services" />
      </div>
      <div v-if="!services && !showLoader">
        <p>No service available for this category</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters, mapActions } from "vuex";
import Service from "../components/Service.vue";
import Loader from "../components/Loader.vue";

export default {
  name: "Home",
  components: { Service, Loader },
  data() {
    return {
      subCategory: null,
      service: null,
      providerServices: null,
      services: null,
      showLoader: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["getAllServicesFromStore"]),
  },
  methods: {
    ...mapActions(["getSubCategoryServicesAction"]),
    likeService(e) {
      e.preventDefault();
      if (!this.user) {
        this.toggleLoginModal();
      }
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    async getSubCategoryServices() {
      this.showLoader = true;
      const subId = this.$route.params.subId;
      this.getSubCategoryServicesAction(subId)
        .then((response) => {
          this.services = response.data.data.data;
          this.showLoader = false;
        })
        .catch((error) => {
          this.showLoader = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSubCategoryServices();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
/* ------------------------- category services page ------------------------- */
.categories-filter {
  margin: 1rem 0;
}
.categories-filter p {
  margin: 0;
  color: $text;
  font-size: 1rem;
}
.categories-filter .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  @include for-large {
    justify-content: flex-start;
  }
}
.categories-filter .flex input {
  display: none;
}
.categories-filter .flex .tab {
  width: 32%;
  padding: 0.5rem 0;
  background-color: $light-gray;
  color: $text;
  font-size: 1rem;
  border-radius: $border-radius;
  text-align: center;
  @include for-large {
    width: fit-content;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
  }
}
.categories-filter .flex input:checked + .tab {
  border: 1px solid $text;
}
</style>
