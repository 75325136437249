<template>
  <div class="con info-page">
    <div class="info">
      <ul>
        <li>
          <div class="left">
            <div class="name">Location</div>
            <div class="redirect-con">
              <div class="redirect">
                <img src="../assets/images/cmr.png" alt="" />
                <span>Cameroon</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
          </div>
        </li>
        <li>
          <div class="left">
            <div class="name">Follow us</div>
          </div>
          <div class="social-links">
            <a href="" target="_blank" class="link">
              <span>Facebook</span>
              <img src="../assets/icons/facebook.png" alt="" />
            </a>
            <a href="" target="_blank" class="link">
              <span>Instagram</span>
              <img src="../assets/icons/instagram.png" alt="" />
            </a>
            <a href="" target="_blank" class="link">
              <span>Youtube</span>
              <img src="../assets/icons/youtube.png" alt="" />
            </a>
            <a href="" target="_blank" class="link">
              <span>Twitter</span>
              <img src="../assets/icons/twitter.png" alt="" />
            </a>
            <a href="" target="_blank" class="link">
              <span>Whatsapp</span>
              <img src="../assets/icons/whatsapp.png" alt="" />
            </a>
            <a href="" target="_blank" class="link">
              <span>LinkedIn</span>
              <img src="../assets/icons/linkedin.png" alt="" />
            </a>
            <a href="" target="_blank" class="link">
              <span>Our Blog</span>
              <img src="../assets/icons/edit.png" alt="" />
            </a>
          </div>
        </li>
        <li>
          <div class="left">
            <div class="name">Terms and Conditions</div>
            <div class="redirect-con">
              <div class="redirect">
                <span>Privacy Policy</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
            <div class="redirect-con">
              <div class="redirect">
                <span>Billing Policy</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
            <div class="redirect-con">
              <div class="redirect">
                <span>Cookie Policy</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
          </div>
        </li>
        <li>
          <div class="left">
            <div class="name">Customer Service</div>
            <div class="redirect-con">
              <div class="redirect">
                <span>Contact Us</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
            <div class="redirect-con">
              <div class="redirect">
                <span>FAQ's</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
            <div class="redirect-con">
              <div class="redirect">
                <span>Safety Guidelines</span>
              </div>
              <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
main {
  margin-top: 70px;
  @include for-tablet {
    margin-top: 90px;
  }
}
.info-page {
  .info {
    margin-bottom: 3rem;
    ul {
      li {
        justify-content: space-between;
        border-bottom: 1px solid $border-color;
        padding: 1rem 0;
        &:last-child {
          margin-bottom: 1rem;
          border-bottom: none;
        }
        .name {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .redirect-con {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.5rem;
          & > img {
            width: 15px;
          }
        }
        .redirect {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 0.5rem;
        }

        .redirect img {
          width: 25px;
          margin-right: 1rem;
        }

        .redirect span {
          font-size: 1rem;
          text-transform: capitalize;
          display: inline-block;
          padding: 0 0.3rem;
          line-height: 0.9;
        }
        .social-links {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
          @include for-large {
            grid-template-columns: repeat(3, 1fr);
          }
          .link {
            width: 100%;
            height: 40px;
            border-radius: $border-radius;
            background-color: $light-gray;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1rem;
            span {
              font-size: 1rem;
            }
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
  .photos {
    width: 100%;
    border-top: 1px solid $border-color;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem 0;
    .img-con {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        object-position: center;
        object-fit: cover;
      }
      .actions {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5rem;
        .action {
          width: 30px;
          height: 30px;
          background-color: $white;
          border-radius: 4px;
          margin-left: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.301);
          img {
            position: relative;
            transform: scale(0.6);
          }
        }
      }
    }
    .add-img {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      border: 2px dashed $text;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    width: fit-content;
  }
  > a.link {
    font-size: 1rem;
    height: fit-content;
    line-height: 1.1;
    border-bottom: 1px solid $primary-color;
  }
}
</style>
