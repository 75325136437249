<template>
  <div class="con favorite-page">
    <Loader v-if="showLoader"/>
    <div v-if="blockRequest">
      <div class="flex">
        <p><b>{{ blockRequest.length }}</b> Blocking requests found</p>
        <a href="" v-if="blockRequest.length">Erase all</a>
      </div>
      <div v-if="blockRequest.length" class="services">
        <div v-for="(request,index) in blockRequest" class="block-user-card" :key="index">
          <div class="top">
            <img v-if="request.blocked_request_user.profile_url" :src="request.blocked_request_user.profile_url" alt="" />
            <img v-else src="../assets/servicehub-avatar.png" alt="" />
            <div class="info">
              <div class="name">{{request.blocked_request_user.name}}</div>
              <p>Member since {{getYear(request.blocked_request_user.created_at)}}</p>
            </div>
          </div>
          <div class="btns">
            <router-link :to="`/block-users/${request.id}`" class="btn btn-light">
              <img src="../assets/icons/place_black_24dp.svg" alt=""/>
              <span>Details</span>
            </router-link>
            <a v-if="request.blocked_request_user.status === 'active'" @click.prevent="blockUsers(request.block_user_id,true)" href="javascript:void(0)" class="btn btn-light" :disabled="submitting">
              <img src="../assets/icons/place_black_24dp.svg" alt=""/>
              <span>Block User</span>
            </a>
            <a v-else-if="request.blocked_request_user.status === 'blocked'" @click.prevent="blockUsers(request.block_user_id,false)" href="javascript:void(0)" class="btn btn-light" :disabled="submitting">
              <img src="../assets/icons/place_black_24dp.svg" alt=""/>
              <span>Unblock User</span>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Loader from "@/components/Loader";

export default {
  data() {
    return {
      blockRequest: null,
      // showLoader: false,
      submitting:false

    };
  },
  components:{
    Loader
  },
  computed: {
    ...mapGetters(["getBlockRequestFromStore","getAllREportsFromStore"]),
    ...mapState({
      showLoader: (state) => state.modals.showLoader
    }),
  },
  mounted() {
    // this.showLoader = true
    this.$store.commit("setLoader",true);
    this.getAllUserReports()
        .then((response) => {
          if (response.status === 200){

            this.blockRequest = response.data.data;
          }
          // this.showLoader = false;
          this.$store.commit("setLoader",false);
        })
        .catch((error) => {
          // this.showLoader = false;
          this.$store.commit("setLoader",false);
          console.log(error)
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        });
  },
  methods: {
    ...mapActions(["getAllUserReports","blockUser"]),
    blockUsers(user_id,status){
      let data  = {"status":status}

      this.blockUser({"user_id":user_id,data}).then((response) => {
          this.$toast.success(response.data.message, {
            position: "top",
          });
         let storeReports = this.getAllREportsFromStore()
        if (storeReports && storeReports.data.length){
          this.blockRequest = storeReports.data;
        }
        }).catch((error) => {
          console.log(error)
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        })


    },
    getYear(dateString){
      let createdAt = new Date(dateString);
      return createdAt.getFullYear();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.favorite-page .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.favorite-page .flex p {
  font-size: 1rem;
  color: $text;
  margin: 0;
}

.favorite-page .flex a {
  text-align: center;
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}
</style>
