<template>
  <div>
    <input
        ref="phoneNumber"
        type="tel"
        name="phone_number"
        :modelValue="modelValue"
        @change="onInput"
    >
  </div>

</template>

<script>
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import axios from "axios";

let countries_code = [
  "dz",
  "ao",
  "bj",
  "bw",
  "bf",
  "bi",
  "cm",
  "cv",
  "cf",
  "td",
  "km",
  "cg",
  "cd",
  "ci",
  "dj",
  "eg",
  "gq",
  "er",
  "et",
  "ga",
  "gm",
  "gh",
  "gn",
  "gw",
  "ke",
  "ls",
  "lr",
  "ly",
  "mg",
  "ml",
  "mw",
  "mr",
  "mu",
  "yt",
  "ma",
  "mz",
  "na",
  "ne",
  "ng",
  "re",
  "rw",
  "st",
  "sn",
  "sc",
  "sl",
  "so",
  "za",
  "ss",
  "sd",
  "sz",
  "tz",
  "tg",
  "tn",
  "ug",
  "eh",
  "zm",
  "zw"
];
export default {
  name: "PhoneNumber",
  props: {
    modelValue: {
      required: true,
    }
  },
  emits: ["update:modelValue",'error'],
  data() {
    return {
      phoneSetUp: undefined
    }
  },
  refs: ['phoneNumber'],
  methods: {
    init() {
      this.phoneSetUp = intlTelInput(this.$refs.phoneNumber, {
        onlyCountries: countries_code,
        // initialCountry: "auto",
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js",
        // geoIpLookup: this.getIp,
        nationalMode: true
      });

    },
    onInput() {
      const formattedNumber = this.phoneSetUp.getNumber();

        this.$emit("update:modelValue", formattedNumber);
        this.$emit("error",this.phoneSetUp.isValidNumber());

    },
    getIp(callback) {
      axios({
        method: "get",
        "url": "https://ip2c.org/s",
      }).then((res) => {
        let country_code = 'cm'
        if (res.status === 200) {
          country_code = res.data.split(";")[1].toLowerCase();
        }
        return country_code
      }).catch(() => {
        return 'cm'
      }).then((res) => callback(res))
    }
  },
  mounted() {
    this.init()
  }
}
</script>
