<template>
  <div class="login-modal" ref="checkEmail">
    <div class="login">
      <div class="con">
        <div class="close">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </div>
        <h1>Check your emails</h1>
        <p>We sent you an email verification to change your password</p>
        <img
          class="check-email"
          src="../assets/images/check-email.png"
          alt=""
        />
        <a href="" class="check-email">
          <img src="@/assets/icons/google.svg" alt="" />
          <span>Open Gmail</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  data() {
    return {};
  },
  methods:{
    hideModal(event) {
      const checkEmail = this.$refs.checkEmail;
      const target = event.target;
      if (checkEmail && checkEmail === target) {
        this.$store.commit("toggleCheckEmailModal");
      }
    },
    closeModal(){
      this.$store.commit("toggleCheckEmailModal",true);
    }
  },
  created() {
    document.addEventListener("click", this.hideModal);
    window.addEventListener('unload', this.closeModal);
  },

};
</script>
