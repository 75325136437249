<template>
  <div class="location-page">
    <div class="con">
      <form class="locations">
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Algeria.png" alt="Algeria flag" />
            <span>Algeria</span>
          </div>
          <input type="radio" name="location" id="Algeria" />
          <label for="Algeria"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Angola.png" alt="Angola flag" />
            <span>Angola</span>
          </div>
          <input type="radio" name="location" id="Angola" />
          <label for="Angola"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Benin.png" alt="Benin flag" />
            <span>Benin</span>
          </div>
          <input type="radio" name="location" id="Benin" />
          <label for="Benin"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Botswana.png"
              alt="Botswana flag"
            />
            <span>Botswana</span>
          </div>
          <input type="radio" name="location" id="Botswana" />
          <label for="Botswana"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Burkina-Faso.png"
              alt="Burkina Faso flag"
            />
            <span>Burkina Faso</span>
          </div>
          <input type="radio" name="location" id="Burkina-Faso" />
          <label for="Burkina-Faso"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Burundi.png" alt="Burundi flag" />
            <span>Burundi</span>
          </div>
          <input type="radio" name="location" id="Burundi" />
          <label for="Burundi"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Cabo-Verde.png"
              alt="Cabo Verde flag"
            />
            <span>Cabo Verde</span>
          </div>
          <input type="radio" name="location" id="Cabo-Verde" />
          <label for="Cabo-Verde"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Cameroon.png"
              alt="Cameroon flag"
            />
            <span>Cameroon</span>
          </div>
          <input type="radio" name="location" id="Cameroon" />
          <label for="Cameroon"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Central-African-Republic.png"
              alt="Central African Republic flag"
            />
            <span>Central African Republic</span>
          </div>
          <input type="radio" name="location" id="Central-African-Republic" />
          <label for="Central-African-Republic"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Chad.png" alt="Chad flag" />
            <span>Chad</span>
          </div>
          <input type="radio" name="location" id="Chad" />
          <label for="Chad"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Comoros.png" alt="Comoros flag" />
            <span>Comoros</span>
          </div>
          <input type="radio" name="location" id="Comoros" />
          <label for="Comoros"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Congo-Democratic-Republic-of.png"
              alt="Democratic Republic of Congo flag"
            />
            <span>Democratic Republic of Congo</span>
          </div>
          <input
            type="radio"
            name="location"
            id="Congo-Democratic-Republic-of"
          />
          <label for="Congo-Democratic-Republic-of"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Congo.png" alt="Congo flag" />
            <span>Congo</span>
          </div>
          <input type="radio" name="location" id="Congo" />
          <label for="Congo"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Cote-d-Ivoire.png"
              alt="Cote d'Ivoire flag"
            />
            <span>Cote d'Ivoire</span>
          </div>
          <input type="radio" name="location" id="Cote-d-Ivoire" />
          <label for="Cote-d-Ivoire"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Djibouti.png"
              alt="Djibouti flag"
            />
            <span>Djibouti</span>
          </div>
          <input type="radio" name="location" id="Djibouti" />
          <label for="Djibouti"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Egypt.png" alt="Egypt flag" />
            <span>Egypt</span>
          </div>
          <input type="radio" name="location" id="Egypt" />
          <label for="Egypt"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Equatorial-Guinea.png"
              alt="Equatorial Guinea flag"
            />
            <span>Equatorial Guinea</span>
          </div>
          <input type="radio" name="location" id="Equatorial-Guinea" />
          <label for="Equatorial-Guinea"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Eritrea.png" alt="Eritrea flag" />
            <span>Eritrea</span>
          </div>
          <input type="radio" name="location" id="Eritrea" />
          <label for="Eritrea"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Eswatini.png"
              alt="Eswatini flag"
            />
            <span>Eswatini</span>
          </div>
          <input type="radio" name="location" id="Eswatini" />
          <label for="Eswatini"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Gabon.png" alt="Gabon flag" />
            <span>Gabon</span>
          </div>
          <input type="radio" name="location" id="Gabon" />
          <label for="Gabon"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Gambia.png" alt="Gambia flag" />
            <span>Gambia</span>
          </div>
          <input type="radio" name="location" id="Gambia" />
          <label for="Gambia"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Ghana.png" alt="Ghana flag" />
            <span>Ghana</span>
          </div>
          <input type="radio" name="location" id="Ghana" />
          <label for="Ghana"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Guinea-Bissau.png"
              alt="Guinea Bissau flag"
            />
            <span>Guinea Bissau</span>
          </div>
          <input type="radio" name="location" id="Guinea-Bissau" />
          <label for="Guinea-Bissau"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Guinea.png" alt="Guinea flag" />
            <span>Guinea</span>
          </div>
          <input type="radio" name="location" id="Guinea" />
          <label for="Guinea"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Kenya.png" alt="Kenya flag" />
            <span>Kenya</span>
          </div>
          <input type="radio" name="location" id="Kenya" />
          <label for="Kenya"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Lesotho.png" alt="Lesotho flag" />
            <span>Lesotho</span>
          </div>
          <input type="radio" name="location" id="Lesotho" />
          <label for="Lesotho"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Liberia.png" alt="Liberia flag" />
            <span>Liberia</span>
          </div>
          <input type="radio" name="location" id="Liberia" />
          <label for="Liberia"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Libya.png" alt="Libya flag" />
            <span>Libya</span>
          </div>
          <input type="radio" name="location" id="Libya" />
          <label for="Libya"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Madagascar.png"
              alt="Madagascar flag"
            />
            <span>Madagascar</span>
          </div>
          <input type="radio" name="location" id="Madagascar" />
          <label for="Madagascar"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Malawi.png" alt="Malawi flag" />
            <span>Malawi</span>
          </div>
          <input type="radio" name="location" id="Malawi" />
          <label for="Malawi"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Mali.png" alt="Mali flag" />
            <span>Mali</span>
          </div>
          <input type="radio" name="location" id="Mali" />
          <label for="Mali"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Mauritania.png"
              alt="Mauritania flag"
            />
            <span>Mauritania</span>
          </div>
          <input type="radio" name="location" id="Mauritania" />
          <label for="Mauritania"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Mauritius.png"
              alt="Mauritius flag"
            />
            <span>Mauritius</span>
          </div>
          <input type="radio" name="location" id="Mauritius" />
          <label for="Mauritius"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Morocco.png" alt="Morocco flag" />
            <span>Morocco</span>
          </div>
          <input type="radio" name="location" id="Morocco" />
          <label for="Morocco"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Mozambique.png"
              alt="Mozambique flag"
            />
            <span>Mozambique</span>
          </div>
          <input type="radio" name="location" id="Mozambique" />
          <label for="Mozambique"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Namibia.png" alt="Namibia flag" />
            <span>Namibia</span>
          </div>
          <input type="radio" name="location" id="Namibia" />
          <label for="Namibia"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Niger.png" alt="Niger flag" />
            <span>Niger</span>
          </div>
          <input type="radio" name="location" id="Niger" />
          <label for="Niger"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Nigeria.png" alt="Nigeria flag" />
            <span>Nigeria</span>
          </div>
          <input type="radio" name="location" id="Nigeria" />
          <label for="Nigeria"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Rwanda.png" alt="Rwanda flag" />
            <span>Rwanda</span>
          </div>
          <input type="radio" name="location" id="Rwanda" />
          <label for="Rwanda"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Sao-Tome-and-Principe.png"
              alt="Sao Tome and Principe flag"
            />
            <span>Sao Tome and Principe</span>
          </div>
          <input type="radio" name="location" id="Sao-Tome-and-Principe" />
          <label for="Sao-Tome-and-Principe"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Senegal.png" alt="Senegal flag" />
            <span>Senegal</span>
          </div>
          <input type="radio" name="location" id="Senegal" />
          <label for="Senegal"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Seychelles.png"
              alt="Seychelles flag"
            />
            <span>Seychelles</span>
          </div>
          <input type="radio" name="location" id="Seychelles" />
          <label for="Seychelles"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Sierra-Leone.png"
              alt="Sierra Leone flag"
            />
            <span>Sierra Leone</span>
          </div>
          <input type="radio" name="location" id="Sierra-Leone" />
          <label for="Sierra-Leone"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-South-Africa.png"
              alt="South Africa flag"
            />
            <span>South Africa</span>
          </div>
          <input type="radio" name="location" id="South-Africa" />
          <label for="South-Africa"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-South-Sudan.png"
              alt="South Sudan flag"
            />
            <span>South Sudan</span>
          </div>
          <input type="radio" name="location" id="South-Sudan" />
          <label for="South-Sudan"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Sudan.png" alt="Sudan flag" />
            <span>Sudan</span>
          </div>
          <input type="radio" name="location" id="Sudan" />
          <label for="Sudan"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Tanzania.png"
              alt="Tanzania flag"
            />
            <span>Tanzania</span>
          </div>
          <input type="radio" name="location" id="Tanzania" />
          <label for="Tanzania"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Togo.png" alt="Togo flag" />
            <span>Togo</span>
          </div>
          <input type="radio" name="location" id="Togo" />
          <label for="Togo"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Tunisia.png" alt="Tunisia flag" />
            <span>Tunisia</span>
          </div>
          <input type="radio" name="location" id="Tunisia" />
          <label for="Tunisia"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Uganda.png" alt="Uganda flag" />
            <span>Uganda</span>
          </div>
          <input type="radio" name="location" id="Uganda" />
          <label for="Uganda"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img src="../assets/flags/flag-of-Zambia.png" alt="Zambia flag" />
            <span>Zambia</span>
          </div>
          <input type="radio" name="location" id="Zambia" />
          <label for="Zambia"></label>
        </div>
        <!-- l -->
        <div class="location">
          <div class="left">
            <img
              src="../assets/flags/flag-of-Zimbabwe.png"
              alt="Zimbabwe flag"
            />
            <span>Zimbabwe</span>
          </div>
          <input type="radio" name="location" id="Zimbabwe" />
          <label for="Zimbabwe"></label>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.location {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @include for-large {
    border-bottom: 1px solid $border-color;
    padding-bottom: 1rem;
  }
  .left {
    display: flex;
    align-items: center;
    font-size: 1rem;
    img {
      width: 40px;
      height: 25px;
      object-fit: cover;
      object-position: center;
      margin-right: 1rem;
      border-radius: 5px;
    }
  }
  label {
    width: 20px;
    height: 20px;
    background-color: $white;
    border: 2px solid $text;
    position: relative;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      transition-duration: 0.3s;
    }
  }
  input {
    display: none;
  }
  input:checked {
    ~ label {
      &::after {
        transform: scale(0.8);
        background-color: $text;
        transition-duration: 0.3s;
      }
    }
  }
}
</style>
