<template>
  <div class="resend-verification">
    <Loader v-if="showLoader" />
    <section v-else >

        <div class="con">
          <div>
            <h1>Resend email verification</h1>
            <form action="" @submit.prevent="submit()">
              <div
                  class="field-con"
                  :class="{ error: errorState && errorState.email }"
              >
                <div class="field">
                  <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      v-model="email"
                  />
                </div>
                <div v-if="errorState && errorState.email" class="error">
                  {{ errorState.email }}
                </div>
              </div>
              <button type="submit">Send</button>
            </form>
          </div>

        </div>

    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions } from "vuex";
import Loader from "../components/Loader.vue";
import router from "../router";
import validator from "validator";
export default {
  name: "ResendVerification",
  components: {Loader},
  data() {
    return {
      showLoader: false,
      email:undefined,
      errorState: null,
    };
  },
  methods: {
    ...mapActions(["resendVerification"]),
    submit(){
      if (this.handleValidation()){
        this.resendVerification({email:this.email})
            .then((response) => {
              if (response.status === 200){
                this.$toast.success(
                    `${response?.data.message}`,
                    {
                      position: "top",
                    }
                );
                setTimeout(()=>{
                  router.push({
                    path: "/",
                  });
                },500)

              }
            })
            .catch((error) => {
              console.log(error.response);
              this.showLoader = false;
              this.$toast.error(error.response.data.message, {
                position: "top",
              });
            });
      }
    },
    handleValidation() {
      let errors = {};
      this.errorState = null;
      let formIsValid = true;
      // validate email
      if (!this.email || this.email?.replace(/^\s+|\s+$/gm, "") === "" ) {
        formIsValid = false;
        errors["email"] = "email is required";
      } else if (!validator.isEmail(this.email)) {
        formIsValid = false;
        errors["email"] = "email should be a valid email";
      }
      this.errorState = errors;
      return formIsValid;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.resend-verification{

  section{
    margin: 6rem 0;
    .con{
      text-align: center;
      width: 30%;
      height: 100%;

      margin: auto;
      @include for-mobile {
        width: 100%;
      }
      h1{
        margin-bottom: 1.5rem;
      }
      form button[type="submit"] {
        background-color: $yellow;
        color: $text;
        font-size: 1rem;
        border-radius: $border-radius;
        height: 50px;
        width: 100%;
        font-weight: 600;
        margin-bottom: 1rem;
        cursor: pointer;
        &:hover {
          background-color: $text;
          color: $white;
        }
      }
      .text-center{
        text-align: center !important;
      }
    }
  }


}

</style>
