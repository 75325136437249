<template>
  <div class="login-modal" ref="resetPassword">
    <div class="login">
      <div class="con">
        <div @click="toggleModal" class="close">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </div>
        <h1>Reset password</h1>
        <p>Did you forgot your password? type your email to reset</p>
        <form action="" @submit.prevent="submit()">
          <div
            class="field-con"
            :class="{ error: errorState && errorState.email }"
          >
            <div class="field">
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                v-model="email"
              />
            </div>
            <div v-if="errorState && errorState.email" class="error">
              {{ errorState.email }}
            </div>
          </div>
          <button type="submit">Send verification</button>
          <p class="text-center">
            Do you remember your password?
            <a @click="toggleLoginModal">Sign In</a>
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import validator from "validator";
import { mapActions } from "vuex";
export default {
  setup() {},
  data() {
    return {
      email: null,
      errorState: null,
    };
  },
  methods: {
    ...mapActions(["forgotPassword"]),
    toggleModal() {
      this.$store.commit("toggleResetPasswordModal");
    },
    toggleRegisterModal() {
      this.$store.commit("toggleRegisterModal");
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    togglePassword() {
      let password = document.getElementById("password");
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },
    submit() {
      if (this.handleValidation()) {
        let data = new FormData();
        data.append("email", this.email);
        this.forgotPassword(data)
          .then((response) => {
            this.$toast.success(`${response?.data.message}`, {
              position: "top",
            });
            this.toggleModal();
          })
          .catch((error) => {
            this.$toast.error("The given email is invalid.", {
              position: "top",
            });
            console.log(error);
          });
      }
    },
    handleValidation() {
      let errors = {};
      this.errorState = null;
      let formIsValid = true;

      // validate email
      if (!this.email) {
        formIsValid = false;
        errors["email"] = "email is required";
      } else if (!validator.isEmail(this.email)) {
        formIsValid = false;
        errors["email"] = "email should be a valid email";
      }
      this.errorState = errors;
      return formIsValid;
    },
    hideModal(event) {
      const resetPassword = this.$refs.resetPassword;
      const target = event.target;
      if (resetPassword && resetPassword === target) {
        this.toggleModal()
      }
    },
    closeModal(){
      this.$store.commit("toggleResetPasswordModal",true);

    }
  },
  created() {
    document.addEventListener("click", this.hideModal);
    window.addEventListener('unload', this.closeModal);
  }
};
</script>
