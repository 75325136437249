import axios from "axios";
import store from "@/store";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

http.interceptors.response.use(response => {
  return response;
}, (error) => {
  let requiresAuth= store._state.data.auth.user;
  if (error?.response.status === 401 && requiresAuth) {
    store.dispatch('logOut2');
  }
  return Promise.reject(error);
});
export {http}