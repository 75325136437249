<template>
  <div class="profile-page">
    <Loader v-if="showLoader" />
    <div class="con">
      <div class="info">
        <img v-if="user.profile_url" :src="user.profile_url" alt="" />
        <img v-else src="../assets/servicehub-avatar.png" alt="" />
        <div class="content">
          <p>Welcome</p>
          <div class="name">{{ user?.name }}</div>
        </div>
      </div>
      <ul class="menu">
        <li>
          <router-link :to="'/users/'+user.id">
            <img src="../assets/icons/settings_black_24dp.svg" alt="" />
            <span>Account</span>
          </router-link>
        </li>
        <li>
          <router-link to="/chats">
            <img src="../assets/icons/chat_black_24dp.svg" alt="" />
            <span>Messages</span>
          </router-link>
        </li>
        <li>
          <a href="">
            <img src="../assets/icons/inventory_2_black_24dp.svg" alt="" />
            <span>Service History</span>
          </a>
        </li>
        <li>
          <a href="">
            <img src="../assets/icons/help_black_24dp.svg" alt="" />
            <span>Help</span>
          </a>
        </li>
        <li>
          <a @click.prevent="checkAuthState">
            <img src="../assets/icons/logout_black_24dp.svg" alt="" />
            <span>Log out</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
/* --------------------------- profile page styles -------------------------- */
.info {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  img {
    width: 100px;
    height: 100px;
    border-radius: $border-radius;
    margin-right: 1rem;
  }
  .content {
    p {
      color: $light-text;
      font-size: 1rem;
    }
    .name {
      font-size: 1.3rem;
      font-weight: 600;
      color: $text;
    }
  }
}
ul {
  li {
    a {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      border-bottom: 1px solid $border-color;
      img {
        width: 20px;
        margin-right: 1rem;
      }
    }
  }
}
</style>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "Profile",
  data() {
    return {
      // showLoader: null,
    };
  },
  components:{
    Loader
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  methods: {
    ...mapActions(["getUserById","logOut2"]),
    ...mapGetters(["isAuthenticated"]),

    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    getUser() {
      this.getUserById(this.$route.params.id)
        .then((response) => {
          this.user = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    init() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      if (!this.isAuthenticated()) {
        this.toggleLoginModal();
      }
      // this.showLoader = false;
      this.$store.commit("setLoader",false);
    },
    checkAuthState() {
      this.logOut2();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

