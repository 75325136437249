<template>
  <div class="pricing-page">
    <Loader v-if="showLoader" />
    <div v-else-if="subscriptionPlans.length" class="con">
      <p class="mb-4">Pick a plan</p>
      <p class="mb-2">Pay upfront and get a {{ discount }}% discount</p>
      <div class="slider mb-4">
        <p class="mr-1">Monthly</p>
        <div id="switch-interval" class="mr-1" @click="toggleInterval">
          <div class="switch-slider" :class="{'year':paymentInterval==='year'}"></div>
        </div>
        <p>Annually</p>
      </div>
      <div class="plans">
        <div v-for="(subscription,index) in  subscriptionPlans"
             :key="index"
             class="plan light"
             :class="{'light': index === 0 , 'black':index > 0 && index < (subscriptionPlans.length -1) ,'yellow':index === (subscriptionPlans.length -1) }"
        >
          <div>
          <div class="top">
            <div class="left">
              <h5>{{ subscription.name }}</h5>
<!--              <p>For {{subscription.userType}}</p>-->
            </div>
            <div v-if="subscription.price < 1" class="free">
              <span>Free</span>
            </div>
            <div v-else class="price"><sup>$</sup>{{planPrice(subscription.price)}}
              <sub v-if="paymentInterval ==='month'">/mo</sub>
              <sub v-else-if="paymentInterval ==='year'">/yr</sub>
            </div>
          </div>
          <div class="mb-4">
            <p>{{subscription.description}}</p>
          </div>
          </div>
          <ul>
            <li v-for="(benefit,benefitIndex) in  subscription.features_desc" :key="benefitIndex">
              <img v-if="index === (subscriptionPlans.length -1) || index === 0" src="../assets/icons/check_box_black_24dp.svg" alt="" />
              <img v-else src="../assets/icons/check_box_white_24dp.svg" alt="" />
              <span class="mr-1">{{benefit.value}}</span>
              <span> {{ formatPlanName(benefit.name) }}</span>
            </li>
          </ul>
          <button @click="makePayment(subscription)" class="btn"
          :class="{'btn-black': index === (subscriptionPlans.length -1) ,'btn-yellow':index < (subscriptionPlans.length -1)}"

          >Select Plan</button>
        </div>
      </div>

    </div>
    <div v-else>
    <p>
      No plans available,contact Admin
    </p>
  </div>
    <payment-modal @toggleLoader="toggleLoader" @hidePaymentModal="showPaymentModal=false" v-if="showPaymentModal && selectedPlan" :subscription="[selectedPlan]"></payment-modal>
  </div>
</template>
<script>
import PaymentModal from "@/components/PaymentModal";
import {mapGetters, mapActions, mapState} from "vuex";
import Loader from "../components/Loader.vue";

export default {
  data(){

    return{
      showPaymentModal: false,
      paymentInterval:'month',
      subscriptionPlans:[],
      selectedPlan:undefined,
      // showLoader:false,
      discount:10
    }

  },
  components:{
    PaymentModal,
    Loader
  },
  methods:{
    ...mapActions([
        "getPaymentPlans",
        ]),
    makePayment(subscription){
      this.selectedPlan = subscription;
      this.selectedPlan['invoice_interval'] = this.paymentInterval;
      if (this.hasSubscribed(this.user.plan,subscription.tag)){
        this.$toast.success(`Subscription already exists.`, {
          position: "top",
        });
      }else{
        this.showPaymentModal = true;
      }
    },
    toggleLoader(value){
      this.$store.commit("setLoader",value);
      // this.showLoader = value
    },
    toggleInterval(){
      this.paymentInterval = this.paymentInterval === 'month' ? 'year' : 'month'
    },
    planPrice(price){
      if (this.paymentInterval === 'month'){
        return price
      }else{
        let yearlyPrice = parseInt(price)*12;
        return ((100-this.discount)/100) * yearlyPrice;
      }
    },
    hasSubscribed(plans,plan){
      let hasPlan = false;
      if (plans && plans.length){
        for (const userPlan of plans) {
          if (userPlan.tag === plan){
            hasPlan = true;
            break;
          }
        }
      }
      return hasPlan
    },
    formatPlanName(planName){
      return planName.replace("_"," ").replace("-"," ");

    }
  },
  computed:{
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
    ...mapGetters(["getPaymentPlansFromStore"]),
  },
  mounted() {
    /**
     * get subscription plans
     * set selected
     */
    // this.showLoader = true;
    this.$store.commit("setLoader",true);
    this.getPaymentPlans().then((response)=>{
      this.subscriptionPlans = response.data.data.plans;
      // this.showLoader = false;
      this.$store.commit("setLoader",false);
    }).catch((error)=>{
      console.log(error);
      this.$toast.error(error.response.data.message, {
        position: "top",
      });
      // this.showLoader = false;
      this.$store.commit("setLoader",false);
    })
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.con {
  text-align: center;
  .slider{
    display: flex;
    align-items: center;
    justify-content: center;
    #switch-interval {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;

      .switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        //background-color: $light-gray;
        background-color: $text;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;

        &:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 26px;
          left: 4px;
          bottom: 4px;
          //background-color: white;
          background-color: $yellow;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
        }

        &.year {
          background-color: $yellow;

          &:before {
            -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
            background-color: $text;
          }
        }
      }
    }
  }


  .plans {
    margin-top: 2rem;
    @include for-large {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }

    .plan {
      width: 100%;
      height: auto;
      border-radius: $border-radius;
      margin-bottom: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;

      &.light {
        background-color: $light-gray;
      }

      &.black {
        background-color: $text;
        color: $white;

        .top {
          h5 {
            color: $white;
          }
        }

        .btn:hover {
          background-color: $white;
          color: $text !important;
        }
      }

      &.yellow {
        background-color: $yellow;
      }

      .top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .left {
          h5 {
            font-size: 1.5rem;
          }

          p {
            font-size: 1rem;
          }
        }

        .free {
          font-size: 1.5rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        .price {
          font-size: 2.2rem;
          font-weight: 600;

          sup,
          sub {
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }

      ul {
        margin-bottom: 1rem;

        li {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          img {
            width: 22px;
            margin-right: 0.5rem;
          }
        }
      }

      .btn {
        cursor: pointer;
        width: 100%;
        margin-top: auto;
      }
    }
  }
}
.mb-4{
  margin-bottom: 2rem;
}
.mb-2{
  margin-bottom: 1rem;
}
.mr-1{
  margin-right: 0.5rem;
}
</style>
