import {http} from "./http-common";

class AuthService {
  register(credentials) {
    return http.post("/register", credentials);
  }

  login(credentials) {
    return http.post("/login", credentials);
  }

  socialSignin(data) {
    return http.post("/users/auth/social", data);
  }

  forgotPassword(data) {
    return http.post("/password/forgot", data);
  }

  resetPassword(data) {
    return http.post("/password/reset", data);
  }

  logout() {
    return http.post("/logout");
  }

  emailVerification({userId,hash,signature,expires}) {
    let url =`/auth/email/verify/${userId}/${hash}?expires=${expires}&signature=${signature}`;
    return http.get(url);
  }

    resendVerification(data){
      return http.post("/user/email/verify", data);
    }
}

const API = new AuthService();

export default API;
export { API as AuthService };
