<template>
  <nav class="bottom-nav">
    <ul>
      <li>
        <router-link class="active" to="/"
          ><img src="../assets/icons/home_black_24dp.svg" alt="" /><span
            >Home</span
          ></router-link
        >
      </li>
      <li>
        <router-link to="/services">
          <img src="../assets/icons/people_black_24dp.svg" alt="" />
          <span>Services</span>
        </router-link>
      </li>
      <li v-if="isAdminFromStore()">
        <router-link to="/statistics"
          ><img src="../assets/icons/bar_chart_black_24dp.svg" alt="" />
          <span>Stats</span>
        </router-link>
      </li>
      <li>
        <div class="toggle" @click="$emit('showSidebar')">
          <img src="../assets/icons/more_horiz_black_24dp.svg" alt="" />
          <span>More</span>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapGetters, mapState} from "vuex";
// import roles from "@/store/modules/roles";

export default {
  setup() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["isAdminFromStore"]),
  },
};
</script>
