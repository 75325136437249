<template>
  <div class="home-admin-page">
    <Loader v-if="showLoader" />
    <div class="con">
      <h3>Statistics</h3>
      <h1>{{statistics?.all?.match_count}}</h1>
      <p>Total number of users</p>
      <div class="admin-sub">
        <div class="stat-cards">
          <div class="stat-card black">
            <p><span>Providers</span></p>
            <div class="number">{{statistics?.providers?.match_count}}</div>
<!--            <p>+14.3% this week</p>-->
          </div>
          <div class="stat-card light">
            <p><span>Users</span></p>
            <div class="number">{{statistics?.users?.match_count}}</div>
<!--            <p>-2.5% this week</p>-->
          </div>
        </div>
        <div class="stat-cards">
          <div class="stat-card light">
            <p><span>Services</span></p>
            <div class="number">{{statistics?.services?.match_count}}</div>
            <!--            <p>-2.5% this week</p>-->
          </div>
          <div class="stat-card yellow">
            <p><span>Service Requests</span></p>
            <div class="number">{{statistics?.service_requests?.match_count}}</div>
            <!--            <p>+14.3% this week</p>-->
          </div>
        </div>
      </div>
      <div class="info">
        <div class="name">Detail Statistics</div>
        <ul class="tabs">
          <li class="tab" :class="{'active':activeTab === 'all_users'}" @click="activeTab = 'all_users'">
            All Users
<!--            <div class="left">-->
<!--              <div class="flex">-->
<!--                <input type="radio" name="filter" id="first" />-->
<!--                <label for="first" class="tab">All Users</label>-->
<!--                <input type="radio" name="filter" id="second" />-->
<!--                <label for="second" class="tab">Providers</label>-->
<!--                <input type="radio" name="filter" id="third" />-->
<!--                <label for="third" class="tab">Users</label>-->
<!--              </div>-->
<!--            </div>-->
          </li>
          <li class="tab" :class="{'active':activeTab === 'providers'}" @click="activeTab = 'providers'">Providers</li>
          <li class="tab" :class="{'active':activeTab === 'users'}" @click="activeTab = 'users'">Users</li>
          <li class="tab" :class="{'active':activeTab === 'services'}" @click="activeTab = 'services'">Services</li>
<!--          <li class="tab" :class="{'active':activeTab === 'service_request'}" @click="activeTab = 'service_request'">Service Requests</li>-->
        </ul>
        <div class="tabs-content">
          <div id="all-users" v-if="activeTab === 'all_users'" >
            <div class="table-wrapper">
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user,index) in statistics?.all.data.data" :key="index" >
                  <td>{{user.name}}</td>
                  <td>{{user.email}}</td>
                  <td>
                    <span v-if="user.address">{{user.address}}</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                </tbody>

              </table>
            </div>
            <div v-if="statistics?.all.data.next_page_url" class="pagination">
              <span class="previous d-inline-block mr-2" >previous</span>
              <span class="next d-inline-block">next</span>
            </div>
          </div>
          <div id="providers" v-if="activeTab === 'providers'">
            <div class="table-wrapper">
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user,index) in statistics?.providers?.data?.data" :key="index" >
                  <td>{{user.name}}</td>
                  <td>{{user.email}}</td>
                  <td>
                    <span v-if="user.address">{{user.address}}</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                </tbody>

              </table>
            </div>
            <div v-if="statistics?.providers.data.next_page_url" class="pagination">
              <span class="previous d-inline-block mr-2" >previous</span>
              <span class="next d-inline-block">next</span>
            </div>
          </div>
          <div id="users" v-if="activeTab === 'users'">
            <div class="table-wrapper">
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user,index) in statistics?.users?.data?.data" :key="index" >
                  <td>{{user.name}}</td>
                  <td>{{user.email}}</td>
                  <td>
                    <span v-if="user.address">{{user.address}}</span>
                    <span v-else>-</span>
                  </td>
                </tr>
                </tbody>

              </table>
            </div>
            <div v-if="statistics?.users.data.next_page_url" class="pagination">
              <span class="previous d-inline-block mr-2" >previous</span>
              <span class="next d-inline-block">next</span>
            </div>
          </div>
          <div id="services" v-if="activeTab === 'services'">
            <div class="table-wrapper">
              <table>
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Location</th>
                  <th>User</th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="(service,index) in statistics?.services?.data?.data" :key="index" >
                  <td>{{service.name}}</td>
                  <td>{{service.location.name}}</td>
                  <td>{{service.user.name}}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="statistics?.services.data.next_page_url" class="pagination">
              <span class="previous d-inline-block mr-2" >previous</span>
              <span class="next d-inline-block">next</span>
            </div>
          </div>
<!--          <div id="service-request" v-if="activeTab === 'service_request'"></div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ApiService from "@/services/api.service";
import Loader from "@/components/Loader";
export default {
  data() {
    return {
      statistics:null,
      // showLoader:false,
      duration:30,
      activeTab: 'all_users'
    }
  },
  components: {Loader },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  methods: {
    ...mapActions(["getStatsAction"]),
    getStatistics(){
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      ApiService.getStatistics(this.duration).then((response)=>{
        if (response.status===200){
          this.statistics = response.data.data.statistics;
        }
        // this.showLoader = false;
        this.$store.commit("setLoader",false);
      }).catch((error)=>{
        // this.showLoader= false;
        this.$store.commit("setLoader",false);
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      })
    }
  },
  mounted() {

    this.getStatistics()
    // this.getStatsAction({ user_id: this.user?.id })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error.response);
    //   });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.home-admin-page {
  .con {
    h3 {
      font-size: 1.2rem;
      line-height: 1;
      margin-bottom: 2rem;
      text-align: center;
    }
    h1 {
      font-size: 3.5rem;
      line-height: 1;
      margin: 1rem 0;
      text-align: center;
    }
    p {
      text-align: center;
      margin-bottom: 1rem;
    }
    .home-menu {
      margin-bottom: 1rem;
    }
    .admin-sub {
      margin-bottom: 2rem;
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        h3 {
          margin-bottom: 0 !important;
        }
      }
      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .link {
        color: $text;
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 1px solid $text;
      }
      .stat-cards {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;
        .stat-card {
          width: 49%;
          height: auto;
          padding: 1rem;
          border-radius: 10px;
          p {
            font-size: 1.1rem;
            text-align: left;
            margin-bottom: 1rem;
            span {
              font-weight: 500;
            }
            &:last-of-type {
              margin: 0;
            }
          }
          .number {
            font-size: 2rem;
            font-weight: 600;
            margin-top: 0.5rem;
          }
          &.black {
            background-color: $text;
            color: $white;
          }
          &.yellow {
            background-color: $yellow;
            color: $text;
          }
          &.light {
            background-color: $light-gray;
            color: $text;
          }
        }
      }
    }
    .info {
      margin-bottom: 3rem;

      ul {
        border-bottom: 1px solid $border-color;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: flex-start;
        margin-top: 0.5rem;

        li {
          justify-content: space-between;
          padding: 0.75em 1rem;
          background-color: $light-gray;
          color: $text;
          font-size: 1rem;
          width: fit-content;
          margin-right: 1rem;
          margin-bottom: 0.5rem;
          border-radius: $border-radius;
          border: 1px solid transparent;
          cursor: pointer;
          &.active{
            border-color: $text;
          }
          //.flex {
          //  display: flex;
          //  align-items: center;
          //  justify-content: space-between;
          //
          //  @include for-large {
          //
          //  }
          //}
          //.flex .tab {
          //  width: 32%;
          //  padding: 0.5rem 0;
          //  background-color: $light-gray;
          //  color: $text;
          //  font-size: 1rem;
          //  border-radius: $border-radius;
          //  text-align: center;
          //  @include for-large {
          //    width: fit-content;
          //    padding: 0.5rem 1rem;
          //    margin-right: 1rem;
          //  }
          //}

          .redirect-con {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            & > img {
              width: 15px;
            }
          }
          .redirect {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 0.5rem;
          }

          .redirect img {
            width: 25px;
            margin-right: 1rem;
          }

          .redirect span {
            font-size: 1rem;
            text-transform: capitalize;
            display: inline-block;
            padding: 0 0.3rem;
            line-height: 0.9;
          }
          .social-links {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;
            .link {
              width: 100%;
              height: 40px;
              border-radius: $border-radius;
              background-color: $light-gray;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 1rem;
              span {
                font-size: 1rem;
              }
              img {
                width: 20px;
              }
            }
          }
        }
      }
      .name {
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      .tabs-content{
        .table-wrapper{
          width: 100%;
          overflow-x: scroll;
          table{
            width: 100%;
            margin-bottom: 1.5rem;
            tr{
              border-color: inherit;
              border-style: solid;
              border-width: 0;
            }
            th,td{
              text-align: inherit;
              border-bottom-width: 1px;
            }
          }
        }
      }
    }
  }
}
.d-inline-block{
  display: inline-block;
}
.mr-2{
  margin-right: 1rem;
}
</style>
