<template>
  <div class="con favorite-page">
    <Loader v-if="showLoader" />
    <div v-if="favorites && favorites.length">
      <div class="flex">
        <p><b>{{favorites.length}}</b> <span v-if="favorites.length >1">favorites</span><span v-else>favorite</span> found</p>
<!--        <a href="">Erase all</a>-->
      </div>
      <div class="services">
        <Service :services="favorites" />
      </div>
    </div>
    <div v-else-if="favorites && !favorites.length" class="text-center">
      <p>No Favorites</p>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/api.service";
import {mapState} from "vuex";
import Loader from "@/components/Loader";
import Service from "@/components/Service";
// import likes from "@/components/Likes";

export default {
  name:'Favorite',
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      likes: (state) => state.services.likes,
      showLoader: (state) => state.modals.showLoader
    }),
  },
  components: {Loader ,Service},
  data(){
    return{
      favorites:null,
      // showLoader:false

    }
  },
  watch: {
    likes:function(currentVal){
      this.filterFavorite(currentVal?.data.id);
    }
  },
  methods:{
    getFavourites(){
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      ApiService.getFavourites({"user_id":this.user.id,"limit":20,"order":"desc"}).then((response)=>{
        if (response.status===200){
          this.favorites = response.data.data;
        }
        // this.showLoader = false;
        this.$store.commit("setLoader",false);
      }).catch((error)=>{
        // this.showLoader= false;
        this.$store.commit("setLoader",false);
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      })
    },
    filterFavorite(id){
      this.favorites = this.favorites.filter((service)=>service.id !== id );
    }
  },
  mounted() {
    this.getFavourites()
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.favorite-page .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.favorite-page .flex p {
  font-size: 1rem;
  color: $text;
  margin: 0;
}
.favorite-page .flex a {
  text-align: center;
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}
.text-center{
  text-align: center;
}
</style>
