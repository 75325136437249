<template>
  <div class="block-user-details-page">
    <Loader v-if="showLoader" />
    <div v-if="report" class="con">
      <div class="request-details">
        <div>
          <h4>Submitted by</h4>
          <div class="user-card">
            <!-- <img src="../" alt="" /> -->
            <div class="info">
              <div class="name">{{report.user.name}}</div>
              <div class="date">{{formatDate(report.created_at)}}</div>
            </div>
          </div>
        </div>
        <div>
          <h4>Reason for blocking</h4>
          <p>
            {{report.reason}}
          </p>
        </div>
        <div class="d-none">
          <h4>Attached Photos</h4>
          <div class="photos">
            <img
              src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
              alt=""
            />
            <img
              src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="btns">
        <a href="" class="link d-none">Dismiss Request</a>
        <a v-if="report.blocked_request_user.status === 'active'" @click.prevent="blockUsers(report.block_user_id,true)" href="javascript:void(0)" class="btn btn-yellow ml-0" :disabled="submitting">Block User</a>
        <a v-else-if="report.blocked_request_user.status === 'blocked'" @click.prevent="blockUsers(report.block_user_id,false)" href="javascript:void(0)" class="btn btn-yellow ml-0" :disabled="submitting">Unblock User</a>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import ApiService from "@/services/api.service";
import router from "@/router";
import moment from "moment";
export default {
  name:'BlockUserDetails',
  components: { Loader },
  data() {
    return{
      users:null,
      showLoader:false,
      report:null,
      // submitting:false
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
    ...mapGetters(["getBlockRequestFromStore","getAllREportsFromStore"]),
  },
  methods:{
    ...mapActions(["blockUser"]),
    getBlockRequest(){
      ApiService.getBlockRequest(this.$route.params.id).then((response) => {
        if (response.status){
          this.report = response.data.data
        }
        // this.showLoader =false;
        this.$store.commit("setLoader",false);
      }).catch((error) => {
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
        // this.showLoader =false;
        this.$store.commit("setLoader",false);
      })
    },
    blockUsers(user_id,status){
      let data  = {"status":status}

      this.blockUser({"user_id":user_id,data}).then((response) => {
        this.$toast.success(response.data.message, {
          position: "top",
        });
        let storeReports = this.getAllREportsFromStore()
        if (storeReports && storeReports.data.length){
          this.blockRequest = storeReports.data;
        }
        router.push({
          path: "/block-users",
        });
      }).catch((error) => {
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      })
    },
    formatDate(dateString){
      return  moment(dateString).format("MMMM DD,YYYY")
    }
  },
  mounted() {
    // this.showLoader = true;
    this.$store.commit("setLoader",true);
    let blockUserRequest = this.getBlockRequestFromStore(this.$route.params.id);
    if (blockUserRequest){
      this.report = blockUserRequest;
      // this.showLoader = false;
      this.$store.commit("setLoader",false);
    }else{
     this.getBlockRequest()
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.block-user-details-page {
  div.request-details {
    &> div {
      padding: 1rem 0;
      border-bottom: 1px solid $border-color;
      h4 {
        font-size: 1rem;
        color: $text;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      .photos {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        img {
          width: 100%;
          height: 120px;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
        }
        @include for-tablet {
          grid-template-columns: repeat(3, 1fr);
          img {
            height: 150px;
          }
        }
      }
    }
  }
}
.d-none{
  display: none !important;
}
.ml-0{
  margin-left: 0 !important;
}
</style>
