<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
// @ is an alias to /src

export default {
  name: "App",
  data() {
    return{}
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
body {
  background-color: $white;
}
</style>
