<template>
  <div class="category-details-page">
    <div class="con">
      <div class="top">
        <img
          src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
          alt=""
        />
        <div class="photo">
          <img src="../assets/icons/photo_camera_white_24dp.svg" alt="" />
        </div>
      </div>
      <div class="actions">
        <p>Profile type</p>
        <div class="flex">
          <a href="" class="btn btn-light">Request Service</a>
          <a href="" class="btn btn-black">Offer Service</a>
        </div>
      </div>
      <div class="info">
        <ul>
          <li>
            <div class="left">
              <div class="name">Name and Surname</div>
              <div class="value">Yousif Cross</div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Email address</div>
              <div class="value">Yousif y.cross@gmail.com</div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Phone Number</div>
              <div class="value">+237 755528281</div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Password</div>
              <div class="value">***************</div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Location</div>
              <div class="sub">
                <img src="../assets/images/cmr.png" alt="" />
                <span>Cameroon</span>
              </div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Years of Experience</div>
              <div class="range">
                <input type="range" name="" id="" />
                <div class="flex">
                  <span> &#60; 1 </span>
                  <span>10+</span>
                </div>
              </div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Pricing plan</div>
              <div class="value">Advanced</div>
            </div>
            <a href="">Edit</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.top {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  margin-bottom: 2rem;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $border-radius;
  }
  .photo {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 100%;
    background-color: $primary-color;
    transform: translate(-50%, -50%);
    border: 2px solid $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: $yellow;
    }
    img {
      width: 60%;
    }
  }
}
.actions {
  margin-bottom: 1rem;
  p {
    color: $text;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .flex {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 48%;
    }
  }
}

.info {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $border-color;
      padding: 1rem 0;
      .left {
        width: 100%;
        .name {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: 0.3rem;
        }
        .value {
          font-size: 1rem;
          color: $text;
        }
        .sub {
          width: fit-content;
          display: flex;
          align-items: center;
          color: $text;
        }

        .sub img {
          width: 35px;
          margin-right: 0.5rem;
        }
        .range {
          width: 100%;
        }

        .range input[type="range"] {
          width: 100%;
        }

        .flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.9rem;
        }
        .range input[type="range"] {
          -webkit-appearance: none;
          margin: 10px 0;
          width: 100%;
        }
        .range input[type="range"]:focus {
          outline: none;
        }
        .range input[type="range"]::-webkit-slider-runnable-track {
          width: 100%;
          height: 8.4px;
          cursor: pointer;
          background: $yellow;
          border-radius: 1.3px;
        }
        .range input[type="range"]::-webkit-slider-thumb {
          border: 1px solid $text;
          height: 20px;
          width: 20px;
          border-radius: 3px;
          background: #ffffff;
          cursor: pointer;
          -webkit-appearance: none;
          margin-top: -5px;
        }
      }
      > a {
        font-size: 1rem;
        height: fit-content;
        line-height: 1.1;
        border-bottom: 1px solid $primary-color;
      }
    }
  }
}
</style>
