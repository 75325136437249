<template>
  <a href="javascript:void(0)" @click="likeService">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      :fill="isLiked ? '#fddb00' : '#cccccc'"
    >
      <path
        d="M16.5 3C14.76 3 13.09 3.81 12 5.09C10.91 3.81 9.24 3 7.5 3C4.42 3 2 5.42 2 8.5C2 12.28 5.4 15.36 10.55 20.04L12 21.35L13.45 20.03C18.6 15.36 22 12.28 22 8.5C22 5.42 19.58 3 16.5 3ZM12.1 18.55L12 18.65L11.9 18.55C7.14 14.24 4 11.39 4 8.5C4 6.5 5.5 5 7.5 5C9.04 5 10.54 5.99 11.07 7.36H12.94C13.46 5.99 14.96 5 16.5 5C18.5 5 20 6.5 20 8.5C20 11.39 16.86 14.24 12.1 18.55Z"
        :fill="isLiked ? '#fddb00' : '#cccccc'"
      />
    </svg>
    {{ likesCount }}
  </a>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    user_id: {
      // type: Number,
      required: false,
    },
    service_id: {
      // type: Number,
      required: true,
    },
    now: {
      // type: [],
      required: true,
    },
    numberOfLikes: {
      // type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    likes: {
      get: function () {
        return this.now + this.liked;
      },
    },
  },
  data() {
    return {
      liked: 0,
      isLiked: false,
      likesCount: this.numberOfLikes,
      allLikes: this.now ?? [],
    };
  },
  methods: {
    ...mapActions(["getAllServiceLikes"]),
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    async checkLikes() {
      if (this.allLikes.length) {
        const userLikes = this.allLikes.filter((like) => {
          return Number(like.user_id) === Number(this.user_id);
        });
        if (userLikes.length) {
          this.isLiked = true;
        }
      }
    },
    async doLikeAction() {
      let data = new FormData();
      data.append("user_id", Number(this.user_id));
      this.getAllServiceLikes({
        service_id: Number(this.service_id),
        data: data,
      })
        .then((response) => {
          console.log(response);
          this.isLiked = !this.isLiked;
          this.likesCount = response.data.data.like_count;
          if (this.isLiked) {
            this.$toast.success(
              `${response?.data?.data?.name} added to favorites`,
              {
                position: "top",
              }
            );
          } else {
            this.$toast.success(
              `${response?.data?.data?.name} removed to favorites`,
              {
                position: "top",
              }
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async likeService(e) {
      e.preventDefault();
      if (!this.isLoggedIn()) {
        this.toggleLoginModal();
        this.doLikeAction();
        this.checkLikes();
      } else {
        this.doLikeAction();
      }
    },
  },
  mounted() {
    this.checkLikes();
  },
};
</script>
