<template>
  <div class="login-modal">
    <div class="login">
      <div class="con">
        <a @click="toggleErrorModal" href="javascript:void(0)" class="close">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </a>
        <img
          class="check-email"
          src="../assets/icons/close_black_24dp.svg"
          alt=""
        />
        <h1>Invalid Token</h1>
        <p>Your password was not successful</p>
        <a
          href="javascript:void(0)"
          @click="toggleResetPassword"
          class="sign-in"
        >
          <span>Retry</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  data() {
    return {};
  },
  methods: {
    toggleErrorModal() {
      this.$store.commit("toggleErrorModal");
    },
    toggleResetPassword() {
      this.$store.commit("toggleResetPasswordModal");
    },
  },
};
</script>
