<template>
  <div>
    <a
        href="javascript:void(0)"
        @click="logInWithFacebook"
        class="btn btn-facebook"
    ><img
        src="@/assets/icons/icons8-facebook-f.svg"
        alt=""
    /><span>Facebook</span></a
    >
  </div>
</template>
<script>
// import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "Facebook",
  emits: ['get-facebook-token', 'social-login'],
  computed: {
    ...mapState({
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  methods: {
    async logInWithFacebook() {
      this.$store.commit("setLoader",true);
      try {
        const response = await this.login({scope: 'public_profile,email'});
        if (response.status === 'connected') {
          this.apiAuthenticate(response.authResponse)
        }
      }catch (error) {
        console.log(error)
        this.$toast.error(error, {
          position: "top",
        });
      }

    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
          cookie: true,
          xfbml: true,
          version: "v14.0"
        });
      };
    },
     loadFacebookSDK(d, s, id) {
      let js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    login(options) {
      return new Promise(resolve => window.FB.login(resolve, options))
    },
    apiAuthenticate(authResponse) {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
      const that = this;
      window.FB.api(`/${authResponse.userID}?fields=id,name,email,picture&access_token=${authResponse.accessToken}`, function (response) {
        response['picture'] = response.picture.data.url;
        that.$emit('social-login');
        that.$emit('get-facebook-token', response)
        that.$store.commit("setLoader",false);
      });
    }
  },
  async mounted() {
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    await this.initFacebook();
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: $border-radius;

  img {
    height: 30px;
    margin-right: 1rem;
  }

  &.btn-facebook {
    width: 100%;
    border: 1px solid #3b5999;
    background-color: #3b5999;
    color: $white;

    &:hover {
      background-color: #243b6e;
    }
  }
}
</style>