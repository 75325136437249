const state = {
  showLoginModal: false,
  showRegisterModal: false,
  showResetPasswordModal: false,
  showEnterNewPasswordModal: false,
  showCheckEmailModal: false,
  showResetSuccessModal: false,
  showLoader:false
};

const mutations = {
  toggleLoginModal(state,close= false) {
    state.showRegisterModal = false;
    state.showResetPasswordModal = false;
    state.showEnterNewPasswordModal = false;
    state.showCheckEmailModal = false;
    state.showResetSuccessModal = false;
    if (close){
      state.showLoginModal = false;
    }else {
      state.showLoginModal = !state.showLoginModal;
    }
  },

  toggleRegisterModal(state,close=false) {
    state.showLoginModal = false;
    state.showResetPasswordModal = false;
    state.showEnterNewPasswordModal = false;
    state.showCheckEmailModal = false;
    state.showResetSuccessModal = false;

    if (close){
      state.showRegisterModal = false;
    }else{
      state.showRegisterModal = !state.showRegisterModal;
    }
  },

  toggleResetPasswordModal(state,close=false) {
    state.showLoginModal = false;
    state.showRegisterModal = false;
    state.showEnterNewPasswordModal = false;
    state.showCheckEmailModal = false;
    state.showResetSuccessModal = false;

    if (close){
      state.showResetPasswordModal = false
    }else{
      state.showResetPasswordModal = !state.showResetPasswordModal;
    }
  },

  toggleEnterNewPasswordModal(state,close= false) {
    state.showLoginModal = false;
    state.showRegisterModal = false;
    state.showResetPasswordModal = false;
    state.showCheckEmailModal = false;
    state.showResetSuccessModal = false;
    if (close){
      state.showEnterNewPasswordModal = false;
    }else{
      state.showEnterNewPasswordModal = !state.showEnterNewPasswordModal;
    }

  },

  toggleCheckEmailModal(state,close = false) {
    state.showLoginModal = false;
    state.showRegisterModal = false;
    state.showResetPasswordModal = false;
    state.showEnterNewPasswordModal = false;
    state.showResetSuccessModal = false;

    if (close){
      state.showCheckEmailModal = false;
    }else{
      state.showCheckEmailModal = !state.showCheckEmailModal;
    }
  },

  toggleResetSuccessModal(state, close=false) {
    state.showLoginModal = false;
    state.showRegisterModal = false;
    state.showResetPasswordModal = false;
    state.showEnterNewPasswordModal = false;
    state.showCheckEmailModal = false;

    if (close){
      state.showResetSuccessModal = false;
    }else{
      state.showResetSuccessModal = !state.showResetSuccessModal;
    }
  },

  toggleErrorModal(state,close=false) {
    state.showLoginModal = false;
    state.showRegisterModal = false;
    state.showResetPasswordModal = false;
    state.showEnterNewPasswordModal = false;
    state.showCheckEmailModal = false;
    state.showResetSuccessModal = false;
    if (close){
      state.showErrorModal = false;
    }else{
      state.showErrorModal = !state.showErrorModal;
    }

  },
  setLoader(state, payload) {
    state.showLoader = payload;
  },
};

export default {
  state,
  mutations,
};
