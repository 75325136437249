<template>
  <div class="review-page">
    <div class="con">
      <form class="info">
        <ul>
          <li>
            <div class="left">
              <div class="name">Problem Description</div>
              <textarea
                v-model="description"
                name=""
                placeholder="Be precise in service description in order to make users understand the scope of the service..."
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </li>
        </ul>
        <a
          href="javascript:void(0)"
          @click="reportProvider"
          class="btn btn-yellow"
          >Submit Report</a
        >
      </form>
    </div>
  </div>
</template>
<script>
import { processFiles } from "../store/helpers";
import { mapActions, mapGetters, mapState } from "vuex";
import router from "@/router";

export default {
  data() {
    return {
      users: [],
      reported_id: null,
      description: null,
      photos: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["getAllUsersFromStore"]),
  },
  methods: {
    ...mapActions(["reportProviderAction"]),
    ...mapGetters(["isAuthenticated"]),

    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    async reportProvider() {
      let data = new FormData();
      data.append("block_user_id", this.$route.params.id);
      data.append("reason", this.description);
      return this.reportProviderAction(data)
        .then((response) => {
          if (response.status <= 300){
            this.$toast.success(response.data.message, {
              position: "top",
            });
            //  set form data to default
            this.description = null;
            this.photos = [];
            router.go(-1)
          }

        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
          console.log(error);
        });
    },
    async handleFiles(e) {
      this.photos = await processFiles(e, this.photos);
    },
    async removeSelectedPhoto(index, file) {
      try {
        this.photos.splice(index, 1);
        this.$toast.success(`${file.name} removed`, {
          position: "top",
        });
      } catch (e) {
        this.$toast.error(`${file.name} removed`, {
          position: "top",
        });
      }
    },
  },
  mounted() {
    this.users = this.getAllUsersFromStore();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.info {
  margin-bottom: 3rem;
  ul {
    li {
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      padding: 1rem 0;
      &:last-child {
        margin-bottom: 1rem;
        border-bottom: none;
      }
      input,
      textarea {
        width: 100%;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        font-size: 1rem;
        color: $text;
        margin-top: 0.3rem;
      }
      input {
        padding: 0 1rem;
        height: 45px;
      }
      textarea {
        padding: 1rem;
      }
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
      .redirect-con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        & > img {
          width: 15px;
        }
      }
      .redirect {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
      }

      .redirect img {
        width: 25px;
        margin-right: 1rem;
      }

      .redirect span {
        font-size: 1rem;
        text-transform: capitalize;
        line-height: 0.9;
      }
    }
  }
}
.add-photo {
  width: 100%;
  height: auto;
  margin: 0.5rem 0;
  padding: 1.5rem 0;
  border: 2px dashed $text;
  border-radius: $border-radius;
  display: grid;
  align-content: center;
  justify-items: center;
  input {
    display: none;
  }
  img {
    width: 50px;
    opacity: 0.5;
  }
  p {
    margin: 0.5rem 0;
  }
  label {
    border-bottom: 1px solid $text;
    font-weight: 500;
  }
}
.btn {
  width: 100%;
}
</style>
