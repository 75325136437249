import APIService from "../../services/api.service";

const state = {
  categories: null,
};

const actions = {
  async getAllCategories({ commit }, limit = 50) {
    return new Promise((resolve, reject) => {
      APIService.getAllCategories(limit)
        .then((response) => {
          commit("setCategories", response.data.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getAllSubCategories({ commit }, id) {
    return new Promise((resolve, reject) => {
      APIService.getSubCategories(id)
        .then((response) => {
          commit("setSubCategories", response.data.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getFeaturedCategories({ commit }) {
    return new Promise((resolve, reject) => {
      APIService.getFeaturedCategories()
        .then((response) => {
          commit("setFeaturedCategories", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async createCategoryAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      APIService.createCategory(data)
        .then((response) => {
          commit("category", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createSubCategoryAction({ commit }, { category_id, data }) {
    return new Promise((resolve, reject) => {
      APIService.createSubCategory({ category_id, data })
        .then((response) => {
          commit("subCategory", response.data.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {
  getAllCategoriesFromStore: (state) => () => {
    return state.categories;
  },

  getCategoryFromStore: (state) => (slug) => {
    return state.categories.find((category) => category.slug === slug);
  },
  getCategoryFromStoreById: (state) => (id) => {
    return state.categories.find((category) => category.id === id);
  },

  getCategoryFromStoreBySlug: (state) => (slug) => {
    return state.categories.find((category) => category.slug === slug);
  },
  getFeaturedCategoriesFromStore: (state) =>()=>{
    const allCategories = state.categories;
    if (allCategories.length){
      return allCategories.filter((category) => category.type === "featured");
    }else {
      return []
    }

  },
};

const mutations = {
  setCategories(state, payload) {
    state.categories = payload;
  },
  setCategory(state, payload) {
    state.category = payload;
  },
  setSubCategory(state, payload) {
    state.subCategory = payload;
  },
  setFeaturedCategories(state, payload) {
    state.featuredCategories = payload;
  },

  setSubCategories(state, payload) {
    state.subCategories = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
