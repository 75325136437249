<template>
  <section>
    <div class="con">
      <h3>Search Result</h3>
      <div class="results">
        <div v-if="searchResult?.services?.data.data.length > 0">
          <h3>Services:</h3>
          <p><span>Results matching service records</span></p>
          <div class="sub-results">
            <div
              v-for="(item, index) in searchResult?.services.data.data"
              :key="index"
              class="item"
            >
              <img
                :src="
                  item?.thumbnail ??
                  '../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg'
                "
                alt=""
              />
              <div class="info">
                <router-link
                  :to="{
                    path: `/services/${item.slug}`,
                  }"
                  tag="div"
                  class="name"
                  >{{ item.name }}</router-link
                >
                <div class="type">{{ item.category_name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="searchResult?.users?.data.data.length > 0">
          <h3>Users</h3>
          <p><span>Results matching user records</span></p>
          <div class="sub-results">
            <div
              v-for="(item, index) in searchResult?.users.data.data"
              :key="index"
              class="item"
            >
              <img v-if="item.profile_url" :src="item.profile_url" alt="" />
              <img v-else src="../assets/servicehub-avatar.png" alt="" />
              <div class="info">
                <router-link :to="'/users/'+item.id" tag="div" class="name">{{
                  item.name
                }}</router-link>
<!--                <div class="type">{{ item?.email }}</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    result: {
      // type: [],
      required: true,
    },
  },
  data() {
    return {
      searchResult: [],
    };
  },
  computed: {
    ...mapGetters(["getCategoryFromStoreById", "getLocationFromStore"]),
  },
  methods: {},
  mounted() {
    this.searchResult = this.result[0];
    this.searchResult.services.data.data = this.searchResult.services.data.data.map(
      (service) => {
        const location = this.getLocationFromStore(service.location_id);
        let category = { name: "Research Services" };
        if (service.sub_categories.length > 0) {
          category = this.getCategoryFromStoreById(
            service?.sub_categories[0]?.category_id
          );
        }
        service["category_name"] = category.name;
        return {
          ...service,
          location_name: location.name,
          category_name: category.name,
        };
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.results {
  margin-top: 1rem;
  overflow: auto;
  display: block;
  max-height: 350px;
  .sub-results {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    .item {
      width: 100%;
      height: auto;
      display: flex;
      img {
        width: 50px;
        height: 50px;
        border-radius: $border-radius;
        object-fit: cover;
        object-position: center;
        margin-right: 1rem;
      }
      .name {
        color: $text;
        font-size: 1rem;
        font-weight: 600;
      }
      .location {
        font-size: 1rem;
        color: $light-text;
      }
    }
  }
}
/* for extra small screens and lower  */
@include for-extra-small {
  .sub-results {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }
}
@include for-mobile {
  .sub-results {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.2rem;
    display: grid;
  }
}
@include for-large {
  .sub-results {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    display: grid;
  }
}
</style>
