<template>
  <div class="users-page">
    <div class="con">
      <Loader v-if="showLoader"/>
      <SearchFilter v-if="allUsers.length" :page="'users'" @searchData="getSearchResult"/>
      <div v-if="displayUsers && displayUsers.length" class="users">
        <div class="categories-filter">
          <p><b>{{ totalUser }}</b> <span v-if="displayUsers.length > 1">users</span> <span v-else>user</span> found
          </p>
        </div>
        <div v-for="(user, index) in displayUsers" :key="index" class="user">
          <router-link :to="'/users/'+user.id" class="flex">
            <img v-if="user.profile_url" :src="user.profile_url" alt=""/>
            <img v-else src="../assets/servicehub-avatar.png" alt=""/>
            <div class="info">
              <p
                  class="name"
              >{{ user.name }}
              </p
              >
              <div v-if="user.email" class="location">{{ user.email }}</div>
              <div v-else-if="user.phone" class="location">{{ user.phone }}</div>
              <div v-if="user.address" class="location">{{ user.address }}</div>

            </div>
          </router-link>
        </div>
      </div>
      <div v-else-if="displayUsers && !displayUsers.length"><p>No users found</p></div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import SearchFilter from "../components/SearchFilter.vue";
import Loader from "../components/Loader.vue";

export default {
  data() {
    return {
      allUsers: [],
      displayUsers:[],
      serviceProviders: null,
      services: null,
      totalUser:0,
      // showLoader: false,
    };
  },
  components: {SearchFilter, Loader},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  methods: {
    ...mapActions(["getAllUsers"]),
    ...mapGetters(["isAuthenticated", "getAllUsersFromStore"]),

    toggleFilter: function () {
      if (this.showFilter) {
        this.showFilter = false;
      } else {
        this.showFilter = true;
      }
    },

    getSearchResult(payloads) {
      if (payloads){
        this.displayUsers = payloads.data.data;
        this.totalUser = payloads.match_count
      }else{
        this.displayUsers = this.allUsers;
        this.totalUser = this.allUsers.length
      }
    },

    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },

    getUsers() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      this.getAllUsers()
          .then((response) => {
            this.allUsers = response;
            this.displayUsers = response;
            this.totalUser = response.length;
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          })
          .catch((error) => {
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
            console.log(error.response);
          });
    },

    init() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      if (!this.isAuthenticated()) {
        // this.showLoader = false;
        this.toggleLoginModal();
        this.$store.commit("setLoader",false);
        this.setUsers()
      } else {
        this.setUsers()
      }
      // this.showLoader = false;
      this.$store.commit("setLoader",false);
    },
    setUsers() {
      let storeUsers = this.getAllUsersFromStore();

      if (storeUsers) {
        this.allUsers = storeUsers
        this.displayUsers = storeUsers
        this.totalUser = storeUsers.total;
      } else {
        this.getUsers();
      }
    }
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.categories-filter {
  margin: 1rem 0;
}

.categories-filter p {
  margin: 0;
  color: $text;
  font-size: 1rem;
}

.categories-filter .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
  @include for-large {
    justify-content: flex-start;
  }
}

.categories-filter .flex input {
  display: none;
}

.categories-filter .flex .tab {
  width: 32%;
  padding: 0.5rem 0;
  background-color: $light-gray;
  color: $text;
  font-size: 1rem;
  border-radius: $border-radius;
  text-align: center;
  @include for-large {
    width: fit-content;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
  }
}

.categories-filter .flex input:checked + .tab {
  border: 1px solid $text;
}

.users {
  margin-top: 1rem;

  .user {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 1rem;

    img {
      width: 50px;
      height: 50px;
      border-radius: $border-radius;
      object-fit: cover;
      object-position: center;
      margin-right: 1rem;
    }

    .name {
      color: $text;
      font-size: 1rem;
      font-weight: 500;
    }

    .location {
      font-size: 1rem;
      color: $light-text;
    }
  }
}
.flex{
  display: flex;
}
</style>
