<template>
  <div class="payment-successful-page">
    <div class="con">
      <img src="../assets/icons/check.svg" alt="" />
      <h1>Payment successful</h1>
      <p>You have paid your service </p>

      <div class="buttons">
        <router-link to="/"  class="btn btn-yellow">Continue</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PaymentModal",
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.payment-successful-page {
  .con {
    text-align: center;
    img {
      width: 250px;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    p {
      margin-top: 1rem;
      font-size: 1rem;
      color: $light-text;
      @include for-large {
        width: 50%;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
    .buttons {
      width: 100%;
      margin-top: 2rem;
      .btn-yellow {
        width: 100%;
        cursor: pointer;
        @include for-large {
          width: 40%;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
