import APIService from "../../services/api.service";

const state = {
    roles: null,
    permissions:null,
    isAdmin:null,
};

const actions = {
    async getAllRoles({ commit }) {
        return new Promise((resolve, reject) => {
            APIService.getAllRoles()
                .then((response) => {
                    commit("setRoles", response.data.data[0].data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const getters = {
    getAllRolesFromStore: (state) => () => {
        return state.roles;
    },
    isAdminFromStore: (state) => () => {
        return state.isAdmin;
    },

};

const mutations = {
    setRoles(state, payload) {
        state.roles = payload;
    },
    setHasAdminRole(state, payload){
        state.isAdmin = payload;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
