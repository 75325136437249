<template>
  <div class="user-card">
    <div class="content">
      <p>
       <span>Welcome</span>
        <router-link to="/profile">
          <em>{{ user.name }}</em>
        </router-link>

      </p>
    </div>
    <div class="img-con">
      <router-link to="/profile">
        <img v-if="user.profile_url" :src="user.profile_url" alt="" />
        <img v-else src="../assets/servicehub-avatar.png" alt="" />
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  methods: {
    ...mapMutations(["toggleLoginModal", "toggleRegisterModal"]),
    toggleLogin() {
      this.toggleLoginModal();
    },
    toggleRegister() {
      this.toggleRegisterModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.user-card {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  .content {
    width: auto;
    height: auto;
    border-bottom: 3px solid $yellow;
    margin-right: 2rem;
    font-size: 1.1rem;
    em {
      font-size: 2rem;
      font-weight: 600;
    }
    @include for-tablet {
      font-size: 1rem;
      em {
        font-size: 1.5rem;
      }
    }
    @include for-mobile {
      font-size: 1rem;
      em {
        font-size: 1.5rem;
      }
    }
  }
  .img-con {
    width: 100px;
    height: 100px;
    border: 2px solid $yellow;
    border-radius: 50%;
    margin-right: 1rem;
    cursor: pointer;
    @include for-tablet {
      width: 70px;
      height: 70px;
    }
    @include for-mobile {
      width: 70px;
      height: 70px;
    }
    @include for-extra-small {
      width: 50px;
      height: 50px;
    }
    &:hover {
      background-color: $light-gray !important;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 57%;
      position: relative;
      transform: scale(0.8);
    }
  }
}
</style>
