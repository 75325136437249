<template>
  <header class="header-two">
    <div class="page-top">
      <a href="/" class="con">
        <img src="../assets/logo_color.svg" alt="" class="logo" />
        <!-- <router-link to="/">
          <img src="../assets/logo_color.svg" alt="" class="logo" />
        </router-link> -->
      </a>
    </div>
  </header>
</template>
