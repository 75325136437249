import APIService from "../../services/api.service";

const state = {
  searchResult: null,
};

const actions = {
  async searchAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      APIService.searchAction(data)
        .then((response) => {
          commit("setSearchResult", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getStatsAction({ commit }, { user_id }) {
    return new Promise((resolve, reject) => {
      APIService.getStats({ user_id })
        .then((response) => {
          commit("setStatistics", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {};

const mutations = {
  setSearchResult(state, payload) {
    state.searchResult = payload;
  },
  setStatistics(state, payload) {
    state.statistics = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
