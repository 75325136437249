import APIService from "../../services/api.service";
import ApiService from "@/services/api.service";

const state = {
    users: null,
    notifications: null,
    reports: null,
    viewedUser: null,
    report: null
};

const actions = {
    async getAllUsers({commit}) {
        return new Promise((resolve, reject) => {
            APIService.getAllUsers()
                .then((response) => {
                    commit("setUsers", response.data.data.data);
                    resolve(response.data.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async updateUser({commit}, data) {
        return new Promise((resolve, reject) => {
            APIService.updateUser(data)
                .then((response) => {
                    commit("setUser", response.data.data.user, {root: true});
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async getUserById({commit}, user_id) {
        return new Promise((resolve, reject) => {
            APIService.getUserById(user_id)
                .then((response) => {
                    commit("setViewedUser", response.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async reportProviderAction({commit}, data) {
        return new Promise((resolve, reject) => {
            APIService.reportProvider(data)
                .then((response) => {
                    commit("setReport", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getAllUserReports({commit}) {
        return new Promise((resolve, reject) => {
            APIService.userReports()
                .then((response) => {
                    commit("setReports", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getUserNotifications({commit}, data) {
        return new Promise((resolve, reject) => {
            ApiService.getNotifications(data)
                .then((response) => {
                    commit("setUserNotifications", response.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async setNotificationStatus({commit, state}, data) {
        return new Promise((resolve, reject) => {
            ApiService.setNotificationStatus(data)
                .then((response) => {
                    let updatedNotifications = state.notifications.data.map((notification) => {
                        if (notification.id === response.data?.data?.id) {
                            notification = response.data.data
                        }
                        return notification
                    })
                    commit("setUserNotifications", {data: updatedNotifications});
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async blockUser({commit, state}, data) {
        return new Promise((resolve, reject) => {
            APIService.blockUser(data)
                .then((response) => {
                    let updatedReport = state.reports.data.map((report) => {

                        if (report.blocked_request_user.id == response.data.data.id) {
                            report.blocked_request_user = response.data.data
                        }
                        return report;
                    })
                    commit("setReports", {"data": updatedReport});

                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

const getters = {
    getAllUsersFromStore: (state) => () => {
        return state.users;
    },
    getAllREportsFromStore: (state) => () => {
        return state.reports;
    },
    // ...
    getUserFromStore: (state) => (user_id) => {
        return state.users?.data.find((user) => user.id === user_id);
    },
    getNotificationsFromStore: (state) =>() => {
    return state.notifications
    },
    getBlockRequestFromStore:(state) => (report_id) => {
        return state.reports?.data.find((report) => report.id === parseInt(report_id));
    }
};

const mutations = {
    setUsers(state, payload) {
        state.users = payload;
    },
    setReports(state, payload) {
        state.reports = payload;
    },
    setReport(state, payload) {
        state.report = payload;
    },
    setViewedUser(state, payload) {
        state.viewedUser = payload;
    },
    setUserNotifications(state, payload) {
        state.notifications = payload;
    }
};

export default {
    state,
    actions,
    mutations,
    getters,
};
