<template>
  <div class="login-modal" ref="enterNewPassword">
    <div class="login">
      <div class="con">
        <div class="close" @click="toggleEnterNewPasswordModal">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </div>
        <h1>New Password</h1>
        <p>Enter your new password</p>
        <form action="" @submit.prevent="submit()">
          <div
            class="field-con"
            :class="{ error: errorState && errorState.password }"
          >
            <div class="field password">
              <input
                id="password"
                type="password"
                name="password"
                placeholder="New Password"
                v-model="password"
              />
              <div class="toggle" @click="togglePassword">
                <img src="@/assets/icons/eye.svg" alt="" />
              </div>
            </div>
            <div v-if="errorState && errorState.password" class="error">
              {{ errorState.password }}
            </div>
          </div>
          <div
            class="field-con"
            :class="{ error: errorState && errorState.passwordConfirm }"
          >
            <div class="field password">
              <input
                id="password-confirm"
                type="password"
                name="password-confirm"
                placeholder="Confirm Password"
                v-model="passwordConfirm"
              />
              <div class="toggle" @click="togglePasswordConfirm">
                <img src="@/assets/icons/eye.svg" alt="" />
              </div>
            </div>
            <div v-if="errorState && errorState.passwordConfirm" class="error">
              {{ errorState.passwordConfirm }}
            </div>
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  setup() {},
  data() {
    return {
      password: null,
      passwordConfirm: null,
      errorState: null,
    };
  },
  methods: {
    ...mapActions(["resetPassword"]),

    toggleEnterNewPasswordModal() {
      this.$store.commit("toggleEnterNewPasswordModal");
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    toggleResetSuccessModal() {
      this.$store.commit("toggleResetSuccessModal");
    },
    toggleErrorModal() {
      this.$store.commit("toggleErrorModal");
    },
    togglePassword() {
      let password = document.getElementById("password");
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },
    togglePasswordConfirm() {
      let password = document.getElementById("password-confirm");
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },
    submit() {
      if (this.handleValidation()) {
        let data = new FormData();
        data.append("email", this.$route.query.email);
        data.append("token", this.$route.params.token);
        data.append("password", this.password);
        data.append("password_confirmation", this.passwordConfirm);

        this.resetPassword(data)
          .then((response) => {
            console.log(response);
            this.$toast.success(`${response?.data.message}`, {
              position: "top",
            });
            this.toggleResetSuccessModal();
          })
          .catch((error) => {
            this.$toast.error(error.response.data.message, {
              position: "top",
            });
            console.log(error.response);
            // this.toggleResetPassword();
            this.toggleErrorModal();
          });
      }
    },
    handleValidation() {
      let errors = {};
      this.errorState = null;
      let formIsValid = true;

      // validate password
      if (!this.password) {
        formIsValid = false;
        errors["password"] = "password is required";
      }

      // validate password
      if (!this.passwordConfirm) {
        formIsValid = false;
        errors["passwordConfirm"] = "password confirm is required";
      } else if (this.passwordConfirm != this.password) {
        formIsValid = false;
        errors["passwordConfirm"] = "Both passwords should be the same";
      }

      this.errorState = errors;
      return formIsValid;
    },
    hideModal(event) {
      const enterNewPassword = this.$refs.enterNewPassword;
      const target = event.target;
      if (enterNewPassword && enterNewPassword === target) {
        this.toggleEnterNewPasswordModal()
      }
    },
    closeModal(){
      this.$store.commit("toggleEnterNewPasswordModal",true);
    }
  },
  created() {
    document.addEventListener("click", this.hideModal);
    window.addEventListener('unload', this.closeModal);
  },
};
</script>
