<template>
  <div ref="editUserModal" class="login-modal">
    <div class="login">
      <div class="con">
        <div class="close mb-3">
          <div @click="hideModal" class="cursor-pointer">
            <img src="@/assets/icons/icon-close.svg" alt=""/>
          </div>
        </div>
        <div>
          <h1>Edit Account</h1>
        </div>

          <form action="" @submit.prevent="submit">
            <div
                class="field-con"
                :class="{ error: formErrors.name }"
            >
              <div class="field">
                <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name and Surname"
                    v-model="form.name"
                    @input="validateInput('name')"
                />
              </div>
              <div v-if="formErrors.name" class="error">
                {{ formErrors.name }}
              </div>
            </div>

            <div
                class="field-con"
                :class="{ error:formErrors.phone }"
            >
              <div class="field">
                <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone number"
                    v-model="form.phone"
                    @input="validateInput('phone')"
                />
              </div>
              <div v-if="formErrors.phone" class="error">
                {{ formErrors.phone }}
              </div>
            </div>

            <div
                class="field-con"
                :class="{ error: formErrors.address }"
            >
              <div class="field">
                <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    v-model="form.address"
                    @input="validateInput('address')"
                />
              </div>
              <div v-if="formErrors.address" class="error">
                {{ formErrors.address }}
              </div>
            </div>
            <div class="field-con mb-4">
              <div class="top">
                <img v-if="form.profile_url && (typeof form.profile_url  === 'string' || form.profile_url  instanceof String)" :src="form.profile_url" alt="" />
                <img v-else-if="form.profile_url" :src="form.profile_url.url" alt="" />
                <img v-else src="../assets/servicehub-avatar.png" alt="" />

                <label for="profile_image">
                  <input id="profile_image" type="file" class="d-none" @input="handleFiles">
                <div class="photo d-flex">
                  <img src="../assets/icons/photo_camera_white_24dp.svg" alt="" />
                  <span v-if="form.profile_url">Change Image</span>
                  <span v-else>Upload Image</span>
                </div>
                </label>
              </div>
            </div>
            <div class="flex">
              <button type="submit">Save</button>
            </div>

          </form>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {processFiles} from "@/store/helpers";
export default {
  name: "Edit User Modal",
  emits: ['hideModal',"toggleLoader"],
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    }),
  },
  data() {
    return {
      form: {
        phone: undefined,
        address: undefined,
        name: undefined,
        profile_url:undefined,
      },
      profile_image: undefined,
      formErrors:{}
    }
  },
  // watch: {
  //   'form.phone':function(currentVal){
  //     if (!this.user.email){
  //       this.handleValidation("phone", currentVal,{required: true},"Phone");
  //     }
  //   },
  //   'form.address':function(currentVal){
  //     this.handleValidation("address", currentVal,{required: true,isCvc: true},"Address");
  //   },
  //   'form.name':function(currentVal){
  //     this.handleValidation("name", currentVal,{required: true,min:5},"Name");
  //   },
  // },
  refs: ['paymentModal'],
  methods: {
    ...mapActions([
        "updateUser",
    ]),
    submit() {
      if (!this.user?.email){
        this.handleValidation('phone',this.form.phone,{required:true,isTel: true},'Phone number');
      }
      this.handleValidation('address',this.form.address,{required:true},'Address');
      this.handleValidation('name',this.form.name,{required:true,min: 3},'Name');

      if (Object.keys(this.formErrors).length === 0) {
        this.$emit('toggleLoader', true);
        const data = {};
        // if (this.form.phone){
          data["phone"] = this.form.phone;
        // }
        if (this.form.profile_url &&  typeof this.form.profile_url  !== 'string' ){
          data["profile_url"] = this.form.profile_url;
        }
        data["address"] =this.form.address;
        data["name"] = this.form.name;

        this.updateUser({user_id:this.user.id,data}).then((response) => {
          this.$emit('toggleLoader', false);
          this.hideModal();
          this.$toast.success(response.data.message, {
            position: "top",
          });

        }).catch((error) => {
          console.log(error);
          this.$emit('toggleLoader', false);
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        })
      }
    },
    hideModal() {
      this.$emit('hideModal')
    },
    validateInput(ele){
      if (ele==='phone') {
        if (!this.user?.email){
          this.handleValidation('phone',this.form.phone,{required:true,isTel: true},'Phone number');
        }
      }
      else if(ele==='address'){
        this.handleValidation('address',this.form.address,{required:true},'Address');
      }
      else if(ele === 'name'){
        this.handleValidation('name',this.form.name,{required:true,min: 3},'Name')
      }

    },
    async handleFiles(e) {
      const response = await processFiles(e, []);
     this.form.profile_url = response[0];
    },
    handleValidation(fieldKey, fieldValue, validation = {
      required: false,
      isEmail: false,
      isCvc: false,
      max:0,
      min:0,
      isTel: false,
      isNumeric:false,
    } ,fieldName) {
      //  todo
      if (validation.required && (!fieldValue || fieldValue?.replace(/^\s+|\s+$/gm, "") === "" )){
        this.formErrors[fieldKey] = `${fieldName} is required`;
      }
      else if(validation.isTel && (isNaN(parseInt(fieldValue)) )){
        this.formErrors[fieldKey] = `${fieldName} you provided is invalid`;
      }
      else if(validation.isTel && fieldValue.toString().replace(/^\s+|\s+$/gm, '').length < 8){
        this.formErrors[fieldKey] = `${fieldName} you provided is short .${fieldName} cannot be less than 8 digits`;
      }else if(validation.min && fieldValue.toString().replace(/^\s+|\s+$/gm, '').length < validation.min){
        this.formErrors[fieldKey] = `${fieldName} must be at least .${validation.min} characters`;
      }
      else{
        delete this.formErrors[fieldKey];
      }
    },
    onClickModal(event) {
      const editUserModal = this.$refs.editUserModal;
      const target = event.target;
      if (editUserModal && editUserModal === target) {
        this.hideModal()
      }
    }
  },
  mounted() {
    console.log('this.user?.address')
    console.log(this.user)
    this.form.phone = this.user?.phone;
    this.form.name = this.user?.name;
    this.form.address = this.user?.address;
    this.form.profile_url = this.user?.profile_url;


  },
  created() {
    document.addEventListener("click", this.onClickModal);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.top {
  width: 200px;
  height: 150px;
  margin-bottom: 2rem;
  position: relative;
  > img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $border-radius;
  }
  .photo {
    position: absolute;
    left: 50%;
    top: 100%;
    background-color: $primary-color;
    transform: translate(-50%, -50%);
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 1rem;
    cursor: pointer;
    //&:hover {
    //  background-color: $yellow;
    //  color:$primary-color; ;
    //}
    //img {
    //  width: 60%;
    //}
    span{
      white-space: nowrap;
    }
  }
}
.mb-4{
  margin-bottom: 4rem !important;
}
.cursor-pointer {
  cursor: pointer;
}
.d-none{
  display: none;
}
</style>
