export async function processFiles(e, fileArray) {
  let photos = fileArray;
  let filteredFiles = [];
  if (e.target.files) {
    try {
      // eslint-disable-next-line no-unused-vars
      for (const index in e.target.files) {
        const file = e.target.files[index];
        if (file.size <= "509054") {
          file.url = URL.createObjectURL(file);
          filteredFiles.push(file);
        }
      }
    } catch (error_message) {
      console.log(error_message);
    }
    photos = photos.length === 0 ? filteredFiles : [...photos, ...filteredFiles];
    return photos;
  }
}

export function computeRoomId(receiver_id,sender_id){
  let parity_bit = 237;
  return parity_bit+receiver_id+sender_id;
}



