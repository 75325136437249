<template>
  <HeaderOne v-if="isLoggedIN"></HeaderOne>
  <HeaderTwo v-else></HeaderTwo>
  <main>
    <slot />
<!--    <transition-->
<!--      enter-active-class="animate__animated animate__slideInTop animate__faster"-->
<!--      leave-active-class="animate__animated animate__slideOutTop animate__faster"-->
<!--    >-->
      <Login
        v-if="showLoginModal"
        @showResetPasswordModal="toggleResetPassword"
      ></Login>
<!--    </transition>-->
    <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <ResetPassword v-if="showResetPasswordModal"></ResetPassword>
    </transition>
    <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <CheckEmail v-if="showCheckEmailModal"></CheckEmail>
    </transition>
    <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <EnterNewPassword v-if="showEnterNewPasswordModal"></EnterNewPassword>
    </transition>
    <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <ResetSuccess v-if="showResetSuccessModal"></ResetSuccess>
    </transition>
    <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <ResetPassword v-if="showResetPasswordModal"></ResetPassword>
    </transition>
    <!-- <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <CheckEmail v-if="false"></CheckEmail>
    </transition> -->
    <transition
      enter-active-class="animate__animated animate__slideInTop animate__faster"
      leave-active-class="animate__animated animate__slideOutTop animate__faster"
    >
      <Error v-if="showErrorModal"></Error>
    </transition>
      <Register v-if="showRegisterModal"></Register>

  </main>
  <Menu @showSidebar="slideSidebar"></Menu>
  <!-- <SecondMenu @showSidebar="slideSidebar"></SecondMenu> -->
    <SideNav @hideSidebar="slideSidebar" v-if="sidebarState"></SideNav>

</template>

<script>
import Menu from "../components/Menu";
// import SecondMenu from "../components/SecondMenu";
import SideNav from "../components/SideNav";
import Login from "@/components/Login";
import Register from "@/components/Register";
import ResetPassword from "@/components/ResetPassword";
import CheckEmail from "@/components/CheckEmail";
import EnterNewPassword from "@/components/EnterNewPassword";
import ResetSuccess from "@/components/ResetSuccess";
import Error from "@/components/Error";
import HeaderOne from "@/components/HeaderOne";
import HeaderTwo from "@/components/HeaderTwo";
import { mapState, mapMutations } from "vuex";
// import router from "../router";
// import store from "@/store/index";

export default {
  data() {
    return {
      sidebarState: false,
    };
  },
  components: {
    Menu,
    SideNav,
    Login,
    Register,
    ResetPassword,
    CheckEmail,
    EnterNewPassword,
    ResetSuccess,
    Error,
    // SecondMenu,
    HeaderOne,
    HeaderTwo,
  },
  computed: {
    ...mapMutations([
      "toggleLoginModal",
      "toggleRegisterModal",
      "toggleEnterNewPasswordModal",
    ]),

    ...mapState({
      showLoginModal: (state) => state.modals.showLoginModal,
      showCheckEmailModal: (state) => state.modals.showCheckEmailModal,
      showRegisterModal: (state) => state.modals.showRegisterModal,
      showResetPasswordModal: (state) => state.modals.showResetPasswordModal,
      showResetSuccessModal: (state) => state.modals.showResetSuccessModal,
      showErrorModal: (state) => state.modals.showErrorModal,
      showEnterNewPasswordModal: (state) =>
        state.modals.showEnterNewPasswordModal,
    }),
  },
  methods: {
    slideSidebar() {
      if (this.sidebarState) {
        this.sidebarState = false;
      } else {
        this.sidebarState = true;
      }
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  mounted() {
    if (this.$route.params.token) {
      this.$store.commit("toggleEnterNewPasswordModal");
    }
  },
  watch: {
    $route() {
      this.sidebarState = false;
    },
  },
};
</script>
