<template>
  <div class="con edit-page">
    <div class="info">
      <form class="info" @submit.prevent="updateService">
        <ul>
          <!-- <li>
            <div class="left">
              <div class="name">Category</div>
              <div class="category">
                <img src="../assets/icons/Professional.svg" alt="" />
                <span>Professional Services</span>
                <span>Legal Services</span>
              </div>
            </div>
            <a href="">Edit</a>
          </li>
          <li>
            <div class="left">
              <div class="name">Location</div>
              <div class="category">
                <img src="../assets/images/cmr.png" alt="" />
                <span>{{ location?.name }}</span>
              </div>
            </div>
            <a href="">Edit</a>
          </li> -->
          <div class="field">
            <div class="left">
              <div class="name">Category</div>

              <select v-model="category" @change="getSubCategoryList">
                <option value="" disabled selected>
                  <span>{{ service?.category_name }}</span>
                </option>
                <option
                  v-for="(category, index) in categories"
                  :key="index"
                  :value="category?.id"
                >
                  <span>{{ category.name }}</span>
                </option>
              </select>
            </div>
          </div>
          <div class="field">
            <div class="left">
              <div class="name">Sub Category</div>
              <select v-model="subCategory" multiple>
                <option
                  v-for="(category, index) in subCategories"
                  :key="index"
                  :value="category"
                >
                  <span>{{ category }}</span>
                </option>
                 </select>
            </div>
          </div>
          <div class="field">
            <div class="left">
              <div class="name">Location</div>
              <!-- <select v-model="location">
                <option :value="location ? location.id : ''" selected>
                  <span>{{
                    location ? location.name : "Select Location"
                  }}</span>
                </option>
                <option
                  v-for="(location, index) in locations"
                  :key="index"
                  :value="location.id"
                >
                  <span>{{ location.name }}</span>
                </option>
              </select> -->
              <select v-model="subCategory" multiple>
                <option
                  v-for="(category, index) in subCategories"
                  :key="index"
                  :value="category"
                >
                  <span>{{ category }}</span>
                </option>
                 </select>
            </div>
          </div>
          <li>
            <div class="left">
              <div class="name">Service Title</div>
              <input
                v-model="serviceTitle"
                type="text"
                name=""
                placeholder="Add title"
                id=""
              />
            </div>
          </li>
          <li>
            <div class="left">
              <div class="name">Service Description</div>
              <textarea
                v-model="serviceDescription"
                name=""
                placeholder="Be precise in service description in order to make users understand the scope of the service..."
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </li>
          <li>
            <div class="left">
              <div class="name">Edit photos</div>
              <!-- <div class="photos">
                <div class="img-con">
                  <img
                    src="../assets/images/boukaih-bGMyTnSlYvE-unsplash.jpg"
                    alt=""
                  />
                  <div class="actions">
                    <div class="action">
                      <img src="../assets/icons/delete_black_24dp.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div> -->
              <div v-if="!photos.length" class="add-photo">
                <img src="../assets/icons/image_black_24dp.svg" alt="" />
                <input
                  @change="handleFiles"
                  type="file"
                  name="photos"
                  id="photos"
                  data-max-size="509054"
                  ef="file"
                  multiple="multiple"
                  accept="image/gif, image/jpeg, image/png"
                />
                <p>Add at least 2 photos</p>
                <label for="photos">Upload photo from device</label>
              </div>
              <div v-else class="add-photo">
                <div class="photos">
                  <div
                    class="img-con"
                    v-for="(file, index) in photos"
                    :key="index"
                  >
                    <img :src="file.url ?? file" alt="" />
                    <div class="actions">
                      <a
                        href="javascript:void(0)"
                        @click="removeSelectedPhoto(index, file)"
                        class="action"
                      >
                        <img
                          src="../assets/icons/delete_black_24dp.svg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <input
                  @change="handleFiles"
                  type="file"
                  name="photos"
                  id="photos"
                  ef="file"
                  data-max-size="32154"
                  multiple="multiple"
                  accept="image/gif, image/jpeg, image/png"
                />
                <label for="photos">Upload more photo(s)</label>
              </div>
            </div>
          </li>
        </ul>
        <div class="btn-flex">
          <a href="" class="link">Cancel</a>
          <button type="submit" class="btn btn-yellow">Update Service</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState, mapActions, mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { processFiles } from "../store/helpers";
// import router from "../router";

export default {
  name: "Edit Service",
  components: {},
  data() {
    return {
      category: null,
      subCategory: [],
      currentLocation: null,
      location: null,
      serviceTitle: "",
      serviceDescription: "",
      photos: [],
      categories: [],
      subCategories: [],
      locations: [],
      service: null,
    };
  },
  validations() {
    return {
      location: { required },
      serviceTitle: { required },
      isSubmitted: false,
      errorSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["getServiceFromStore", "getLocationFromStore"]),
  },
  methods: {
    ...mapActions([
      "getAllCategories",
      "getAllLocations",
      "createServiceAction",
      "getAllSubCategories",
    ]),
    ...mapGetters(["isAuthenticated"]),

    async updateService() {
      let data = new FormData();
      data.append("id", this.service.id);
      data.append("name", this.serviceTitle);
      data.append("description", this.serviceDescription);
      data.append("user_id", Number(this.user?.id));
      data.append("location_id", Number(this.location.id));
      data.append("photos_count", this.photos.length);
      data.append("subcategories", this.subCategory.id);
      data.append("thumbnail", this.service.thumbnail);
      // if (this.photos.length > 0) {
      //   data.append("thumbnail", this.photos[0]);
      // }
      //Assigned to Fenn (Don't touch): fix this not to upload thumbnail again
      for (let i = 1; i <= this.photos.length; i++) {
        data.append(`photo_${i}`, this.photos[i - 1]);
      }
      this.isSubmitted = true;
      return this.createServiceAction(data)
        .then((response) => {
          this.$toast.success(`Service ${this.serviceTitle} created`, {
            position: "top",
          });
          console.log(response);
        })
        .catch((error) => {
          this.$toast.error(error, {
            position: "top",
          });
          console.log(error);
        });
    },

    async handleFiles(e) {
      this.photos = await processFiles(e, this.photos);
      console.log(this.photos instanceof Array);
    },
    async removeSelectedPhoto(index, file) {
      try {
        this.photos.splice(index, 1);
        this.$toast.success(`${file.name} removed`, {
          position: "top",
        });
      } catch (e) {
        this.$toast.error(`${file.name} removed`, {
          position: "top",
        });
      }
      //  this.$delete(this.finds, index)
    },

    async getServiceValues() {
      this.service = this.getServiceFromStore(this.$route.params.slug);

      this.serviceTitle = this.service.name;
      this.serviceDescription = this.service.description;
      this.photos = this.service.photos;
      this.currentLocation = this.getLocationFromStore(
        this.service.location_id
      );
      this.location = {
        label: this.currentLocation.name,
        value: this.currentLocation.id,
      };
      console.log(this.service);
      console.log(this.currentLocation);
    },

    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    async getSubCategoryList() {
      this.getAllSubCategories(Number(this.category))
        .then((response) => {
          const subCategories = response.data.data.data;
          this.$store.commit("setSubCategories", subCategories);
          this.subCategories = subCategories.map((subcategory) => {
            return { label: subcategory.name, value: subcategory.id };
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    init() {
      if (!this.isAuthenticated()) {
        this.toggleLoginModal();
      }

      this.getServiceValues();

      this.getAllCategories(100)
        .then((response) => {
          const categories = response.data.data.data;
          this.$store.commit("setCategories", categories);
          this.categories = categories;
        })
        .catch((error) => {
          console.log(error.response);
        });

      this.getAllLocations()
        .then((response) => {
          const locations = response.data.data;
          this.$store.commit("setLocations", locations);
          this.locations = locations.map((location) => {
            return { label: location.name, value: location.id };
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.edit-page {
  .info {
    margin-bottom: 3rem;
    // ul {
    //   li {
    //     display: flex;
    //     justify-content: space-between;
    //     border-bottom: 1px solid $border-color;
    //     padding: 1rem 0;
    //     &:last-child {
    //       margin-bottom: 1rem;
    //       border-bottom: none;
    //     }
    //     .name {
    //       font-size: 1rem;
    //       font-weight: 600;
    //       margin-bottom: 0.3rem;
    //     }
    //     .category {
    //       width: 100%;
    //       display: flex;
    //       align-items: center;
    //       margin-top: 0.5rem;
    //     }

    //     .category img {
    //       width: 25px;
    //       margin-right: 1rem;
    //     }

    //     .category span {
    //       font-size: 1rem;
    //       text-transform: capitalize;
    //       display: inline-block;
    //       padding: 0 0.3rem;
    //       line-height: 0.9;
    //       border-right: 1px solid $yellow;
    //     }

    //     .category span:last-child {
    //       border-right: none;
    //     }
    //     > a {
    //       font-size: 1rem;
    //       height: fit-content;
    //       line-height: 1.1;
    //       border-bottom: 1px solid $primary-color;
    //     }
    //   }
    // }
  }
  .photos {
    width: 100%;
    border-bottom: 1px solid $border-color;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem 0;
    .img-con {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        object-position: center;
        object-fit: cover;
      }
      .actions {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5rem;
        .action {
          width: 30px;
          height: 30px;
          background-color: $white;
          border-radius: 4px;
          margin-left: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.301);
          img {
            position: relative;
            transform: scale(0.6);
          }
        }
      }
    }
    @include for-large {
      grid-template-columns: repeat(3, 1fr);
      .img-con {
        height: 200px;
      }
      .add-img {
        height: 200px;
      }
    }
  }
}
.btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn {
    width: fit-content;
  }
  > a.link {
    font-size: 1rem;
    height: fit-content;
    line-height: 1.1;
    border-bottom: 1px solid $primary-color;
  }
}
</style>
