<template>
  <div class="type">{{ category?.name }}</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    subcategories: {
      // type: [],
      required: false,
    },
  },
  data() {
    return {
      category: { name: "Research Services" },
    };
  },
  computed: {
    ...mapGetters(["getCategoryFromStoreById"]),
  },
  methods: {
    async getServiceCategories() {
      if (this.subcategories?.length > 0) {
        this.category = this.getCategoryFromStoreById(
          this.subcategories[0]?.category_id
        );
      }
    },
  },
  mounted() {
    this.getServiceCategories();
  },
};
</script>
