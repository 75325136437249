<template>
  <div class="rating">
    <vue3-star-ratings
      v-model="averageRating"
      :starSize="'12'"
      :numberOfStars="1"
      :step="0.5"
      :starColor="'#fddb00'"
      :showControl="false"
      :disableClick="true"
    />
    {{ averageRating }}
    <span> ({{ review_count }} reviews)</span>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    service_id: {
      // type: [],
      required: true,
    },
  },
  data() {
    return {
      review_count: 0,
      averageRating: 0,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(["getAllServiceReviews"]),
  },
  mounted() {
    this.getAllServiceReviews(this.service_id)
      .then((response) => {
        let allRatings = 0;
        this.review_count = response.data.data.length;
        response.data.data.map((r) => {
          allRatings = allRatings + r.rating;
        });
        const avrRating = Number(allRatings / response.data.data.length);
        if (!isNaN(avrRating)) {
          this.averageRating = avrRating;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
.vue3-star-ratings__wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
