<template>
  <div class="category-details-page">
    <Loader v-if="showLoader" />
    <div class="con">
            <div class="top">
              <img v-if="viewedUser?.profile_url" :src="viewedUser?.profile_url" alt="" />
              <img v-else src="../assets/servicehub-avatar.png" alt="" />
            </div>
      <div class="actions">
        <p>Profile type</p>
        <div class="flex">
          <router-link  :to="'/users/'+user.id+'/services'" class="btn btn-black">Services</router-link>
          <router-link to="/add-service" v-if="user?.id === viewedUser?.id"  class="btn btn-light">Offer Service</router-link>
        </div>
      </div>
      <div class="info">
        <ul>
          <li>
            <div class="left">
              <div class="name">Name and Surname</div>
              <div class="value">{{ viewedUser?.name }}</div>
            </div>
          </li>
          <li v-if="viewedUser?.email">
            <div class="left">
              <div class="name">Email address</div>
              <div class="value">{{ viewedUser.email }}</div>
            </div>
          </li>
          <li v-if="viewedUser?.phone">
            <div class="left">
              <div class="name">Phone Number</div>
              <div class="value">{{viewedUser.phone }}</div>
            </div>
          </li>
          <li v-if="viewedUser?.address">
            <div class="left">
              <div class="name">Address</div>
              <div class="value">{{viewedUser.address}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="user?.id === viewedUser?.id" class="buttons">
        <button @click="showModal=true"  class="btn btn-yellow cursor-pointer">Edit</button>
      </div>
    </div>
     <edit-user-modal v-if="showModal" @hideModal="showModal=false" @toggleLoader="toggleLoader"></edit-user-modal>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Loader from "@/components/Loader";
import EditUserModal from "@/components/EditUserModal";
export default {
  name: "Customer Account",
  data() {
    return {
      viewedUser: null,
      // showLoader: false,
      showModal: false,
    };
  },
  components:{
    Loader,EditUserModal
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  methods: {
    ...mapActions(["getUserById"]),
    ...mapGetters(["isAuthenticated"]),
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    getUser() {
      this.getUserById(this.$route.params.id)
          .then((response) => {

            this.viewedUser = response;
            setTimeout(()=>{
              // this.showLoader = false;
              this.$store.commit("setLoader",false);
            },500);
          })
          .catch((error) => {
            console.log(error);
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          });

    },
    init() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      if (!this.isAuthenticated()) {
        this.toggleLoginModal();
        // this.showLoader =  false;
        this.$store.commit("setLoader",false);
      }
      else {
        this.getUser();
      }

    },
    toggleLoader(val){
      this.$store.commit("setLoader",val);
      // this.showLoader = val;
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
/* ------------------------- category services page ------------------------- */
.top {
  width: 130px;
  height: 130px;
  margin: 0 auto;
  margin-bottom: 2rem;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $border-radius;
  }
  .photo {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 100%;
    background-color: $primary-color;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $yellow;
    }
    img {
      width: 60%;
    }
  }
}
.actions {
  margin-bottom: 1rem;
  p {
    color: $text;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .flex {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 48%;
    }
  }
}

.info {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $border-color;
      padding: 1rem 0;
      .name {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0.3rem;
      }
      .value {
        font-size: 1rem;
        color: $text;
      }
      > a {
        font-size: 1rem;
        height: fit-content;
        line-height: 1.1;
        border-bottom: 1px solid $primary-color;
      }
    }
  }
}
.cursor-pointer{
  cursor: pointer;
}
</style>
