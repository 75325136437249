import APIService from "../../services/api.service";

const state = {
paymentPlans:[]
};

const actions = {
    async getPaymentPlans({ commit }) {
        return new Promise((resolve, reject) => {
            APIService.getPaymentPlans()
                .then((response) => {
                    commit("setPaymentPlans", response.data.data.plans);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    async makePayment({ commit,state },data) {
        return new Promise((resolve, reject) => {
            APIService.makePayment(data)
                .then((response) => {
                    commit("setPaymentPlans", state.paymentPlans);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async getPaymentStatus({ commit,state },reference) {
        return new Promise((resolve, reject) => {
            APIService.getPaymentStatus(reference)
                .then((response) => {
                    console.log("PLANS FROM THE SERVER");
                    console.log(response)
                    commit("setPaymentPlans", state.paymentPlans);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

const getters = {
    getPaymentPlansFromStore: (state) => () => {
        return state.paymentPlans;
    },

};

const mutations = {
    setPaymentPlans(state, payload) {
        state.paymentPlans = payload;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};
