<template>
  <div class="service-history-page">
    <Loader v-if="showLoader" />
    <div v-if="!showLoader" class="con">
      <div class="top">
        <img src="../assets/icons/Professional.svg" alt="" />
        <span>Professional Services</span>
      </div>
      <div class="services-list">
        <ul>
          <li v-for="(service, index) in services" :key="index">
            <router-link :to="{ path: `/services/${service.slug}` }">
              <div class="left">
                <div class="name">{{ service.name }}</div>
                <!-- <div class="sub">753 services</div> -->
              </div>
              <div class="icon">
                <img
                  src="../assets/icons/navigate_next_black_24dp.svg"
                  alt=""
                />
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapState, mapGetters, mapActions} from "vuex";
import Loader from "../components/Loader.vue";

export default {
  name: "Home",
  components: { Loader },
  data() {
    return {
      categories: null,
      services: null,
      // showLoader: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),

    ...mapGetters(["getAllServicesFromStore"]),
  },
  methods: {
    ...mapActions([
      "getAllServices",
    ]),
    likeService(e) {
      e.preventDefault();
      if (!this.user) {
        this.toggleLoginModal();
      }
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },

  mounted() {
    // this.showLoader = true;
    this.$store.commit("setLoader",true);
    // this.services = this.getAllServicesFromStore();
    const all_services = this.getAllServicesFromStore();
    if (all_services){
      this.services = all_services
      // this.showLoader = false;
      this.$store.commit("setLoader",false);
    }else{
      this.getAllServices()
          .then((response) => {

            this.services = response.data.data.data;
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          })
          .catch((error) => {
            console.log(error);
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          });
    }

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.service-history-page {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border-color;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
    img {
      height: 35px;
      margin-right: 1rem;
    }
  }
}
.services-list {
  width: 100%;
  height: auto;
}

.services-list ul {
  padding: 1rem 0;
}
.services-list ul li a {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid $border-color;
  text-decoration: none;
  &:hover {
    background-color: rgba($light-gray, 0.5);
  }
  @include for-tablet {
    padding: 1rem 0;
  }
}

.services-list ul li a .left .name {
  font-size: 1.1rem;
  font-weight: 600;
  color: $text;
  text-transform: capitalize;
}
.services-list ul li a .left .sub {
  font-size: 1rem;
  color: $light-text;
}

.services-list ul li a .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.services-list ul li a .icon img {
  width: 80%;
}
/* for extra small screens and lower  */
@include for-extra-small {
  .services-list {
    width: 100%;
    height: auto;
  }

  .services-list ul {
    padding: 0.5rem 0;
  }

  .services-list ul li a .left .name {
    font-size: 1rem;
  }
  .services-list ul li a .icon {
    width: 20px;
    height: 20px;
  }

  .services-list ul li a .icon img {
    width: 80%;
  }
}
</style>
