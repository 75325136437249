<template>
  <div class="side-nav animate__animated animate__slideInRight animate_faster">
    <div class="close">
      <div @click="$emit('hideSidebar')" class="close-btn">
        <img src="@/assets/icons/icon-close.svg" alt=""/>
      </div>
    </div>
    <div v-if="user" class="user-info">
      <div class="img-con">
        <router-link to="/profile"
                     @click="$emit('hideSidebar')"
        >
          <img v-if="user.profile_url" :src="user.profile_url" alt="" />
          <img v-else src="../assets/servicehub-avatar.png" alt="" />
        </router-link>
      </div>
      <div class="content">
        <div class="top">
          <router-link to="/profile"
                       @click="$emit('hideSidebar')"
          >
            <span>{{ user?.name }}</span>
          </router-link>

          <!-- <div> -->
          <a href="javascript:void(0)" @click="toggleLogout"
          ><img src="@/assets/icons/navigate_next_black_24dp.svg" alt=""
          /></a>
          <!-- </div> -->
        </div>
        <p>User</p>
      </div>
    </div>
    <ul v-if="user && showLogout" class="nav-dropdown-menu">
      <li><a href="javascript:void(0)" @click="checkAuthState">Logout</a></li>
    </ul>
    <div v-if="!user" class="auth-btns">
      <a @click="toggleLogin" class="btn btn-yellow">Sign In</a>
      <a @click="toggleRegister" class="btn btn-black">Sign Up</a>
    </div>
    <ul>
      <li>
        <router-link to="/" @click="$emit('hideSidebar')">Home</router-link>
      </li>
      <li v-if="user"
          @click="$emit('hideSidebar')"
      >
        <router-link
            :to="{
            path: '/add-service',
          }"

        >Add Service
        </router-link
        >
      </li>
      <li v-if="user">
        <router-link
            :to="{
            path: '/add-category',
          }"
            @click="$emit('hideSidebar')"
        >Add Category
        </router-link
        >
      </li>
      <li v-if="user">
        <router-link
            :to="{
            path: '/add-sub-category',
          }"
            @click="$emit('hideSidebar')"
        >Add Sub-Category
        </router-link
        >
      </li>
      <li v-if="isAdmin">
        <router-link to="/block-user-details"
                     @click="$emit('hideSidebar')"
        >Blocked User Details
        </router-link>
      </li>
      <li v-if="isAdmin">
        <router-link to="/block-users"
                     @click="$emit('hideSidebar')"
        >Blocked Users
        </router-link>
      </li>
      <li>
        <router-link to="/categories"
                     @click="$emit('hideSidebar')"
        >Categories
        </router-link>
      </li>
      <li v-if="user">
        <router-link to="/favorite"
                     @click="$emit('hideSidebar')"
        >Favorite
        </router-link>
      </li>
      <li>
        <router-link to="/general-info"
                     @click="$emit('hideSidebar')"
        >General Info
        </router-link>
      </li>
      <li>
        <router-link to="/location"
                     @click="$emit('hideSidebar')"
        >Location
        </router-link>
      </li>
      <li v-if="user">
        <router-link to="/chats"
                     @click="$emit('hideSidebar')"
        >Messages
        </router-link>
      </li>
      <li v-if="user">
        <router-link :to="'/users/'+user.id+'/services'"
                     @click="$emit('hideSidebar')"
        >My Services
        </router-link>
      </li>
      <li v-if="user">
        <router-link to="/notifications"
                     @click="$emit('hideSidebar')"
        >Notifications
        </router-link>
      </li>
      <li>
        <router-link to="/pricing"
                     @click="$emit('hideSidebar')"
        >Pricing
        </router-link>
      </li>
      <li v-if="user">
        <router-link to="/profile"
                     @click="$emit('hideSidebar')"
        >My Profile
        </router-link>
      </li>
      <li v-if="user">
        <router-link to="/report-provider"
                     @click="$emit('hideSidebar')"
        >Report Provider
        </router-link>
      </li>
      <li>
        <router-link to="/service-history"
                     @click="$emit('hideSidebar')"
        >History
        </router-link>
      </li>
      <li>
        <router-link to="/services"
                     @click="$emit('hideSidebar')"
        >Services
        </router-link>
      </li>
      <li v-if="isAdmin">
        <router-link to="/statistics"
                     @click="$emit('hideSidebar')"
        >Statistics
        </router-link>
      </li>
      <li v-if="isAdmin">
        <router-link to="/users"
                     @click="$emit('hideSidebar')"
        >Users
        </router-link>
      </li>
    </ul>
  </div>
  <div class="overlay" @click="$emit('hideSidebar')"></div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  data() {
    return {
      showLogout: false,
      isAdmin: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    }),
    ...mapGetters(["isAdminFromStore"]),
  },
  emits: ["hideSidebar","showSidebar"],
  methods: {
    ...mapMutations(["toggleLoginModal", "toggleRegisterModal"]),
    ...mapActions(["logOut", "hasAdminRole","logOut2"]),
    toggleLogin() {
      this.toggleLoginModal();
      this.$emit('hideSidebar');
    },
    toggleRegister() {
      this.toggleRegisterModal();
      this.$emit('hideSidebar');
    },
    checkAuthState(e) {
      e.preventDefault();
      this.logOut2();
    },
    toggleLogout() {
      return (this.showLogout = !this.showLogout);
    },

  },
  mounted() {
    this.isAdmin = this.isAdminFromStore();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  background-color: #0000005d;
}

.side-nav {
  width: 300px;
  height: 100vh;
  background-color: $white;
  box-shadow: 3px 0px 5px 2px #00000021;
  position: fixed;
  z-index: 5;
  right: 0;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 70px;
  padding-bottom: 85px;
  @include for-tablet {
    width: 350px;
  }

  .close {
    width: calc(100% - 2rem);
    margin: 1rem;
    display: flex;
    justify-content: flex-end;

    .close-btn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;

      img {
        width: 50%;
      }

      &:hover {
        background-color: darken($light-gray, 10%);
      }
    }
  }

  .user-info {
    width: calc(100% - 2rem);
    border-radius: $border-radius;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: 0 1rem;

    .img-con {
      width: 50px;
      height: 50px;
      border: 2px solid $yellow;
      border-radius: 50%;
      margin-right: 1rem;
      cursor: pointer;

      &:hover {
        background-color: $light-gray !important;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 57%;
        position: relative;
        transform: scale(0.8);
      }
    }

    .content {
      width: auto;
      flex-grow: 1;

      .top {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-size: 1rem;
          color: $primary-color;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        img {
          position: relative;
          transform: rotateZ(90deg);
        }
      }

      p {
        font-size: 0.9rem;
        color: $light-text;
      }
    }
  }

  ul.nav-dropdown-menu {
    padding: 0.2rem;
    margin-top: -2rem;
    margin-left: 3rem;
    width: auto;
    position: absolute;
    right: 5px;
    box-shadow: 0px 2px 3px 0px $light-text;

    li {
      border: none;
    }
  }

  .auth-btns {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;

    .btn {
      width: 48%;
      font-size: 0.9rem;
      padding: 0 0.5rem;
      cursor: pointer;
    }
  }

  ul {
    width: 100%;
    height: auto;
    padding: 1rem;
    @include for-tablet {
      padding: 1rem;
    }

    li {
      border-bottom: 1px solid $border-color;

      a {
        display: flex;
        width: 100%;
        padding: 0.3rem 1rem;
        margin: 0.3rem 0;
        border-radius: $border-radius;

        &:hover {
          background-color: lighten($yellow, 40%);
        }
      }
    }
  }
}
</style>
