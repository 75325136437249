<template>
  <div v-if="service" class="service-page">
    <div class="service-img-con">
      <img :src="service.thumbnail ?? thumbnailPlaceholder" alt=""/>
    </div>
    <div class="con">
      <div v-if="service">
        <section class="service-top">
          <div class="flex">
            <ServiceCategoryDisplay
                class="service-type"
                :subcategories="service.sub_categories"
            />
            <div class="icons">
              <likes
                  class="icon"
                  :user_id="user?.id ?? null"
                  :now="service.likes ?? []"
                  :service_id="service.id ?? null"
                  :numberOfLikes="service.like_count"
              ></likes>
              <a href="" class="icon">
                <img src="../assets/icons/share_black_24dp.svg" alt=""/>
              </a>
              <a
                  v-if="user?.id === service.user.id"
                  href="javascript:void(0)"
                  @click="editService(service.slug)"
                  class="icon"
              >
                <img src="../assets/icons/edit.png" alt=""/>
              </a>
            </div>
          </div>
          <div class="name">{{ service.name }}</div>
          <div class="rating">
            <Reviews :service_id="service?.id ?? null"/>
          </div>
        </section>
        <section class="service-location">
          <h4>location</h4>
          <ServiceLocationDisplay :location_id="service?.location_id"/>
        </section>

        <div class="actions flex">
          <div class="contact-options">
            <a v-if="user" @click.prevent="openChat()" href="javascript:void(0)" class="chat">
              <img src="../assets/icons/chat_white_24dp.svg" alt=""/>
              <span>Chat</span>
            </a>
            <div v-if="hasPaidPlans(service.user?.plan)">
              <a v-if="service.user.phone" :href="'tel:'+service.user.phone" @click="toggleShowNumber" class="call">
                <img src="../assets/icons/call_white_24dp.svg" alt="" />
                <span>Call</span>
              </a>
                <span v-if="showNumber">
                Provider number: {{ service.user?.phone }}
              </span>
            </div>

          </div>
          <div v-if="hasPaidPlans(service.user?.plan)" class="contact-options request">
            <a href="javascript:void(0)" @click="toggleRequest" class="request">
              <img src="../assets/icons/chat_white_24dp.svg" alt=""/>
              <span>{{ !showRequest ? `Request Service` : `Cancel` }}</span>
            </a>
          </div>
        </div>
        <br/>
        <div v-if="showRequest" class="con">
          <form action="">
            <div class="field">
              <h4>Service request message</h4>
            </div>
            <div class="field">
              <h4>Care to describe?</h4>
              <textarea
                  name=""
                  v-model="requestMessage"
                  placeholder="Give us a brief description of your request..."
                  id=""
                  cols="30"
                  rows="10"
              ></textarea>
            </div>
            <div class="btn-flex">
              <a href="javascript:void(0)" @click="toggleRequest" class="link">
                Cancel
              </a>
              <a
                  href="javascript:void(0)"
                  @click="requestService"
                  class="btn btn-yellow"
              >Send Request</a
              >
            </div>
          </form>
          <p>
            Some text about responsibility when requesting a service and the
            time needed for request to be attended to.
          </p>
        </div>

        <section class="service-description">
          <h4>Service Description</h4>
          <p>
            {{ service.description }}
          </p>
          <a href="">Show more</a>
        </section>
      </div>
      <section class="service-provider">
        <h4>Service Provider</h4>

        <div class="provider">
          <router-link :to="'/users/'+service.user.id">
            <img v-if="service.user?.profile_url" :src="service.user?.profile_url" alt="User profile image"/>
            <img v-else src="../assets/servicehub-avatar.png" alt=""/>
            <div class="info">
              <div class="name">{{ service.user.name }}</div>
              <p>
                {{service.user?.created_at}}
              </p>
            </div>
          </router-link>
        </div>
      </section>
      <section class="service-report">
        <img src="../assets/icons/flag_black_24dp.svg" alt=""/>
        <router-link
            tag="a"
            :to="{ path: `/report-provider/${service.user.id}` }"
        >Report this provider
        </router-link
        >
      </section>
      <section class="service-reviews">
        <h4>Reviews ({{ reviews.length }})</h4>
        <div class="reviews">
          <div
              v-for="(review, index) in reviews.slice(0, listLimit)"
              :key="index"
              class="review-card"
          >
            <div class="top">
              <div class="left">
                <img
                    src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
                    alt=""
                />
                <div class="info">
                  <div class="name">{{ review.user.name }}</div>
                  <div class="rating">
                    <vue3-star-ratings
                        v-model="review.rating"
                        :starSize="12"
                        :starColor="'#fddb00'"
                        :showControl="false"
                        :disableClick="true"
                    />
                  </div>
                </div>
              </div>
              <div class="time">
                {{review?.created_at}}
              </div>
            </div>
            <p>
              {{ review.review_message }}
            </p>
          </div>
        </div>
        <div class="flex">
          <a @click="seeMoreReviews" href="javascript:void(0)"
          >See all reviews</a
          >
          <a
              href="javascript:void(0)"
              @click="toggleReview"
              class="btn btn-yellow"
          >{{ !showReview ? "Leave Review" : "Close Review" }}</a
          >
        </div>
        <div v-if="showReview" class="con">
          <form action="">
            <div class="field">
              <h4>How was your experience</h4>
              <div class="review">
                <vue3-star-ratings
                    v-model="rating"
                    :starSize="24"
                    :starColor="'#fddb00'"
                    :step="1"
                    :showControl="false"
                />
              </div>
            </div>
            <div class="field">
              <h4>Care to describe?</h4>
              <textarea
                  name=""
                  v-model="reviewMessage"
                  placeholder="Tell us what you liked, what you thought is wrong..."

                  cols="30"
                  rows="10"
              ></textarea>
            </div>
            <div class="btn-flex">
              <a href="" class="link">Cancel</a>
              <a
                  href="javascript:void(0)"
                  @click="createReview"
                  class="btn btn-yellow"
              >Save Review</a
              >
            </div>
          </form>
          <p>
            Some text about responsibility when posting review and the time
            needed for review to be published
          </p>
        </div>
      </section>
      <section class="services-services">
        <h4>Other services by this provider</h4>
        <div class="services" v-if="providerServices?.length > 0">
          <!-- <Service :service="providerServices" /> -->
          <div
              class="service-card"
              v-for="service in providerServices"
              :key="service.id"
          >
            <img
                :src="
                service.thumbnail ??
                '@/assets/images/olivier-depaep-0clJTXtgsIY-unsplash.jpg'
              "
                alt=""
            />
            <div class="content">
              <div class="top">
                <Reviews :service_id="service?.id ?? null"/>
                <likes
                    :user_id="user?.id ?? null"
                    :now="service.likes ?? []"
                    :service_id="service.id ?? null"
                    :numberOfLikes="service.like_count"
                ></likes>
              </div>
              <a
                  href="javascript:void(0)"
                  @click="currentServiceDetails(service.slug)"
                  class="name"
              >
                {{ service.name }}
              </a>
              <ServiceCategoryDisplay :subcategories="service.sub_categories"/>
              <ServiceLocationDisplay :location_id="service?.location_id"/>
            </div>
          </div>
        </div>
        <div class="text-center">
          <router-link tag="a" :to="{ path: '/services' }" class="name"
          >See all services
          </router-link>
        </div>
      </section>
    </div>

    <service-modal v-if="showModal" @hideServiceModal="hideModal" :view-limit="viewLimit"></service-modal>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapState, mapGetters, mapActions} from "vuex";
import router from "../router";
import Likes from "../components/Likes.vue";
import Reviews from "../components/Reviews.vue";
import ServiceLocationDisplay from "../components/ServiceLocationDisplay.vue";
import ServiceCategoryDisplay from "../components/ServiceCategoryDisplay.vue";
import moment from "moment";
import ServiceModal from "@/components/ServiceModal";

export default {
  name: "Service Details",
  data() {
    return {
      category: null,
      service: null,
      thumbnailPlaceholder:
          "../assets/images/olivier-depaep-0clJTXtgsIY-unsplash.jpg",
      providerServices: null,
      serviceProvider: null,
      showReview: false,
      showRequest: false,
      reviews: [],
      reviewMessage: null,
      requestMessage: null,
      rating: 0,
      listLimit: 5,
      showNumber: false,
      viewLimit:5,
      showModal:false,
      serviceSlug: this.$route.params.slug
    };
  },
  components: {
    Likes,
    Reviews,
    ServiceLocationDisplay,
    ServiceCategoryDisplay,
    ServiceModal,
  },
  //
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters([
      "getLocationFromStore",
      "getServiceFromStore",
      "getAllServicesFromStore",
      "getUserFromStore",
    ]),
  },
  methods: {
    ...mapActions([
      "getAllServiceReviews",
      "createReviewAction",
      "getAllUsers",
      "createRequestAction",
    ]),
    checkAuthState(e) {
      e.preventDefault();
      if (!this.user) {
        this.toggleLoginModal();
      }
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    toggleProviderNumberModal() {
      this.$store.commit("toggleProviderNumberModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    seeMoreReviews() {
      this.listLimit = this.listLimit + 5;
    },
    hideModal(){
      this.showModal = false;
      router.push({
        path: `/services`,
      });
    },

    async currentServiceDetails(selectedSlug) {
      //set view limit
      this.setViewedServices();
      let serviceSlug = null;
      if (selectedSlug) {
        serviceSlug = selectedSlug;
        router.push({
          path: `/services/${selectedSlug}`,
        });
      } else {
        serviceSlug = this.$route.params.slug;
      }
      this.service = this.getServiceFromStore(serviceSlug);
      // this.serviceProvider = this.getUserFromStore(this.service.id);
      this.getAllServiceReviews(this.service?.id)
          .then((response) => {
            this.reviews = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      this.providerServices = this.getAllServicesFromStore().map((service) => {
        let filteredService = {};
        let reviews = 0;
        if (
            service.user_id === this.service.user_id &&
            service.slug !== this.service.slug
        ) {
          this.getAllServiceReviews(service.id)
              .then((response) => {
                reviews = response.data.data.length;
              })
              .catch((error) => {
                console.log(error);
              });
        }
        if (service.slug !== this.service.slug) {
          filteredService = service;
        }
        return {
          ...filteredService,
          reviewNumber: reviews,
        };
      });
    },

    toggleReview() {
      this.checkAuthState;
      return (this.showReview = !this.showReview);
    },
    toggleRequest() {
      this.checkAuthState;
      return (this.showRequest = !this.showRequest);
    },
    toggleShowNumber() {
      this.checkAuthState;
      return (this.showNumber = !this.showNumber);
    },
    //create review for a particular service
    async createReview() {
      let data = new FormData();
      data.append("service_id", this.service?.id);
      data.append("user_id", this.user?.id);
      data.append("review_message", this.reviewMessage);
      data.append("rating", Number(this.rating));
      console.log(this.service.id);
      return this.createReviewAction(data)
          .then((response) => {
            this.$toast.success(
                `${this.user.name} reviewed ${this.service.name}`,
                {
                  position: "top",
                }
            );

            this.getAllServiceReviews(this.service.id)
                .then((response) => {
                  this.reviews = response.data.data;
                })
                .catch((error) => {
                  console.log(error);
                });
            console.log(response.data.data);
            this.toggleReview();
          })
          .catch((error) => {
            this.$toast.error(error.response, {
              position: "top",
            });
            this.toggleReview();
            console.log(error);
          });
    },
    //create request for service
    async requestService() {
      let data = new FormData();
      data.append("service_id", this.service?.id);
      data.append("sender_id", this.user?.id);
      data.append("message", this.requestMessage);
      this.createRequestAction(data)
          .then((response) => {
            this.$toast.success(
                `Request for ${this.service.name} successfully made`,
                {
                  position: "top",
                }
            );
            this.toggleRequest();
            console.log(response);
          })
          .catch((error) => {
            this.$toast.error(error.response, {
              position: "top",
            });
            this.toggleRequest();
          });
    },

    editService(slug) {
      router.push({
        path: `/edit-service/${slug}`,
      });
    },

    openChat() {
      let chat = {
        receiver: this.service?.user,
        room_id: undefined
      };
      localStorage.setItem('chat', JSON.stringify(chat));
      router.push({
        path: `/chats/${this.service.user?.id}`,
      });
    },
    setViewedServices() {
      /**
       * Function check if user is on free plan
       * sets limit for number of services to be viewed per day
       * @type {boolean}
       */
      let isFreePlan = this.hasSubscribed(this.user?.plan, 'free');
      if (!this.user || isFreePlan) {
        let today = moment(new Date()).format("MM-DD-YYYY");
        let viewServicesData = {
          total: 1,
          currentDay: today,
          "services":[this.$route.params.slug]
        }
        let viewServices = localStorage.getItem("viewServices")
        if (viewServices){
          viewServices = JSON.parse(localStorage.getItem("viewServices"))

          if (viewServices.currentDay === today) {
              viewServicesData.total = viewServices.total;
              viewServicesData.services = viewServices.services;
            if (viewServices.services.indexOf(this.$route.params.slug) === -1){
              viewServicesData.total = viewServices.total + 1;
              viewServicesData.services.push(this.$route.params.slug)
            }

          }
        }
        //set viewServices
        if (viewServicesData.total <= this.viewLimit){
          localStorage.setItem('viewServices',JSON.stringify(viewServicesData));
        }else{
          this.showModal = true;
        }

      }
    },
    hasSubscribed(plans, plan) {
      let hasPlan = false;
      if (plans && plans.length) {
        for (const userPlan of plans) {
          if (userPlan.tag === plan) {
            hasPlan = true;
            break;
          }
        }
      }
      return hasPlan;
    },
    hasPaidPlans(plans){
      let paidPlan = false;
      if (plans && plans.length){
        for (const userPlan of plans) {
          if (userPlan.tag !== 'free') {
                paidPlan = true;
              break
          }
        }
      }
      return paidPlan;
    }
  },
  mounted() {
    this.currentServiceDetails(this.$route.params.slug);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.service-img-con {
  width: 100%;
  height: 250px;
  position: relative;
  @include for-large {
    height: 300px;
    width: 1100px;
    margin: 0 auto;
  }
}

.service-img-con img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.service-img-con .con {
  text-align: left;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  padding-top: 1rem;
  z-index: 2;
}

.service-img-con .con img {
  width: 30px;
  @include for-large {
    display: none;
  }
}

.service-page section {
  width: 100%;
  border-bottom: 1px solid $border-color;
  padding: 1rem 0;
  margin: 0;
}

.service-page section h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.service-page .service-top .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-page .service-top .flex .service-type {
  font-size: 1rem;
  margin: 0;
  color: $light-text;
}

.service-page .service-top .flex .icons {
  display: flex;
}

.service-page .service-top .flex .icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
}

.service-page .service-top .flex .icon img {
  width: 60%;
}

.service-page .service-top .name {
  font-size: 1.5rem;
  color: $text;
  font-weight: 600;
  margin: 0.5rem 0;
}

.service-page .service-top .rating {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: $text;
}

.service-page .service-top .rating img {
  width: 18px;
  margin-right: 0.3rem;
}

.service-page .service-top .rating span {
  color: $light-text;
  display: inline-block;
  margin-left: 0.2rem;
}

.service-page .service-location .location {
  width: 100%;
  display: flex;
  align-items: center;
}

.service-page .service-location .location img {
  width: 20px;
  margin-right: 0.7rem;
}

.service-page .service-location .location span {
  font-size: 1rem;
  text-transform: capitalize;
  display: inline-block;
  padding: 0 0.5rem;
  line-height: 1rem;
}

.service-page .service-location .location span:last-child {
  border-left: 1px solid $yellow;
}

.service-page .service-description p {
  color: $text;
  font-size: 1rem;
  line-height: 1.5;
}

.service-page .service-description a {
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}

.service-page .service-provider .provider {
  width: 100%;
  display: flex;
  align-items: center;
}

.service-page .service-provider .provider img {
  width: 70px;
  height: 70px;
  border-radius: $border-radius;
  object-fit: cover;
  object-position: center;
  margin-right: 1rem;
}

.service-page .service-provider .provider .info .name {
  font-size: 1.3rem;
  color: $text;
}

.service-page .service-provider .provider .info p {
  font-size: 1rem;
  color: $light-text;
  margin: 0;
}

.service-page .service-report {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-page .service-report img {
  width: 25px;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
}

.service-page .service-report a {
  font-size: 1.1rem;
  font-weight: 600;
  color: $text;
  line-height: 1;
  border-bottom: 1px solid $text;
}

.review-card {
  width: 100%;
  height: auto;
  margin-bottom: 2rem;

  > p {
    margin: 0.5rem 0;
  }
}

.review-card .top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.review-card .top .left {
  display: flex;
}

.review-card .top .left > img {
  width: 45px;
  height: 45px;
  border-radius: $border-radius;
  margin-right: 0.5rem;
}

.review-card .top .left .name {
  font-size: 1rem;
  color: $text;
  font-weight: 600;
}

.review-card .top .left .rating {
  display: flex;
  height: fit-content;
  margin-top: 0.3rem;

  img {
    width: 15px;
    height: auto;
    margin: 0;
    margin-right: 0.1rem;
  }
}

.review-card .top .time {
  color: $light-text;
  font-size: 1rem;
  object-fit: cover;
  object-position: center;
}

.service-reviews .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-reviews .flex a {
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}

.service-reviews .flex a.btn {
  border: none;
}

.services-services .text-center {
  text-align: center;
  margin-top: 1rem;
}

.services-services .text-center a {
  text-align: center;
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}

.actions {
  display: flex;

  .contact-options {
    width: 100vw;
    height: auto;
    position: fixed;
    left: 0;
    bottom: -2px;
    border-radius: 10px 10px 0 0;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 0 -2px 5px 3px rgba(24, 24, 24, 0.082);
    @include for-large {
      &.request {
        justify-content: flex-end;
      }
      position: static;
      width: 100%;
      box-shadow: none;
      justify-content: flex-start;
      padding: 1rem 0;
    }
  }

  @include for-large {
    position: static;
    width: 100%;
    box-shadow: none;
    border-bottom: 1px solid $border-color;
    justify-content: space-between;
    padding: 1rem 0;
  }
}

.actions {
  .contact-options a {
    width: 49%;
    height: auto;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    color: $white;
    @include for-large {
      width: fit-content;
      margin-right: 1rem;
      padding: 0.5rem 1.5rem;
    }
  }
}

.contact-options a img {
  width: 25px;
  margin-right: 1rem;
  margin-bottom: 0;
}

.contact-options a.chat {
  background-color: orange;
}

.contact-options a.call,
.contact-options a.request {
  background-color: green;
}

/* for extra small screens and lower  */
@include for-extra-small {
  .service-img-con {
    height: 150px;
  }
  .service-page section {
    padding: 1rem 0;
  }

  .service-page section h4 {
    font-size: 1rem;
  }
  .service-page .service-top .flex .icon {
    width: 30px;
    height: 30px;
  }
  .service-page .service-top .flex .icon img {
    width: 70%;
  }
  .service-page .service-top .name {
    font-size: 1.2rem;
  }
  .service-page .service-top .rating {
    font-size: 0.9rem;
  }
  .service-page .service-top .rating img {
    width: 15px;
  }

  .service-page .service-location .location img {
    width: 15px;
    margin-right: 0.3rem;
  }

  .service-page .service-location .location span {
    font-size: 0.9rem;
  }
  .service-page .service-description a {
    font-size: 0.9rem;
  }
  .service-page .service-provider .provider img {
    width: 50px;
    height: 50px;
  }
  .service-page .service-provider .provider .info .name {
    font-size: 1.1rem;
  }
  .service-page .service-provider .provider .info p {
    font-size: 0.9rem;
  }
  .service-page .service-report img {
    width: 20px;
  }
  .service-page .service-report a {
    font-size: 1rem;
  }
  .review-card .top .left {
    display: flex;
  }
  .review-card .top .left img {
    width: 40px;
    height: 40px;
  }

  .review-card p {
    margin-top: 1rem;
  }

  .review-card .top .left .name {
    font-size: 0.9rem;
  }
  .review-card .top .time {
    font-size: 0.9rem;
  }
  .service-reviews .flex a {
    font-size: 0.9rem;
  }
  .contact-options {
    border-radius: 8px 8px 0 0;
    padding: 1rem;
  }

  .contact-options a {
    padding: 0.5rem 0;
  }
  .contact-options a img {
    width: 18px;
    margin-right: 0.5rem;
  }
}

form {
  .field {
    border: none;
    margin-bottom: 1rem;

    h4 {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .review {
      display: flex;
      align-items: center;

      img {
        width: 25px;
        margin-right: 0.3rem;
      }
    }

    textarea {
      width: 100%;
      border: 1px solid $border-color;
      padding: 1rem;
      font-size: 1rem;
      color: $text;
      border-radius: $border-radius;
    }
  }

  .btn-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .btn {
      width: fit-content;
    }

    > a.link {
      font-size: 1rem;
      height: fit-content;
      line-height: 1.1;
      border-bottom: 1px solid $primary-color;
    }
  }
}

p {
  font-size: 1rem;
  color: $light-text;
  text-align: center;
}

.vue3-star-ratings__wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
