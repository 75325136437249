<template>
  <div ref="googleLogin">
    <div id="google-sign-in" @click.prevent="toggleLoader"></div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "GoogleLogin",
  emits:['get-google-token','social-login'],
  computed: {
    ...mapState({
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  methods:{
    initGoogle() {
      /* eslint-disable-next-line */
      if(google){
        this.googleSetup()
      }else{
        setTimeout(()=>{
          this.googleSetup()
        },1000)
      }

    },
    googleSetup(){
      /* eslint-disable-next-line */
      google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        callback: this.handleCredentialResponse
      });
      /* eslint-disable-next-line */
      google.accounts.id.renderButton(
          document.getElementById("google-sign-in"),
          {theme: "outline", size: "large"}  // customization attributes
      );
      /* eslint-disable-next-line */
      // google.accounts.id.prompt(); // also display the One Tap dialog
    },
    handleCredentialResponse(response) {
      this.$store.commit("setLoader",false);
      this.$emit('social-login');
      this.$emit('get-google-token',response.credential)
    },
    toggleLoader(){
      console.log("button was click")
      this.$store.commit("setLoader",true);
    },
    onClickModal(event) {
      // const paymentModal = this.$refs.paymentModal;
      const target = event.target;
      console.log("event")
      console.log(target)
      // if (paymentModal && paymentModal === target) {
      //   this.hideModal()
      // }
    }

  },

  mounted() {
    // await this.loadGoogleClient(document, "script", "google-client");
    console.log("google component")
   this.initGoogle();
  },
  created() {
    console.log("nmnmnm")
    document.addEventListener("click", this.onClickModal);
  },
}
</script>

<style scoped>
#google-sign-in{
  width: 100%;
}
</style>