<template>
  <div class="con favorite-page">
    <div class="services">
      <Service :services="services" :page="'myServices'" />
    </div>
    <router-link v-if="parseInt( $route.params.id) === parseInt(user.id) " tag="a" to="/add-service" class="btn btn-yellow"
      >Add Service</router-link
    >
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Service from "../components/Service.vue";

export default {
  name: "My Services",
  components: { Service },
  data() {
    return {
      services: null,
      thumbnailPlaceholder:
        "../assets/images/olivier-depaep-0clJTXtgsIY-unsplash.jpg",
      // user: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters(["getLocationFromStore", "getAllServicesFromStore"]),
  },
  methods: {
    ...mapGetters(["isAuthenticated"]),
    async getMyServices() {
      this.services = this.getAllServicesFromStore().filter((service) => {
        return service.user_id === this.user?.id;
      });
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    init() {
      if (!this.isAuthenticated()) {
        this.toggleLoginModal();
        this.getMyServices();
      } else {
        this.getMyServices();
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.favorite-page .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.favorite-page .flex p {
  font-size: 1rem;
  color: $text;
  margin: 0;
}
.favorite-page .flex a {
  text-align: center;
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}
.favorite-page .btn.btn-yellow {
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  @include for-large {
    width: 200px;
  }
}
</style>
