<template>
  <div class="con notifications-page">
    <Loader v-if="showLoader" />

    <div v-if="notifications" class="notifications">
      <div v-if="notifications.length">
        <div class="flex">
          <p><b>{{ notifications.length }}</b> notifications found</p>
                <a href="" @click.prevent="getNotifications(true)">Load new</a>
        </div>
        <div v-for="(notification,index) in notifications" class="notification" :key="index" @click="showNotification(notification)">
          <img src="../assets/icons/chat_black_24dp.svg" alt="" />
          <div class="info mb-0">
            <div v-if="notification.notification_title" class="name">
              {{notification.notification_title}}
            </div>
            <div>{{reduceString(notification.notification_content,showChars)}}</div>
            <p>by:{{notification.notification_type}} notification</p>
            <p>{{notification.created_at}}</p>
            <p class="viewed" v-if="notification.read"><img src="../assets/icons/eye.svg" alt="" /></p>

          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <p>No notification</p>
      </div>
    </div>
    <notification v-if="showModal" :notification="notification" @hideNotificationModal="showModal=false"></notification>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import Loader from "@/components/Loader";
import Notification from "@/components/Notification";
export default {
  data() {
    return {
      notifications:null,
      // showLoader:false,
      showModal:false,
      notification:null,
      showChars: 15
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
    ...mapGetters(["getNotificationsFromStore"])
  },
  components: { Loader,Notification },
  mounted() {
    this.getNotifications()
  },
  methods: {
    ...mapActions(["getAllUserReports","getUserNotifications"]),
    getNotifications(newNotifications = false){
      // this.showLoader =true;
      this.$store.commit("setLoader",true);
      let storeNotifications = this.getNotificationsFromStore();
      if (!storeNotifications || (storeNotifications && newNotifications)){
        const data= {
          user_id : this.user?.id,
          notification_type: "email"
        }
        this.getUserNotifications(data).then((response)=>{
          if (response.status <= 300){

            this.notifications = response.data.data;
            console.log(this.notifications)
          }
          // this.showLoader = false;
          this.$store.commit("setLoader",false);
        }).catch((error)=>{
          console.log(error)
          // this.showLoader= false;
          this.$store.commit("setLoader",false);
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        })
      }else{
        this.notifications = storeNotifications.data
        // this.showLoader= false;
        this.$store.commit("setLoader",false);
        // console.log(this.notifications)
      }

    },
    showNotification(notification){
      this.notification = notification;
      this.showModal = true;
    },
    reduceString(value, showChars = 10, ellipsesText = "...") {
      let reduced_text = value;
      if (reduced_text.length > showChars) {
        reduced_text = reduced_text.substr(0, showChars) + ellipsesText;
      }
      return reduced_text;
    },
    hideModal(){
      let storeNotifications = this.getNotificationsFromStore();
      this.notifications = storeNotifications.data
    }

  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ---------------------------- notification page --------------------------- */
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @include for-tablet {
    margin-bottom: 2rem;
  }
}
.flex p {
  font-size: 1rem;
  color: $text;
  margin: 0;
}
.flex a {
  text-align: center;
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}
p.viewed{
  width: 21px;
  img{
    width: 100%;
    height: auto;
  }
}

.notifications {
  width: 100%;
}
.notification {
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  margin-bottom: 1rem;
  @include for-tablet {
    margin-bottom: 1.5rem;
  }
}
.notification img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: $border-radius;
  margin-right: 1rem;
}
.notification .info{
  margin-bottom: 0!important;
}
.notification .info .name {
  font-size: 1.1rem;
  color: $text;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.notification .info p {
  margin: 0;
  font-size: 0.9rem;
  color: $light-text;
}
</style>
