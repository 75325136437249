<template>
  <div class="con favorite-page">
    <div class="services">
      <div class="service-card">
        <img
          src="../assets/images/olivier-depaep-0clJTXtgsIY-unsplash.jpg"
          alt=""
        />
        <div class="content">
          <div class="top">
            <div class="rating">
              <img src="../assets/icons/star_yellow_24dp.svg" alt="" />
              4.86 <span> (23 reviews)</span>
            </div>
            <a href="">
              <img
                src="../assets/icons/favorite_border_black_24dp.svg"
                alt=""
              />
            </a>
          </div>
          <div class="name">Service name will go here</div>
          <div class="type">Research Services</div>
          <div class="location">
            <img src="../assets/icons/place_black_24dp.svg" alt="" />
            <span>Yaounde</span>
            <span>Cameroon</span>
          </div>
        </div>
      </div>
      <div class="service-card">
        <img
          src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
          alt=""
        />
        <div class="content">
          <div class="top">
            <div class="rating">
              <img src="../assets/icons/star_yellow_24dp.svg" alt="" />
              4.86 <span> (23 reviews)</span>
            </div>
            <a href="">
              <img
                src="../assets/icons/favorite_border_black_24dp.svg"
                alt=""
              />
            </a>
          </div>
          <div class="name">Service name will go here</div>
          <div class="type">Research Services</div>
          <div class="location">
            <span>Yaounde</span>
            <span>Cameroon</span>
          </div>
        </div>
      </div>
      <div class="service-card">
        <img src="../assets/images/boukaih-bGMyTnSlYvE-unsplash.jpg" alt="" />
        <div class="content">
          <div class="top">
            <div class="rating">
              <img src="../assets/icons/star_yellow_24dp.svg" alt="" />
              4.86 <span> (23 reviews)</span>
            </div>
            <a href="">
              <img
                src="../assets/icons/favorite_border_black_24dp.svg"
                alt=""
              />
            </a>
          </div>
          <div class="name">Service name will go here</div>
          <div class="type">Research Services</div>
          <div class="location">
            <img src="../assets/icons/place_black_24dp.svg" alt="" />
            <span>Yaounde</span>
            <span>Cameroon</span>
          </div>
        </div>
      </div>
      <div class="service-card">
        <img
          src="../assets/images/olivier-depaep-0clJTXtgsIY-unsplash.jpg"
          alt=""
        />
        <div class="content">
          <div class="top">
            <div class="rating">
              <img src="../assets/icons/star_yellow_24dp.svg" alt="" />
              4.86 <span> (23 reviews)</span>
            </div>
            <a href="">
              <img
                src="../assets/icons/favorite_border_black_24dp.svg"
                alt=""
              />
            </a>
          </div>
          <div class="name">Service name will go here</div>
          <div class="type">Research Services</div>
          <div class="location">
            <img src="../assets/icons/place_black_24dp.svg" alt="" />
            <span>Yaounde</span>
            <span>Cameroon</span>
          </div>
        </div>
      </div>
      <div class="service-card">
        <img
          src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
          alt=""
        />
        <div class="content">
          <div class="top">
            <div class="rating">
              <img src="../assets/icons/star_yellow_24dp.svg" alt="" />
              4.86 <span> (23 reviews)</span>
            </div>
            <a href="">
              <img
                src="../assets/icons/favorite_border_black_24dp.svg"
                alt=""
              />
            </a>
          </div>
          <div class="name">Service name will go here</div>
          <div class="type">Research Services</div>
          <div class="location">
            <span>Yaounde</span>
            <span>Cameroon</span>
          </div>
        </div>
      </div>
      <div class="service-card">
        <img src="../assets/images/boukaih-bGMyTnSlYvE-unsplash.jpg" alt="" />
        <div class="content">
          <div class="top">
            <div class="rating">
              <img src="../assets/icons/star_yellow_24dp.svg" alt="" />
              4.86 <span> (23 reviews)</span>
            </div>
            <a href="">
              <img
                src="../assets/icons/favorite_border_black_24dp.svg"
                alt=""
              />
            </a>
          </div>
          <div class="name">Service name will go here</div>
          <div class="type">Research Services</div>
          <div class="location">
            <img src="../assets/icons/place_black_24dp.svg" alt="" />
            <span>Yaounde</span>
            <span>Cameroon</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.favorite-page .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.favorite-page .flex p {
  font-size: 1rem;
  color: $text;
  margin: 0;
}
.favorite-page .flex a {
  text-align: center;
  color: $text;
  font-size: 1rem;
  line-height: 0.9;
  border-bottom: 1px solid $text;
}
</style>
