<template>
  <div ref="paymentModal" class="login-modal">
    <div class="login">
      <div class="con">
        <div class="d-flex align-items-center mb-3"
             :class="{
          'justify-content-end':steps.indexOf(currentStep) === 0 || steps.indexOf(currentStep) === steps.length-1 ,
          'justify-content-between':steps.indexOf(currentStep) < steps.length-1 || steps.indexOf(currentStep) > 0

          }"
        >
          <div @click="previousStep"
               v-if="steps.indexOf(currentStep) < steps.length-1 && steps.indexOf(currentStep) > 0"
               class="cursor-pointer">
            <img src="@/assets/icons/arrow_back_black_24dp.svg" alt=""/>
          </div>
          <div @click="hideModal" class="cursor-pointer">
            <img src="@/assets/icons/icon-close.svg" alt=""/>
          </div>
        </div>

        <div v-if="currentStep==='payment_options'" id="payment-methods">
          <h1>Payment methods</h1>
          <p class="sub-heading">choose a payment method</p>
          <div @click="selectedOption='card'" class="payment-method"
               :class="{'checked':selectedOption ==='card' }"
          >
            <div class="payment-method-left">
              <img src="../assets/icons/logos_visa.svg" alt="">
              <img src="../assets/icons/logos_mastercard.svg" alt="">
              <span>Credit Card</span>
            </div>
            <div class="payment-method-right">
              <img v-if="selectedOption ===  'card'" src="../assets/icons/radio_button_checked.svg" alt="">
              <img v-else src="../assets/icons/radio_button_unchecked.svg" alt="">
            </div>
          </div>
          <div @click="selectedOption='mtn_momo'" class="payment-method"
               :class="{'checked':selectedOption ===  'mtn_momo' }"
          >
            <div class="payment-method-left">
              <img src="../assets/icons/mtn-mm-logo-generic-mtn-mobile-money-logo.svg" alt="">
              <span>Mtn Momo</span>
            </div>
            <div class="payment-method-right">
              <img v-if="selectedOption ===  'mtn_momo'" src="../assets/icons/radio_button_checked.svg" alt="">
              <img v-else src="../assets/icons/radio_button_unchecked.svg" alt="">
            </div>
          </div>
          <div>
            <button @click="onConfirm" class="confirm">Confirm</button>
          </div>
        </div>
        <div v-if="currentStep==='payment_form'" id="payment-form">
          <div v-if="paymentInitiated && !transactionFailed">
            <h1>Payment request initiated</h1>
            <p class="sub-heading mb-3">You will receive a payment request on your phone</p>
            <p class="sub-heading">If you don't dial <strong>*126#</strong> to initiate the payment</p>
          </div>
          <div v-else-if="paymentInitiated && transactionFailed">
            <h1>Transaction failed!</h1>
            <p class="sub-heading">Oops! Your transaction failed.Please try again</p>
            <button @click="onTryAgain" class="confirm">Try again</button>
          </div>
          <div v-else>
            <h1>Payment methods</h1>
            <p class="sub-heading">You have chosen MTN momo, please fill infos below</p>
            <form action="" @submit.prevent="processPayment">
              <!--            <div v-if="selectedOption==='master_card' || selectedOption==='visa_card'">-->
              <!--              <div-->
              <!--                  class="field-con"-->
              <!--                  :class="{ error: formErrors.cardNumber }"-->
              <!--              >-->
              <!--                <div class="field">-->
              <!--                  <input-->
              <!--                      id="card-number"-->
              <!--                      type="text"-->
              <!--                      name="card-number"-->
              <!--                      v-model="form.cardNumber"-->
              <!--                      placeholder="Card Number (4242 4242 4242 4242 4242)"-->
              <!--                  />-->
              <!--                </div>-->
              <!--                <div v-if="formErrors.cardNumber" class="error">-->
              <!--                  {{ formErrors.cardNumber }}-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="d-flex justify-content-between align-items-center">-->
              <!--                <div-->
              <!--                    class="field-con w-70"-->
              <!--                    :class="{ error: formErrors.cardDate }"-->
              <!--                >-->
              <!--                  <div class="field">-->
              <!--                    <input-->
              <!--                        id="card-date"-->
              <!--                        type="text"-->
              <!--                        name="card-date"-->
              <!--                        v-model="form.cardDate"-->
              <!--                        placeholder="Valid Date (MM/AA)"-->
              <!--                    />-->
              <!--                  </div>-->
              <!--                  <div v-if="formErrors.cardDate" class="error">-->
              <!--                    {{ formErrors.cardDate }}-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div-->
              <!--                    class="field-con w-30"-->
              <!--                    :class="{ error: formErrors.cvc }"-->
              <!--                >-->
              <!--                  <div class="field">-->
              <!--                    <input-->
              <!--                        id="cvc"-->
              <!--                        type="text"-->
              <!--                        name="password"-->
              <!--                        placeholder="CVC"-->
              <!--                    />-->
              <!--                  </div>-->
              <!--                  <div v-if="formErrors.cvc" class="error">-->
              <!--                    {{ formErrors.cvc }}-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->

              <!--            </div>-->

              <!--            <div v-else>-->
              <div
                  class="field-con"
                  :class="{ error: formErrors.phoneNumber }"
              >
                <div class="field">
                  <input
                      id="phone-number"
                      type="text"
                      name="password"
                      placeholder="Phone Number (677 77 77 77)"
                      v-model="form.phoneNumber"
                  />
                </div>
                <div v-if="formErrors.phoneNumber" class="error">
                  {{ formErrors.phoneNumber }}
                </div>
              </div>
              <!--            </div>-->

              <div>

                <button type="submit">Pay now</button>
              </div>

            </form>
          </div>

        </div>
        <div v-if="currentStep==='payment_complete'" id="payment-complete">
          <img src="../assets/icons/check.svg" alt=""/>
          <h1>Payment successful</h1>
          <p>You have paid your service </p>
          <div>
            <router-link to="/"> class="confirm">Continue</router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
// import {ReusableFunctions} from "@/helpers/helpers";
import validator from "validator";

export default {
  name: "PaymentModal",
  emits: ['hidePaymentModal',"toggleLoader"],
  props: {
    subscription: {
      type: [],
      required: true,

    },
  },
  // mixins:[ReusableFunctions],
  computed: {
    ...mapState({
      user: (state) => state.auth.user
    }),
  },
  data() {
    return {
      steps: ['payment_options', 'payment_form', 'payment_complete'],
      currentStep: 'payment_options',
      selectedOption: "card",
      form: {
        cardNumber: undefined,
        cvc: undefined,
        cardDate: undefined,
        phoneNumber: undefined

      },
      paymentInitiated:false,
      transactionFailed:false,
      formErrors:{}
    }
  },
  watch: {
    'form.cardNumber':function(currentVal){
      this.handleValidation("cardNumber", currentVal,{required: true},"Card number");
    },
    'form.cvc':function(currentVal){
      this.handleValidation("cvc", currentVal,{required: true,isCvc: true},"CVC");
    },
    'form.cardDate':function(currentVal){
      this.handleValidation("cardDate", currentVal,{required: true},"Card date");
    },
    'form.phoneNumber':function(currentVal){
      this.handleValidation("phoneNumber", currentVal,{required:true,isTel: true},"Phone number");
    },
  },
  refs: ['paymentModal'],
  methods: {
    ...mapActions([
      "makePayment",
        "getPaymentStatus"
    ]),
    previousStep() {
      const currentStepIndex = this.steps.indexOf(this.currentStep) - 1;
      this.currentStep = this.steps[currentStepIndex];
    },
    onTryAgain(){
      this.paymentInitiated=false;
          this.transactionFailed =false;
    },
    processPayment() {
      let data = {
        "plan": this.subscription[0].tag,
        "user_id": this.user.id,
        "description": this.subscription[0].description,
        "annual" : false
      }
      if (this.subscription[0].invoice_interval === 'year'){
        data['annual'] = true;
      }
        this.handleValidation('phoneNumber',this.form.phoneNumber,{required:true,isTel: true},'Phone number');
        data["payment_method"] = 'mtn_momo';
        data["from"] = this.form.phoneNumber;
      if (Object.keys(this.formErrors).length === 0) {
        this.$emit('toggleLoader',true);
        this.makePayment(data).then((response) => {
          this.$emit('toggleLoader', false);
          this.paymentInitiated = true;
          this.transactionStatus(response.data.data.payment.reference,0);
        }).catch((error) => {
          console.log(error);
          this.$emit('toggleLoader', false);
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        })
      }

    },
    transactionStatus(reference,completedSeconds){
      let totalWait = 2*60*1000;
        setTimeout(()=>{
          this.getPaymentStatus(reference).then((response) => {
            completedSeconds= completedSeconds+10000;
            if (completedSeconds < totalWait && response.data.data.status === "PENDING"){
              this.transactionStatus(reference,completedSeconds)
            }else if(completedSeconds >= totalWait && response.data.data.status !== "SUCCESSFUL"){
              this.transactionFailed = true;
            }
            else if(response.data.data.status === "SUCCESSFUL"){
              this.currentStep = "payment_complete"
            }else if(response.data.data.status === "FAILED"){
              this.transactionFailed = true;
            }
          }).catch((error) => {
            console.log(error);
            this.transactionFailed = true;
            this.$toast.error(error.response.data.message, {
              position: "top",
            });
          })
        },10000);
    },
    hideModal() {
      this.$emit('hidePaymentModal')
    },
    onConfirm(){
      if (this.selectedOption === 'card'){
        this.$emit('toggleLoader',true);
        let data = {
          "plan": this.subscription[0].tag,
          "user_id": this.user.id,
          "description": this.subscription[0].description,
          "payment_method":"card",
          "annual" : false
        }
        if (this.subscription[0].invoice_interval === 'year'){
          data['annual'] = true;
        }
        this.makePayment(data).then((response) => {
          if (response.status === 200){
            const redirectURL = response.data.data.payment.payment_url;
            window.location.href = redirectURL;
          }
        }).catch((error) => {
          console.log(error);
          this.$emit('toggleLoader', false);
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        })
      }else{
        this.currentStep='payment_form'
      }

    },
    handleValidation(fieldKey, fieldValue, validation = {
      required: false,
      isEmail: false,
      isCvc: false,
      max:0,
      min:0,
      isTel: false,
      isNumeric:false,
    } ,fieldName) {
      //  todo

      if (validation.required && (!fieldValue || fieldValue?.replace(/^\s+|\s+$/gm, "") === "" )){
        this.formErrors[fieldKey] = `${fieldName} is required`;
      }else if(validation.isEmail && !validator.isEmail(this.email)){
        this.formErrors[fieldKey] = `${fieldName} should be a valid email`;
      }else if(validation.isCvc && (isNaN(parseInt(fieldValue)) )){
        this.formErrors[fieldKey] = `${fieldName} is invalid`;
      }
      else if(validation.isCvc && fieldValue.toString().replace(/^\s+|\s+$/gm, '').length < 3){
        this.formErrors[fieldKey] = `${fieldName} cannot be less than 3 digits`;
      }
      else if(validation.isTel && (isNaN(parseInt(fieldValue)) )){
        this.formErrors[fieldKey] = `${fieldName} you provided is invalid`;
      }
      else if(validation.isTel && fieldValue.toString().replace(/^\s+|\s+$/gm, '').length < 8){
        this.formErrors[fieldKey] = `${fieldName} you provided is short .${fieldName} cannot be less than 8 digits`;
      }
      else{
        delete this.formErrors[fieldKey];
      }

    },
    onClickModal(event) {
      const paymentModal = this.$refs.paymentModal;
      const target = event.target;
      if (paymentModal && paymentModal === target) {
        this.hideModal()
      }
    }

  },
  // methods:{
  //     onClickModal(event) {
  //       const paymentModal = this.$refs.paymentModal;
  //       const target = event.target;
  //       if (paymentModal && paymentModal === target) {
  //         this.hideModal()
  //       }
  //     }
  // },
  created() {
    document.addEventListener("click", this.onClickModal);
  },
};
</script>

<style lang="scss" scoped>
.login-modal {
  .login {
    .con {
      #payment-methods {
        .sub-heading {
          margin-bottom: 1.5rem;
        }

        .payment-method {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 20px;
          border-radius: 8px;
          border: 0.5px solid #010101;
          margin-bottom: 1.5rem;

          &.checked {
            background-color: #FFFBE0;
            border-color: #FDDB00;
          }

          .payment-method-left {
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              margin-left: 4px;
            }
          }
        }

      }

      #payment-complete {
        img {
          width: 236px;
          height: 208px;
        }
      }
    }
  }

}

button {
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fddb00;
  color: #010101;
  font-weight: 600;
  font-size: 1rem;
  margin: 2rem 0;
  border-radius: 10px;

  &:hover {
    background-color: #010101;
    color: #fff;
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.w-70 {
  width: 70%;
  margin-right: 1.5rem;

}

.w-30 {
  width: 30%;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
