<template>
  <div class="review-page">
    <div class="con">
      <div class="info">
        <Loader v-if="showLoader" />
        <div class="top">
          <img :src="category?.thumbnail" alt="" />
          <span>{{ category?.name }}</span>
        </div>
        <ul v-if="subCategories && !showLoader">
          <li v-for="(subCategory, index) in subCategories" :key="index">
            <router-link
              tag="a"
              :to="{
                path: `/categories/${category?.slug}/${subCategory?.id}`,
              }"
            >
              <div class="left">
                <div class="redirect-con">
                  <div class="redirect">
                    <img
                      :src="
                        subCategory?.thumbnail ??
                        '../assets/icons/engineering_black_24dp.svg'
                      "
                      alt=""
                    />
                    <span>{{ subCategory?.name }}</span>
                  </div>
                  <img src="../assets/icons/arrow-point-to-right.svg" alt="" />
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div v-if="!subCategories && !showLoader">
          <h2>No Sub-categories found</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Loader from "../components/Loader.vue";
export default {
  setup() {},
  data() {
    return {
      category: null,
      subCategories: null,
      // showLoader: false,
    };
  },
  components: { Loader },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
    ...mapGetters(["getCategoryFromStoreBySlug"]),
  },
  methods: {
    ...mapActions(["getAllSubCategories"]),
    async getSubCategories() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      const categorySlug = this.$route.params.slug;
      this.category = this.getCategoryFromStoreBySlug(categorySlug);
      this.getAllSubCategories(this.category.id)
        .then((response) => {
          this.subCategories = response.data.data.data;
          console.log(this.subCategories);
          this.$store.commit("setLoader",false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("setLoader",false);
        });
      // this.showLoader = false;
    },
  },
  mounted() {
    this.getSubCategories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
main {
  margin-top: 70px;
  @include for-tablet {
    margin-top: 90px;
  }
}
@-webkit-keyframes bg-pan-left {
  0% {
    receiverground-position: 100% 50%;
  }
  100% {
    receiverground-position: 0% 50%;
  }
}
@keyframes bg-pan-left {
  0% {
    receiverground-position: 100% 50%;
  }
  100% {
    receiverground-position: 0% 50%;
  }
}
.info {
  margin-bottom: 3rem;
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $border-color;
    padding-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
    img {
      height: 35px;
      margin-right: 1rem;
    }
  }
  ul {
    &.categories-loader {
      width: 100%;
      li {
        height: 55px;
        display: flex;
        justify-content: flex-start;
        padding: 0.5rem 0;
        div {
          height: 100%;
          border-radius: 5px;
          receiverground: linear-gradient(57deg, #eee, #ddd, #eee);
          receiverground-size: 400%;
          width: 100px;
          -webkit-animation: bg-pan-left 1s linear infinite forwards;
          animation: bg-pan-left 1s linear infinite forwards;
          &:first-child {
            width: 40px;
            margin-right: 0.5rem;
          }
          &:last-child {
            width: 100%;
          }
        }
      }
    }
    li {
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      padding: 1rem 0;
      &:last-child {
        margin-bottom: 1rem;
        border-bottom: none;
      }
      input,
      textarea {
        width: 100%;
        receiverground-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        font-size: 1rem;
        color: $text;
        margin-top: 0.3rem;
      }
      input {
        padding: 0 1rem;
        height: 45px;
      }
      textarea {
        padding: 1rem;
      }
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
      .redirect-con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        & > img {
          width: 15px;
        }
      }
      .redirect {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
      }

      .redirect img {
        width: 25px;
        margin-right: 1rem;
      }

      .redirect span {
        font-size: 1rem;
        text-transform: capitalize;
        line-height: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
