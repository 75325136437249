<template>
  <section id="loader-section">
    <div class="loader">
      <div class="spin"></div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  components: {},
};
</script>

<style>
.spin {
  background: #0000;
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
#loader-section{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 9999999999999;
  background: #ffffff;
  margin: 0;

}
.spin::before {
  animation: 1.5s linear infinite spinner;

  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #fddb00;
  border-radius: 50%;
  content: "";
  background: #0000;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>
