<template>
  <header>
    <div class="page-top">
      <div class="con">
        <router-link to="/">
          <img src="../assets/logo-icon.png" alt="" class="logo" />
        </router-link>
        <h3>
          <img
            @click="$router.go(-1)"
            src="../assets/icons/arrow_back_black_24dp.svg"
            alt=""
            class="back"
          />
          <!-- <span>Select Plan</span> -->
        </h3>
      </div>
    </div>
  </header>
</template>
