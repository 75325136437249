<template>
  <div class="location">
    <img src="../assets/icons/place_black_24dp.svg" alt="" />
    <span>{{ location?.name }}</span>
    <span>Cameroon</span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    location_id: {
      // type: Number,
      required: true,
    },
  },
  data() {
    return {
      location: null,
    };
  },
  computed: {
    ...mapGetters(["getLocationFromStore"]),
  },
  methods: {
    async getServiceLocation() {
      this.location = this.getLocationFromStore(this.location_id);
    },
  },
  mounted() {
    this.getServiceLocation();
  },
};
</script>
<style scoped>
.location span {
  font-size: 0.8rem;
  text-transform: capitalize;
  display: inline-block;
  padding: 0 0.3rem;
  line-height: 0.9;
}
.location span:last-child {
  border-left: 1px solid #fddb00;
}
</style>
