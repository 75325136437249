import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import "@/assets/styles/global.scss";
import "vue-loaders/dist/vue-loaders.css";
import VueLoaders from "vue-loaders";
import vue3StarRatings from "vue3-star-ratings";
import "animate.css";

createApp(App)
    .use(store)
    .use(router)
    .use(Toaster)
    .use(VueLoaders)
    .component("vue3-star-ratings", vue3StarRatings)
    .mount("#app");
