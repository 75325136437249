<template>
  <div ref="paymentModal" class="login-modal">
    <div class="login">
      <div class="con">
        <div class="d-flex align-items-center mb-3 justify-content-end"
        >
          <div @click="hideModal" class="cursor-pointer">
            <img src="@/assets/icons/icon-close.svg" alt=""/>
          </div>
        </div>

        <div>
          <div  class="mb-3">
            <h1>Limit</h1>
            <p>You can only view {{viewLimit}} services per day.</p>
            <p>Upgrade to see more</p>
          </div>

          <div class="d-flex align-items-center mb-3 justify-content-center ">
            <router-link to="/services" class="btn btn-light mr-3">Services</router-link>
            <router-link to="/pricing" class="btn btn-black">Pricing</router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceModal",
  emits: ['hideServiceModal'],
  props: {
    viewLimit: {
      // type: Number,
      required: true,
    },
  },

  methods: {
    hideModal() {
      this.$emit('hideServiceModal')
    },
    onClickModal(event) {
      const paymentModal = this.$refs.paymentModal;
      const target = event.target;
      if (paymentModal && paymentModal === target) {
        this.hideModal()
      }
    }
  },
  created() {
    document.addEventListener("click", this.onClickModal);
  },
};
</script>

<style lang="scss" scoped>
.login-modal {
  .login {
    .con {
      #payment-methods {
        .sub-heading {
          margin-bottom: 1.5rem;
        }

        .payment-method {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 20px;
          border-radius: 8px;
          border: 0.5px solid #010101;
          margin-bottom: 1.5rem;

          &.checked {
            background-color: #FFFBE0;
            border-color: #FDDB00;
          }

          .payment-method-left {
            display: flex;
            align-items: center;

            span {
              display: inline-block;
              margin-left: 4px;
            }
          }
        }

      }

      #payment-complete {
        img {
          width: 236px;
          height: 208px;
        }
      }
    }
  }

}

button {
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fddb00;
  color: #010101;
  font-weight: 600;
  font-size: 1rem;
  margin: 2rem 0;
  border-radius: 10px;

  &:hover {
    background-color: #010101;
    color: #fff;
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.mb-3 {
  margin-bottom: 1.5rem;
}
.mr-3 {
  margin-right: 1.5rem;
}

.w-70 {
  width: 70%;
  margin-right: 1.5rem;

}

.w-30 {
  width: 30%;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
