import APIService from "../../services/api.service";

const state = {
  services: null,
};

const actions = {
  async getAllServices({ commit }) {
    return new Promise((resolve, reject) => {
      APIService.getAllServices()
        .then((response) => {
          commit("setServices", response.data.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async createServiceAction({ commit ,state}, data) {
    return new Promise((resolve, reject) => {
      APIService.createService(data)
        .then((response) => {
          let new_services = [...[response.data.data],...state.services]
          commit("setServices", new_services);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async createRequestAction({ commit }, data) {
    return new Promise((resolve, reject) => {
      APIService.createServiceRequest(data)
        .then((response) => {
          commit("setRequest", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getAllServiceLikes({ commit }, { service_id, data }) {
    return new Promise((resolve, reject) => {
      APIService.getServiceLikes({ service_id, data })
        .then((response) => {
          commit("setLikes", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getSubCategoryServicesAction({ commit }, sub_category_id) {
    console.log(sub_category_id);
    return new Promise((resolve, reject) => {
      APIService.getSubCategoryServices(sub_category_id)
        .then((response) => {
          commit("setSubList", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {
  getAllServicesFromStore: (state) => () => {
    return state.services;
  },
  // ...
  getServiceFromStore: (state) => (slug) => {
    return state.services.find((service) => service.slug === slug);
  },
};

const mutations = {
  setServices(state, payload) {
    state.services = payload;
  },
  setService(state, payload) {
    state.service = payload;
  },
  setRequest(state, payload) {
    state.request = payload;
  },
  setLikes(state, payload) {
    state.likes = payload;
  },
  setSubList(state, payload) {
    state.subList = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
