<template>
  <div class="home">
    <Loader v-if="showLoader" />
    <div class="con">
      <User v-if="user"></User>
      <div class="header">
        <h1 class="home-header">What are you looking for today?</h1>
        <a href="javascript:void(0)" class="link" @click="resetPageReload">Refresh</a>
      </div>

      <SearchFilter :page="'home'" @searchData="getSearchResult" />
    </div>
    <SearchResult v-if="searchResult?.match_total > 0" :result="[searchResult]" />

    <!-- home categories section  -->
    <section v-if="!showLoader">
      <div class="con">
        <div class="section-top">
          <h3>Categories</h3>
          <router-link :to="{ path: '/categories' }" class="link"
            >See All</router-link
          >
        </div>
        <div v-if="categories?.length > 0" class="categories">
          <router-link
            v-for="(category, index) in categories"
            :key="index"
            tag="a"
            :to="{
              path: `/categories/${category.slug}`,
              props: { category: category },
            }"
            class="category"
          >
            <img
              :src="category.thumbnail ?? '@/assets/icons/Professional.svg'"
              alt=""
              class="icon"
            />
            <span>{{ category.name }}</span>
          </router-link>
        </div>
        <div v-if="categories?.length === 0" class="categories">
          <p>No featured category found</p>
        </div>
        <!-- </vue-loaders> -->
      </div>
    </section>

    <!-- home featured services section -->
    <section v-if="!showLoader">
      <div class="con">
        <div class="section-top">
          <h3>Featured Services</h3>
          <router-link tag="a" :to="{ path: '/services' }" class="name"
            >See All
          </router-link>
        </div>
        <div class="services">
          <Service :services="services" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapState, mapActions, mapGetters} from "vuex";
import Service from "../components/Service.vue";
import User from "../components/User.vue";
import SearchFilter from "../components/SearchFilter.vue";
import SearchResult from "../components/SearchResult.vue";
import Loader from "../components/Loader.vue";
// import router from "../router";
export default {
  name: "Home",
  components: { Service, User, SearchFilter, SearchResult, Loader },
  data() {
    return {
      categories: null,
      services: [],
      // showLoader: false,
      search: "",
      searchResult: null,
      passwordResetToken: null,
      limit: 30//limit in mins
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
    ...mapGetters(["getAllServicesFromStore","getFeaturedCategoriesFromStore"]),
  },
  methods: {
    ...mapActions([
      "getFeaturedCategories",
      "getAllServices",
      "getAllLocations",
      "getAllCategories",
      "searchAction",
    ]),
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    toggleCheckEmailModal() {
      this.$store.commit("toggleCheckEmailModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    getSearchResult(payloads) {
      this.searchResult = payloads;
    },
    setPageReload(){
      let now = new Date();
      let pageReload = now.getTime() + (this.limit * 60 * 1000);
      localStorage.setItem('homePageReload',JSON.stringify(pageReload));
    },
    ifReload(homepageReload){
      let reload = false;
      let now = new Date()
      now = now.getTime();
      if (homepageReload && now > homepageReload){
        reload = true
      }
      return reload;
    },
    resetPageReload(){
      localStorage.removeItem("homePageReload");
      window.location.reload();
    }
  },
  created() {
    let homepageReload = localStorage.getItem('homePageReload') ? JSON.parse(localStorage.getItem('homePageReload')): '';
    if (!homepageReload || this.ifReload(homepageReload) ){
      this.getAllLocations();
      this.getAllCategories(100);
    }

  },

  mounted() {
    // this.showLoader = true;
    this.$store.commit("setLoader",true);
    let homepageReload = localStorage.getItem('homePageReload') ? JSON.parse(localStorage.getItem('homePageReload')): '';
    if (!homepageReload || this.ifReload(homepageReload) ) {
      this.getFeaturedCategories()
          .then((response) => {
            const categories = response.data.data.data;
            this.categories = categories;
          })
          .catch((error) => {
            console.log(error.response);
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          });

      this.getAllServices()
          .then((response) => {
            this.services = response.data.data.data;
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          })
          .catch((error) => {
            console.log(error);
            // this.showLoader = false;
            this.$store.commit("setLoader",false);
          });
          this.setPageReload();
    }else{
      try{
        this.categories = this.getFeaturedCategoriesFromStore();
        this.services = this.getAllServicesFromStore();
        // this.showLoader = false;
        this.$store.commit("setLoader",false);
      }catch (error) {
        this.$toast.error(error, {
          position: "top",
        });
        this.$store.commit("setLoader",false);
      }

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.header{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .link{
    color: #010101;
    font-size: 1rem;
    display: inline;
    border-bottom: 1px solid #010101;
    line-height: 0.9;
    margin: 1rem 0 1rem 0;
  }
  .home-header {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 1rem 0 1rem 0;

  }
}

/* for extra small screens and lower  */
@include for-extra-small {
  .home-header {
    font-size: 1.5rem;
  }
}

.categories {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
/* for extra small screens and lower  */
@include for-extra-small {
  .categories {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }
}
@include for-mobile {
  .categories {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.2rem;
    display: grid;
  }
}
@include for-large {
  .categories {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.5rem;
    display: grid;
  }
}
.categories .category {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.3em 1rem;
  border-radius: $border-radius;
  background-color: $light-gray;
  height: 70px;
  font-size: 0.9rem;
  color: $text;
  &:hover {
    background-color: darken($light-gray, 5%);
  }
}
/* for extra small screens and lower  */
@include for-extra-small {
  .categories .category {
    height: 40px;
    padding: 0.5rem 1rem;
    margin: 0.5em auto;
  }
}
@include for-mobile {
  .categories .category {
    margin: 0.5em auto;
  }
}
.categories .category img {
  width: 30px;
  min-width: 30px;
  opacity: 0.8;
  margin-right: 0.5rem;
}
/* for extra small screens and lower  */
@include for-extra-small {
  .categories .category img {
    height: 20px;
  }
}
.categories .category span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.review .vue3-star-ratings__wrapper {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
