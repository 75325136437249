<template>
  <div class="con messages-page">
    <Loader v-if="showLoader" />
    <div>
    <div class="home-menu search-only">
      <form class="search">
        <img src="../assets/icons/search_black_24dp.svg" alt=""/>
        <input type="text" name="" placeholder="Search inbox" id="" v-model="searchText" />
      </form>
    </div>
    <div class="messages" >
      <div v-if="chats?.length"><div  v-for="(chat,index) in chats" @click="openChat(chat)" class="message"  :key="index" >
        <img v-if="getChat(chat,'profile_url')" :src="getChat(chat,'profile_url')" alt="" />
        <img v-else src="../assets/servicehub-avatar.png" alt="" />
        <div class="info">
          <div class="name">
            <span>{{getChat(chat,'name')}}</span> <span v-if="chat?.messages.length" class="time">{{ chatMessageDate(chat?.messages[0].created_at) }}</span>
          </div>
          <p v-if="chat?.messages.length">{{chat?.messages[0].content}}</p>
        </div>
      </div></div>
      <div v-else class="text-center">
        <p>No chat</p>
      </div>
    </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import ApiService from "@/services/api.service";
import moment from "moment";
import Loader from "@/components/Loader";
import router from "@/router";

export default {

  data() {
    return {
      chats:undefined,
      // showLoader:false,
      searchText:undefined,
      userChats:undefined,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  watch: {
    searchText: function (current) {
      if (current && this.chats.length) {
        this.chats = this.chats.filter( (chat)=>chat.participants?.sender.name.includes(current) )
      } else {
        this.chats = this.userChats;
      }
    },
  },
  mounted() {
    this.getChats()
  },
  components: {Loader },
  methods: {
    getChats() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      ApiService.getUserChats(this.user.id).then((response) => {
        if (response.status === 200) {
          this.chats = response.data.data;
          this.userChats = response.data.data;
        }
        // this.showLoader = false;
        this.$store.commit("setLoader",false);
      }).catch((error) => {
        // this.showLoader = false;
        this.$store.commit("setLoader",false);
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      })
    },
    chatMessageDate(last_message_date){
      let messageDate = moment(new Date(last_message_date)).format("MM-DD-YYYY");
      let today = moment(new Date()).format("MM-DD-YYYY");
      if (messageDate === today){
        return moment(new Date(last_message_date)).format("h:mma");
      }else{
        return moment(new Date(last_message_date)).format("dddd MMMM DD,YYYY");
      }
    },
    openChat(room){
      let receiver = room.participants.sender.id === this.user.id ? room.participants.receiver : room.participants.sender;
      let chat ={
        receiver,
        room_id:room.id
      };
      localStorage.setItem('chat', JSON.stringify(chat));
      router.push({
        path: `/chats/${receiver.id}`,
      });
    },
    getChat(room,atr){
      let receiver = room.participants.sender.id === this.user.id ? room.participants.receiver : room.participants.sender;
      if (atr === 'name'){
        return receiver.name
      }else{
        return receiver.profile_url;
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ---------------------------- message page --------------------------- */
.home-menu {
  margin-bottom: 1rem;
  @include for-large {
    width: 600px;
    margin: 0 auto;
  }
}

.messages {
  width: 100%;
  @include for-large {
    width: 600px;
    margin: 0 auto;
    margin-top: 2rem;
  }
}

.message {
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  //align-items: center;
  margin-bottom: 2rem;
  @include for-tablet {
    margin-bottom: 1.5rem;
  }
}

.message img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: $border-radius;
  margin-right: 1rem;
}

.message .info {
  width: 100%;
}

.message .info .name {
  width: 100%;
  font-size: 1.1rem;
  color: $text;
  font-weight: 500;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .time {
    font-size: 0.8rem;
    color: $light-text;
    display: inline-block;
  }
}

.message .info p {
  width: 85%;
  margin: 0;
  font-size: 1rem;
  line-height: 1;
  color: $light-text;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}
</style>
