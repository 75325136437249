<template>
  <div ref="registerModal" class="register-modal">
    <div class="register">
      <div class="con">
        <div class="close" @click="toggleRegisterModal">
          <img src="@/assets/icons/icon-close.svg" alt=""/>
        </div>
        <div class="request" v-if="showService">
          <div class="header">
            <h1>Sign Up</h1>
            <p>
              Create an account and offer your service you need with one click.
            </p>
          </div>
          <form @submit.prevent="submit()">
            <div
                class="field-con"
                :class="{ error: errorState && errorState.name }"
            >
              <div class="field">
                <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name and Surname"
                    v-model="name"
                />
              </div>
              <div v-if="errorState && errorState.name" class="error">
                {{ errorState.name }}
              </div>
            </div>

            <div class="field-con"
                 :class="{ error: errorState && errorState.email }"
            >
              <div class="field">
                <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    v-model="email"
                />
              </div>
              <div v-if="errorState && errorState.email" class="error">
                {{ errorState.email }}
              </div>
            </div>

            <div
                class="field-con"
                :class="{ error: errorState && errorState.phone }"
            >
              <div class="field">

                <phone-number v-model="phone" @error="phoneNumberError"></phone-number>

              </div>
              <div v-if="errorState && errorState.phone" class="error">
                {{ errorState.phone }}
              </div>
            </div>

            <div
                class="field-con"
                :class="{ error: errorState && errorState.address }"
            >
              <div class="field">
                <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Address"
                    v-model="address"

                />
              </div>
              <div v-if="errorState && errorState.address" class="error">
                {{ errorState.address }}
              </div>
            </div>

            <div
                class="field-con"
                :class="{ error: errorState && errorState.password }"
            >
              <div class="field password">
                <input
                    type="password"
                    name="password"
                    id="service-password"
                    placeholder="Password"
                    v-model="password"
                />
                <div class="toggle" @click="toggleServicePassword">
                  <img src="@/assets/icons/eye.svg" alt=""/>
                </div>
              </div>
              <div v-if="errorState && errorState.password" class="error">
                {{ errorState.password }}
              </div>
            </div>

            <div
                class="field-con"
                :class="{ error: errorState && errorState.password_confirmation }"
            >
              <div class="field password">
                <input
                    type="password"
                    name="password_confirmation"
                    id="service-password-confirm"
                    placeholder="Password Confirm"
                    v-model="password_confirmation"
                />

                <div class="toggle" @click="toggleServicePasswordConfirm">
                  <img src="@/assets/icons/eye.svg" alt=""/>
                </div>
              </div>
              <div
                  v-if="errorState && errorState.password_confirmation"
                  class="error"
              >
                {{ errorState.password_confirmation }}
              </div>
            </div>
            <div
                class="field-con"
                :class="{ error: errorState && errorState.account_type }"
            >
              <p class="account-type-label">
                <span>Account Type:</span>
              </p>
              <div class="check-flex">
                <div class="sub">
                  <input
                      v-model="account_type"
                      type="radio"
                      name="account_type"
                      id="individual"
                      value="individual"
                  />
                  <label for="individual"
                  >
                    <div class="check"></div>
                    <span>Individual</span></label
                  >
                </div>
                <div class="sub">
                  <input
                      v-model="account_type"
                      type="radio"
                      name="account_type"
                      id="company"
                      value="company"
                  />
                  <label for="company"
                  >
                    <div class="check"></div>
                    <span>Company</span></label
                  >
                </div>
              </div>
              <div
                  v-if="errorState && errorState.account_type"
                  class="error"
              >
                {{ errorState.account_type }}
              </div>
            </div>
            <button type="submit">Sign Up</button>
            <p class="text-center">
              Already have an account?
              <a @click="toggleLoginModal">Sign In</a>
            </p>
            <div class="or">
              <p>or sign up with</p>
              <div class="flex">
                <google-login @get-google-token="socialLogin" @social-login="userSocialLogin= 'google'"
                              class="item"></google-login>
                <Facebook class="item" @get-facebook-token="socialLogin"
                          @social-login="userSocialLogin= 'facebook'"></Facebook>
              </div>
            </div>
            <p class="text-center">
              By signing up you agree to our
              <a href="">Terms &amp; Conditions.</a>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import validator from "validator";
import {mapState, mapActions} from "vuex";
import Facebook from "@/components/Facebook";
import GoogleLogin from "@/components/GoogleLogin";
import jwt_decode from "jwt-decode";
// import {setUpPhone} from "@/store/helpers";
import PhoneNumber from "@/components/PhoneNumber";

export default {
  data() {
    return {
      showRequest: false,
      showService: true,
      name: null,
      phone: null,
      address: null,
      email: null,
      account_type: null,
      password: null,
      password_confirmation: null,
      errorState: null,
      userSocialLogin: undefined,
      phoneSetUp: undefined,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      token: (state) => state.auth.token,
    }),
  },
  components: {
    Facebook,
    GoogleLogin, PhoneNumber
  },

  methods: {
    ...mapActions(["register", "socialSignin"]),

    //normal sign up
    submit() {
      if (this.handleValidation()) {
        let data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          account_type: this.account_type,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };
        this.register(data)
            .then(() => {
              this.$toast.success(
                  `Registration successful, please log in with your credentials`,
                  {
                    position: "top",
                  }
              );
              this.toggleLoginModal();
            })
            .catch((error) => {
              console.log(error.response);
              let errorMessage = "Something unexpected happened";
              if (error.response.data.errors.email) {
                errorMessage = error.response.data.errors.email;
              }
              this.$toast.error(errorMessage, {
                position: "top",
              });
            });
      }
    },
    parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    socialLogin(response) {

        let data = {
          provider: this.userSocialLogin
        };
        if (this.userSocialLogin === 'facebook') {
          data = {
            ...data,
            name: response.name,
            social_id: response.id,
            email: response.email,
            profile_url: response.picture,
            email_verified: true
          }
        } else {
          const userCredentials = jwt_decode(response);
          data = {
            ...data,
            name: userCredentials.name,
            email: userCredentials.email,
            social_id: userCredentials.nbf,
            profile_url: userCredentials.picture,
            email_verified: true
          }
        }
        this.socialSignin(data).then(() => {
          this.$toast.success(
              `Registration successful, please log in with your credentials`,
              {
                position: "top",
              }
          );
          this.toggleRegisterModal()
        }).catch((error)=>{
          console.log(error)
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
        });
    },

    // display register modal
    toggleRegisterModal() {
      this.$store.commit("toggleRegisterModal");
    },

    // display login modal
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },

    // display requestPassword modal
    toggleRequestPassword() {
      let password = document.getElementById("request-password");
      console.log(password);
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },

    // display service password modal
    toggleServicePassword() {
      let password = document.getElementById("service-password");
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },

    toggleServicePasswordConfirm() {
      let password = document.getElementById("service-password-confirm");
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },
    updatePhoneNumber() {
      if (this.phoneSetUp.isValidNumber()) {
        this.phone = this.phoneSetUp.getNumber();
      }
    },

    showServiceForm() {
      this.showRequest = false;
      this.showService = true;
    },
    showRequestForm() {
      this.showRequest = true;
      this.showService = false;
    },

    handleValidation() {
      let errors = {};
      this.errorState = null;
      let formIsValid = true;
      const regEX = /^\s+|\s+$/gm;

      // validate name
      if (!this.name || this.name?.replace(regEX, "") === "") {
        formIsValid = false;
        errors["name"] = "name is required";
      }
      //validate address
      if (!this.address || this.address?.replace(regEX, "") === "") {
        formIsValid = false;
        errors["address"] = "Address is required";
      }
      // validate email
      if ((!this.email && !this.phone) || (this.email?.replace(regEX, "") === "" && this.phone?.replace(regEX, "") === "")) {
        formIsValid = false;
        errors["phone"] = "Phone number or email is required";
      } else if (this.email && !validator.isEmail(this.email)) {
        formIsValid = false;
        errors["email"] = "email should be a valid email";
      }

      // validate account type
      if (!this.account_type) {
        formIsValid = false;
        errors["account_type"] = "Account type is required";
      }
      // validate password
      if (!this.password || this.password?.replace(regEX, "") === "") {
        formIsValid = false;
        errors["password"] = "password is required";
      } else if (this.password?.toString().replace(regEX, "").length < 8) {
        formIsValid = false;
        errors["password"] = "password must be at least 8 characters";
      }

      // validate password confirmation
      if (!this.password_confirmation || this.password_confirmation.toString().replace(regEX, "") === "") {
        formIsValid = false;
        errors["password_confirmation"] = "password confirm is required";
      } else if (
          this.password_confirmation &&
          this.password !== this.password_confirmation
      ) {
        formIsValid = false;
        errors["password_confirmation"] = "both passwords do not match";
      }

      this.errorState = errors;
      return formIsValid;
    },
    phoneNumberError(err) {
      if (!err && this.errorState){
        this.errorState["phone"] = "Phone number is incorrect";
      }
      else if (!err && !this.errorState){
        this.errorState = {phone: "Phone number is incorrect"};
      }
      else if (err && (this.errorState && this.errorState["phone"] ) ){
        delete this.errorState["phone"]
      }
    },

    hideModal(event) {
      const registerModal = this.$refs.registerModal;
      const target = event.target;
      if (registerModal && registerModal === target) {
        this.toggleRegisterModal()
      }
    },
    closeModal() {
      this.$store.commit("toggleRegisterModal", true);
    }
  },
  created() {
    document.addEventListener("click", this.hideModal);
    window.addEventListener('unload', this.closeModal);
  },

};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
/* ------------------------------- register modal ------------------------------ */
.register-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.377);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.register-modal .register {
  width: fit-content;
  max-width: 70%;
  padding: 1em 0;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 20px !important;
  padding: 2rem;
  max-height: 95% !important;
  overflow: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include for-tablet {
    border-radius: 20px 20px 0 0;
    overflow-y: auto;
    max-height: 95vh;
    max-width: 90%;
  }
  @include for-mobile {
    width: 100%;
  }

  .header {
    text-align: center;
  }

  .con {
    width: 100%;
    @include for-mobile {
      width: 90%;
    }
  }
}

.register-modal .register .nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .nav-link {
    background-color: $light-gray;
    color: $text;
    width: 100%;
    font-weight: 500;
    // width: 49%;
    height: 45px;
    border-radius: $border-radius;
    cursor: pointer;

    &.active {
      background-color: $text;
      color: $white;
    }
  }
}

.register-modal .register p.text-center:last-child {
  width: 70%;
  margin: 0 auto;

  a {
    cursor: pointer;
  }
}

.register-modal .register p.text-center {
  a {
    cursor: pointer;
  }
}

.register-modal .register p {
  color: $light-text;

  &.account-type-label {
    color: $text;
    text-align: left;
    padding-bottom: 2px;
  }
}

.register-modal .register p a {
  color: $text;
  text-decoration: underline;
}

.register-modal .register .close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  cursor: pointer;
}

.register-modal .register .close img {
  width: 15px;
}

.register-modal .register h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: $text;
}

.register-modal .register form {
  margin-top: 2rem;
}

.register-modal .register form .check-flex {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.register-modal .register form .check-flex .sub {
  width: fit-content;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.register-modal .register form .check-flex .sub input {
  display: none;
}

.register-modal .register form .check-flex .sub label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.register-modal .register form .check-flex .sub label .check {
  width: 20px;
  height: 20px;
  border: 1px solid $text;
  margin-right: 0.5rem;
  position: relative;
}

.register-modal .register form .check-flex .sub label .check::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70%;
  height: 70%;
  background-color: $text;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition-duration: 0.3s;
}

.register-modal
.register
form
.check-flex
.sub
input:checked
+ label
.check::after {
  opacity: 1;
  transition-duration: 0.3s;
}

.register-modal .register form button[type="submit"] {
  background-color: $yellow;
  color: $text;
  font-size: 1rem;
  border-radius: $border-radius;
  height: 50px;
  width: 100%;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;
}

.register-modal .register form button[type="submit"]:hover {
  background-color: $text;
  color: $white;
}

.register-modal .register form .text-center {
  margin-bottom: 1rem;
}

.register-modal .register form .or {
  margin-bottom: 1rem;
}

.register-modal .register form .or p {
  color: $text;
}

.register-modal .register form .or .flex {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.register-modal .register form .or .flex .item {
  width: 49%;
  //height: 50px;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //padding: 0 1rem;
  //border-radius: $border-radius;
}

.register-modal .register form .or .flex .btn img {
  height: 30px;
  margin-right: 1rem;
}

.register-modal .register form .or .flex .btn.btn-google {
  border: 1px solid $text;
  color: $text;
}

.register-modal .register form .or .flex .btn.btn-google:hover {
  background-color: $light-gray;
}

.register-modal .register form .or .flex .btn.btn-facebook {
  border: 1px solid #3b5999;
  background-color: #3b5999;
  color: $white;
}

.register-modal .register form .or .flex .btn.btn-facebook:hover {
  background-color: #243b6e;
}

/* for extra small screens and lower  */
@media only screen and (max-width: 360px) {
  .register-modal .register {
    border-radius: 13px 13px 0 0;
    height: auto;
    max-height: 100vh;
    font-size: 0.9rem;
  }

  .register-modal .register .close img {
    width: 15px;
  }

  .register-modal .register h1 {
    font-size: 1.5rem;
  }

  .register-modal .register form {
    margin-top: 1rem;
  }

  .register-modal .register form button[type="submit"] {
    font-size: 0.9rem;
    height: 40px;
  }

  .register-modal .register form .or .flex .btn {
    height: 40px;
  }

  .register-modal .register form .or .flex .btn img {
    height: 20px;
  }

  .register-modal .register p.text-center:last-child {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .register-modal .register {
    width: 100%;
    max-width: 96%;
    padding: 1em 0;
  }
}

/* for extra small screens and lower  */

@include for-extra-small {
  .register-modal .register {
    border-radius: 13px 13px 0 0;
    height: auto;
    max-height: 100vh;
    font-size: 0.9rem;
  }
  .register-modal .register .close img {
    width: 15px;
  }
  .register-modal .register h1 {
    font-size: 1.5rem;
  }
  .register-modal .register form {
    margin-top: 1rem;
  }
  .register-modal .register form button[type="submit"] {
    font-size: 0.9rem;
    height: 40px;
  }
  .register-modal .register form .or .flex .btn {
    height: 40px;
  }
  .register-modal .register form .or .flex .btn img {
    height: 20px;
  }
  .register-modal .register p.text-center:last-child {
    width: 100%;
  }
}
</style>
