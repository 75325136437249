<template>
  <div ref="loginModal" class="login-modal">
    <div class="login">
      <div class="con">
        <div class="close" @click="toggleLoginModal">
          <img src="@/assets/icons/icon-close.svg" alt=""/>
        </div>
        <h1>Sign In</h1>
        <p>Good to see you again, Just sign in with your credentials</p>
        <form action="" @submit.prevent="submit()">
          <div
              class="field-con"
              :class="{ error: errorState && errorState.email }"
          >
            <div class="field">
              <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  v-model="email"
              />
            </div>
            <div v-if="errorState && errorState.email" class="error">
              {{ errorState.email }}
            </div>
          </div>
          <div
              class="field-con"
              :class="{ error: errorState && errorState.password }"
          >
            <div class="field password">
              <input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Password"
                  v-model="password"
              />
              <div class="toggle" @click="togglePassword">
                <img src="@/assets/icons/eye.svg" alt=""/>
              </div>
            </div>
            <div v-if="errorState && errorState.password" class="error">
              {{ errorState.password }}
            </div>
          </div>
          <div class="forgot">
            <p class="link" @click="toggleResetPassword" href="">
              Forgot Password?
            </p>
          </div>
          <button type="submit">Sign In</button>
          <p class="text-center">
            Don't have an account? <a @click="toggleRegisterModal">Sign Up</a>
          </p>
          <div class="or">
            <p>or sign in with</p>
            <div class="flex">
              <google-login @get-google-token="socialLogin"  @social-login="userSocialLogin= 'google'" class="item"></google-login>
              <Facebook class="item" @get-facebook-token="socialLogin"  @social-login="userSocialLogin= 'facebook'"></Facebook>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>



</template>

<script>
import validator from "validator";
import {mapActions, mapState} from "vuex";
import Facebook from "@/components/Facebook";
import GoogleLogin from "@/components/GoogleLogin";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      password: undefined,
      email: undefined,
      clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      userSocialLogin:undefined
    }
  },
  components:{
    Facebook,
    GoogleLogin
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      token: (state) => state.auth.token,
    }),
  },
  methods: {
    ...mapActions(["logIn","socialSignin"]),
    //normal login
    submit() {
      if (this.handleValidation()) {
        let data = {
          email: this.email,
          password: this.password,
        };
        this.logIn(data)
            .then(() => {
              this.$toast.success(`Hi ${this.user.name}, login successful.`, {
                position: "top",
              });
              this.toggleLoginModal();
            })
            .catch((error) => {
              console.log(error.response);
              this.$toast.error(error.response.data.message, {
                position: "top",
              });
            });
      }
    },
    socialLogin(response) {
      try {
        let data = {
          provider: this.userSocialLogin
        };
        if (this.userSocialLogin === 'facebook'){
          data = {
            ...data,
            name:response.name,
            social_id:response.id,
            email: response.email,
            profile_url: response.picture,
            email_verified: true
          }
        }else{
          const userCredentials = jwt_decode(response);
          data={
            ...data,
            name: userCredentials.name,
            email: userCredentials.email,
            social_id: userCredentials.nbf,
            profile_url: userCredentials.picture,
            email_verified: true
          }
        }
        this.socialSignin(data).then(() => {
          this.$toast.success(`Hi ${this.user.name}, login successful.`, {
            position: "top",
          });
          this.toggleLoginModal();
          // setTimeout(()=>{
          //   window.location.reload();
          // },1000)

        });
      } catch (error) {
        console.log(error);
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      }
    },
    toggleRegisterModal() {
      this.$store.commit("toggleRegisterModal");
    },
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    toggleResetPassword() {
      this.$store.commit("toggleResetPasswordModal");
    },
    togglePassword() {
      let password = document.getElementById("password");
      let passwordType = password.getAttribute("type");
      if (passwordType == "password") {
        password.setAttribute("type", "text");
      } else {
        password.setAttribute("type", "password");
      }
    },
    handleValidation() {
      let errors = {};
      this.errorState = null;
      let formIsValid = true;

      // validate email
      if (!this.email) {
        formIsValid = false;
        errors["email"] = "email is required";
      } else if (!validator.isEmail(this.email)) {
        formIsValid = false;
        errors["email"] = "email should be a valid email";
      }

      // validate password
      if (!this.password) {
        formIsValid = false;
        errors["password"] = "password is required";
      }

      this.errorState = errors;
      console.log(formIsValid);
      return formIsValid;
    },
    hideModal(event) {
      const registerModal = this.$refs.loginModal;
      const target = event.target;
      if (registerModal && registerModal === target) {
        this.toggleLoginModal()
      }
    },
    closeModal(){
      this.$store.commit("toggleLoginModal",true);
    }
  },
  created() {
    document.addEventListener("click", this.hideModal);
    window.addEventListener('unload', this.closeModal)
  },
};
</script>
