import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import DefaultLayout from "@/layouts/DefaultLayout";
import Home from "@/views/Home.vue";
import SubCategoriesDetails from "@/views/SubCategories.vue";
import CategoryDetails from "@/views/CategoryDetails.vue";
import Chat from "@/views/Chat.vue";
import Notifications from "@/views/Notifications.vue";
import Favorite from "@/views/Favorite.vue";
import Profile from "@/views/Profile.vue";
import CustomerAccount from "@/views/CustomerAccount.vue";
import ProviderAccount from "@/views/ProviderAccount.vue";
import Messages from "@/views/Messages.vue";
import AddService from "@/views/AddService.vue";
import EditService from "@/views/EditService.vue";
import MyServices from "@/views/MyServices.vue";
import ServiceHistory from "@/views/ServiceHistory.vue";
import Service from "@/views/Service.vue";
import Services from "@/views/Services.vue";
import GeneralInfo from "@/views/GeneralInfo.vue";
import Location from "@/views/Location.vue";
import Confirmation from "@/views/Confirmation.vue";
import Pricing from "@/views/Pricing.vue";
import Users from "@/views/Users.vue";
import BlockUsers from "@/views/BlockUsers.vue";
import ReportProvider from "@/views/ReportProvider.vue";
import BlockUserDetails from "@/views/BlockUserDetails.vue";
import HomeAdmin from "@/views/HomeAdmin.vue";
import Statistics from "@/views/Statistics.vue";
import AddCategory from "@/views/AddCategory";
import AddSubCategory from "@/views/AddSubCategory";
import Categories from "@/views/Categories.vue";
import SubCategories from "@/views/SubCategories.vue";
import EmailVerification from "@/views/EmailVerification.vue";
import ResendVerification from "@/views/ResendVerification.vue";
import PaymentSuccessful from "@/views/PaymentSuccessful";
// import interceptor from "@/router/interceptor";

// import EnterNewPassword from "@/components/EnterNewPassword";
const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: DefaultLayout },
    component: Home,
    children: [
      {
        path: "/password/reset/:token?",
        name: "ResetPassword",
        component: Home,
        // only authenticated users can create posts
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: "/services",
    name: "Services",
    meta: { layout: DefaultLayout },
    component: Services,
  },
  {
    path: "/categories/:slug",
    name: "Category",
    meta: { layout: DefaultLayout },
    component: SubCategories,
  },
  {
    path: "/categories/:slug/:subId",
    name: "SubCategories",
    meta: { layout: DefaultLayout },
    component: SubCategoriesDetails,
  },
  {
    path: "/services/:slug",
    name: "Service",
    props: true,
    meta: { layout: DefaultLayout },
    component: Service,
  },
  {
    path: "/chats/:id",
    name: "Chat",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: Chat,
  },
  {
    path: "/notifications",
    name: "Notifications",
    meta: { layout: DefaultLayout },
    component: Notifications,
  },
  {
    path: "/favorite",
    name: "Favorite",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: Favorite,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: Profile,
  },
  {
    path: "/users/:id",
    name: "CustomerAccount",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: CustomerAccount,
  },
  {
    path: "/provider-account",
    name: "ProviderAccount",
    meta: { layout: DefaultLayout },
    component: ProviderAccount,
  },
  {
    path: "/chats",
    name: "Messages",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: Messages,
  },
  {
    path: "/service-history",
    name: "ServiceHistory",
    meta: { layout: DefaultLayout },
    component: ServiceHistory,
  },
  {
    path: "/users/:id/services",
    name: "MyServices",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: MyServices,
  },
  {
    path: "/edit-service/:slug",
    name: "EditService",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: EditService,
  },
  {
    path: "/general-info",
    name: "GeneralInfo",
    meta: { layout: DefaultLayout },
    component: GeneralInfo,
  },
  {
    path: "/location",
    name: "Location",
    meta: { layout: DefaultLayout },
    component: Location,
  },
  {
    path: "/add-service",
    name: "AddService",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: AddService,
  },
  {
    path: "/add-category",
    name: "AddCategory",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: AddCategory,
  },
  {
    path: "/add-sub-category",
    name: "AddSubCategory",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: AddSubCategory,
  },
  {
    path: "/categories",
    name: "Categories",
    meta: { layout: DefaultLayout },
    component: Categories,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    meta: { layout: DefaultLayout },
    component: Confirmation,
  },
  {
    path: "/pricing",
    name: "Pricing",
    meta: { layout: DefaultLayout ,requiresAuth: true },
    component: Pricing,
  },
  {
    path: "/users",
    name: "Users",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: Users,
  },
  {
    path: "/block-users",
    name: "BlockUsers",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: BlockUsers,
  },
  {
    path: "/report-provider/:id",
    name: "ReportProvider",
    meta: { layout: DefaultLayout, requiresAuth: true },
    props: true,
    component: ReportProvider,
  },
  {
    path: "/block-users/:id",
    name: "BlockUserDetails",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: BlockUserDetails,
  },
  {
    path: "/category-details",
    name: "CategoryDetails",
    meta: { layout: DefaultLayout },
    component: CategoryDetails,
  },
  {
    path: "/home-admin",
    name: "HomeAdmin",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: HomeAdmin,
  },
  {
    path: "/payment/successful",
    name: "PaymentSuccessful",
    meta: { layout: DefaultLayout, requiresAuth: true },
    component: PaymentSuccessful,
  },
  {
    path: "/statistics",
    name: "Statistics",
    meta: { layout: DefaultLayout },
    component: Statistics,
  },

  /**
   *
   */
  {
    path: "/auth/email/verify/:userId/:hash",
    name: "EmailVerification",
    meta: { layout: DefaultLayout },
    component: EmailVerification,
  },
  {
    path: "/resend-verification",
    name: "ResendVerification",
    meta: { layout: DefaultLayout },
    component: ResendVerification,
  },
  // {
  //   path: "/password/reset/:token",
  //   name: "PasswordReset",
  //   meta: { layout: DefaultLayout },
  //   component: EnterNewPassword,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    store.commit("toggleLoginModal");
    return {
      path: from,
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
});
// interceptor(router);
export default router;
