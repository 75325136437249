<template>
  <div class="email-verification">
    <Loader v-if="showLoader" />
    <!-- home categories section  -->
    <section v-else>
      <div class="con text-center">
        <!-- </vue-loaders> -->
<!--        <img src="../assets/icons/icon-close.svg" alt="" />-->
        <h1>Attention</h1>
        <p>{{responseMessage}}</p>
        <div class="buttons" v-if="error">
          <router-link
              :to="{
                path: '/resend-verification',
              }"
              class="btn btn-yellow"
          >
            Resend verification
          </router-link>
          <router-link
              :to="{
                path: '/login',
              }"
              class="go-to"
          >
            Login
          </router-link>

        </div>
        <div class="buttons" v-else>
          <router-link
              :to="{
                path: '/',
              }"
              class="btn btn-yellow"
          >
            Home
          </router-link>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapActions, mapState} from "vuex";
import Loader from "../components/Loader.vue";
import router from "../router";
export default {
  name: "EmailVerification",
  components: {Loader },
  data() {
    return {
      // showLoader: false,
      responseMessage:"",
      error:false,
    };
  },
  methods: {
    ...mapActions(["emailVerification"]),
  },
  computed: {
    ...mapState({
      showLoader: (state) => state.modals.showLoader,
    })
  },
  mounted() {
    // this.showLoader = true;
    this.$store.commit("setLoader",true);
    const userId = this.$route.params.userId;
    const hash = this.$route.params.hash;
    const url = new URL(window.location.href);
    const expires = url.searchParams.get("expires");
    const signature = url.searchParams.get("signature");
    //
    this.emailVerification({userId,hash,signature,expires})
        .then((response) => {
          if (response.status ===  200){
            router.push({
              path: "/confirmation",
            });
          }else{
            this.$toast.info(`${response?.data.message}`, {
              position: "top",
            });
            this.responseMessage = response?.data.message;
          }
          this.error = false;
        })
        .catch((error) => {
          console.log(error)
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
          this.responseMessage = error.response.data.message;
          // this.showLoader = false;
          this.$store.commit("setLoader",false);
          this.error = true;
        });
  },
};
</script>


<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.email-verification {
  .con {
    text-align: center;
    img {
      width: 250px;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    p {
      margin-top: 1rem;
      font-size: 1rem;
      color: $light-text;
      @include for-large {
        width: 50%;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
    .buttons {
      width: 100%;
      margin-top: 2rem;
      .btn-yellow {
        width: 100%;
        @include for-large {
          width: 40%;
          margin: 0 auto;
        }
      }
      .go-to {
          color: $text;
          border-bottom: 1px solid $text;
          margin-top: 1rem;
          display: inline-block;
          font-weight: 500;

      }
    }
  }
}
</style>
