<template>
  <div class="chat-page">
    <Loader v-if="showLoader" />

      <div class="top">
        <div class="con">
          <div class="info">
            <img v-if="receiver?.profile_url" :src="receiver?.profile_url" alt="" />
            <img v-else src="../assets/servicehub-avatar.png" alt="" />
            <div class="info-sub">
              <div class="name">{{receiver?.name}}</div>
            </div>
          </div>
          <a href="javascript:void(0)" @click.prevent="goBack" class="icon">
            <img src="../assets/icons/arrow_back_black_24dp.svg" alt=""/>
          </a>
        </div>
      </div>
      <div class="chat">
        <div v-for="(messages,key) in chats" :key="key" class="con chat-per-day">
          <div class="date">
            <div class="sub">{{groupMessageDate(key)}}</div>
          </div>
          <div v-if="messages.length">
            <div v-for="(message,index) in messages"  :key="index" class="messages">
            <div v-if="user.id === (message?.sender?.id || message.sender_id)" class="leaving">
              <div class="message">
                <p>{{message.content}}</p>
                <div class="time">{{ getTime(message.created_at) }}</div>
              </div>
            </div>
            <div v-else class="entering">
              <div class="message">
                <p>{{message.content}}</p>
                <div class="time">{{ getTime(message.created_at) }}</div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="write-message">
        <div class="con">
          <form action="" @submit.prevent="sendMessage">
            <input type="text" name="" id="" placeholder="Type something.." v-model="message"/>
            <button type="submit" :disabled="!message"  :class="{'disabled':!message}">
              <img src="../assets/icons/send_black_24dp.svg" alt=""/>
            </button>
          </form>
        </div>
      </div>


  </div>
</template>
<script>
import {mapState} from "vuex";
import ApiService from "@/services/api.service";
import Loader from "@/components/Loader";
import moment from "moment";
import router from "@/router";


export default {
  setup() {
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,
    }),
  },
  components: {Loader },
  data() {
    return {
      chats: null,
      // showLoader:false,
      receiver:undefined,
      message:undefined,
      redirect_url :undefined,
      parity_bit:237,
      room_id: undefined,

    };
  },
  mounted() {
    // this.showLoader = true;
    this.$store.commit("setLoader",true);

    document.querySelector('main').classList.add("chat-page-main");
    const chat = JSON.parse( localStorage.getItem('chat'));
    this.receiver = chat.receiver;
    this.room_id = chat?.room_id;
    if (this.room_id){
      this.getRoomChats(this.room_id);
    }else{
      this.getRoom()
    }


  },
  methods:{
    getRoomChats(room_id){
      ApiService.getRoomChats(room_id).then((response)=>{
        if (response.status===200){
          this.chats = this.groupMessages( response.data.data.messages);
        }
        // this.showLoader = false;
        this.$store.commit("setLoader",false);

      }).catch((error)=>{
        // this.showLoader= false;
        this.$store.commit("setLoader",false);
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      })
    },
    getRoom(){
      const data= {
        receiver_id : this.$route.params.id,
        sender_id: this.user.id
      }
      ApiService.getRoom(data).then((response)=>{
        this.chats = this.groupMessages( []);
        this.room_id = response.data.data.id;
        // this.showLoader = false;
        this.$store.commit("setLoader",false);

      }).catch((error)=>{
        // this.showLoader= false;
        this.$store.commit("setLoader",false);

        this.$toast.error(error.response.data.message, {
          position: "top",
        });
        console.log(error)
      })
    },
    sendMessage(){
      if (this.message){
        const data = {
          receiver_id : this.receiver.id.toString(),
          sender_id: this.user.id.toString(),
          content: this.message,
          room_id:this.room_id
        }
        ApiService.sendMessage(data).then((response)=>{
          if (response.status===201){
            this.chats = this.groupMessages([response.data.data], this.chats);
            this.message = undefined
          }
        }).catch((error)=>{
          this.showLoader= false;
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
          console.log(error)
        })
      }
    },
    groupMessages(messages,new_message_object={}){
      let local_message_object = new_message_object;
      if (messages.length){
        for (const message of messages) {
          let messageDate = new Date(message.created_at);
          messageDate = moment(messageDate).format("MM-DD-YYYY");
          if (local_message_object[messageDate]){
            local_message_object[messageDate].push(message)
          }else{
            local_message_object[messageDate] = [message]
          }
        }
        return local_message_object
      }else {
        let today = moment(new Date()).format("MM-DD-YYYY");
        local_message_object[today] = [];
        return local_message_object;
      }

    },
    groupMessageDate(group_date){
      let local_date =  group_date.replaceAll("-","/");
      let messageDate = moment(new Date(`${local_date}`)).format("MM-DD-YYYY");
      let today = moment(new Date()).format("MM-DD-YYYY");
      if (messageDate === today){
        return "Today"
      }else{
        return moment(new Date(local_date)).format("dddd MMMM DD,YYYY");
      }
    },
    getTime(message_date){
      return moment(new Date(message_date)).format("h:mma");
    },
    goBack(){
      /**
       * Not working at the moment
       */
      // router.push({
      //   path: `${this.redirect_url}`,
      // });
      router.go(-1)
    }

  },
  unmounted() {
    document.querySelector('main').classList.remove("chat-page-main");
  }

}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
/* -------------------------------- chat page ------------------------------- */
.chat-page .top .con {
  position: relative;
  border-bottom: 1px solid $border-color;
}
main{
  margin:0 !important;
}
.chat-page .top {
  width: 100%;
  position: fixed;
  top: 4rem;
  left: 0;
  z-index: 2;
  background-color: $white;
}

.chat-page .top .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}

.chat-page .top .icon img {
  width: 70%;
}

.chat-page .top .info {
  width: fit-content;
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 80%;
  padding: 1rem 0;
}

.chat-page .top .info img {
  width: 60px;
  height: 60px;
  border-radius: $border-radius;
  margin-right: 1rem;
  object-fit: cover;
  object-position: center;
}

.chat-page .top .info .info-sub .name {
  font-size: 1.2rem;
  font-weight: 600;
}

.chat-page .top .info .info-sub p {
  margin: 0;
  color: $light-text;
}

.chat-page .write-message {
  bottom: 3.5rem;
  width: 100vw;
  padding: 1rem 0;
  background-color: #fff;
  z-index: 2;
  margin-top: auto;
  position: fixed;
}

.chat-page .write-message form {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 0.4rem;
}

.chat-page .write-message form input {
  width: calc(98% - 40px);
  padding: 0 0.5rem;
  font-size: 1rem;
  height: 40px;
}

.chat-page .write-message form button {
  width: 40px;
  height: 40px;
  border-radius: $border-radius;
  background-color: $yellow;
  cursor: pointer;
}
.chat-page .write-message form button.disabled {
opacity: 0.5;
}

.chat-page .write-message form button img {
  width: 60%;
}

.chat-page .chat {
  padding: 1rem 0;
  margin: 10rem 0;
}

.chat-page .chat .date {
  width: 100%;
  margin-bottom: 1rem;
}

.chat-page .chat .sub {
  width: fit-content;
  margin: 0 auto;
  border-radius: $border-radius;
  background-color: $light-gray;
  padding: 0.5rem 1rem;
  color: $text;
}

.chat-page .chat .message {
  width: 70%;
}

.chat-page .chat .leaving {
  padding: 0;
  display: flex;
  position: relative;
}

.chat-page .chat .leaving .message {
  margin-left: 30%;
  @include for-tablet {
    margin-left: 40%;
  }
  @include for-large {
    margin-left: 60%;
  }
}

.chat-page .chat .leaving .message p {
  padding: 1rem;
  background-color: $text;
  color: $white;
  margin: 0;
  line-height: 1;
  border-radius: $border-radius 0 $border-radius $border-radius;
}

.chat-page .chat .leaving .message .time {
  font-size: 0.8rem;
  color: $light-text;
  width: 100%;
  text-align: right;
  margin-top: 0.3rem;
}

.chat-page .chat .entering {
  padding: 0;
  display: flex;
  align-items: flex-start;
  position: relative;
}

.chat-page .chat .entering .message {
  margin-right: 30%;
  @include for-tablet {
    margin-right: 40%;
  }
  @include for-large {
    margin-right: 60%;
  }
}

.chat-page .chat .entering .message p {
  padding: 1rem;
  background-color: $yellow;
  color: $text;
  margin: 0;
  line-height: 1;
  border-radius: $border-radius $border-radius $border-radius 0;
}

.chat-page .chat .entering .message .time {
  font-size: 0.8rem;
  color: $light-text;
  margin-top: 0.3rem;
}

/* for extra small screens and lower  */
@include for-extra-small {
  .chat-page .top .icon {
    width: 35px;
    height: 35px;
  }
  .chat-page .top .info {
    padding: 0.5rem 0;
  }
  .chat-page .top .info img {
    width: 45px;
    height: 45px;
    margin-right: 0.5rem;
  }
  .chat-page .top .info .info-sub .name {
    font-size: 1rem;
    line-height: 0.9;
  }
  .chat-page .top .info .info-sub p {
    font-size: 0.8rem;
  }
  .chat-page .write-message {
    padding: 0.5rem 0;
  }
  .chat-page .write-message form input {
    width: calc(98% - 40px);
    height: 40px;
  }
  .chat-page .write-message form button {
    width: 40px;
    height: 40px;
  }
  .chat-page .chat {
    margin: 50px 0;
    padding: 1rem 0;
  }
  .chat-page .chat .leaving {
    padding: 0.5rem 0;
  }
  .chat-page .chat .entering {
    padding: 0.5rem 0;
  }
}
</style>
