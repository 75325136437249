<template>
  <main>
    <div class="review-page">
      <div class="con">
        <form class="info" @submit.prevent="createCategory">
          <ul>
            <li>
              <div class="left">
                <div class="name">Category Name</div>
                <input
                  v-model="name"
                  type="text"
                  name=""
                  placeholder="Add title"
                  id=""
                />
              </div>
            </li>
            <li>
              <div class="left">
                <div class="name">Category Type</div>
                <input
                  v-model="type"
                  type="text"
                  name=""
                  placeholder="Add type"
                  id=""
                />
              </div>
            </li>
            <li>
              <div class="left">
                <div class="name">Category Description</div>
                <textarea
                  v-model="description"
                  name=""
                  placeholder="Be precise in category description in order to make users understand the scope of the category..."
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </li>
            <li>
              <div class="left">
                <div class="name">Add photos</div>
                <div v-if="!photos.length" class="add-photo">
                  <img src="../assets/icons/image_black_24dp.svg" alt="" />
                  <input
                    @change="handleFiles"
                    type="file"
                    name="photos"
                    id="photos"
                    data-max-size="509054"
                    ef="file"
                    multiple="multiple"
                    accept="image/gif, image/jpeg, image/png"
                  />
                  <p>Add at least 2 photos</p>
                  <label for="photos">Upload photo from device</label>
                </div>
                <div v-else class="add-photo">
                  <div class="photos">
                    <div
                      class="img-con"
                      v-for="(file, index) in photos"
                      :key="index"
                    >
                      <img :src="file.url" alt="" />
                      <div class="actions">
                        <a
                          href="javascript:void(0)"
                          @click="removeSelectedPhoto(index, file)"
                          class="action"
                        >
                          <img
                            src="../assets/icons/delete_black_24dp.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <input
                    @change="handleFiles"
                    type="file"
                    name="photos"
                    id="photos"
                    ef="file"
                    data-max-size="32154"
                    multiple="multiple"
                    accept="image/gif, image/jpeg, image/png"
                  />
                  <label for="photos">Upload more photo(s)</label>
                </div>
              </div>
            </li>
          </ul>
          <div class="btn-flex">
            <a href="" class="link">Cancel</a>
            <button type="submit" class="btn btn-yellow">Add Category</button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { processFiles } from "../store/helpers";

// import { locations } from "@/store/locations";

export default {
  name: "Add  Category",
  components: {},
  data() {
    return {
      name: "",
      type: "",
      description: "",
      photos: [],
      user: {},
    };
  },
  validations() {
    return {
      name: { required },
      isSubmitted: false,
      errorSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions(["createCategoryAction"]),
    ...mapGetters(["isAuthenticated"]),

    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    async createCategory() {
      let data = new FormData();
      data.append("name", this.name);
      data.append("type", this.type);
      data.append("description", this.description);
      if (this.photos.length > 0) {
        data.append("thumbnail", this.photos[0]);
      }
      return this.createCategoryAction(data)
        .then((response) => {
          this.$toast.success(`Category ${this.name} created`, {
            position: "top",
          });
          console.log(response);
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
          });
          console.log(error);
        });
    },
    async removeSelectedPhoto(index, file) {
      try {
        this.photos.splice(index, 1);
        this.$toast.success(`${file.name} removed`, {
          position: "top",
        });
      } catch (e) {
        this.$toast.error(`${file.name} removed`, {
          position: "top",
        });
      }
      //  this.$delete(this.finds, index)
    },

    async handleFiles(e) {
      this.photos = await processFiles(e, this.photos);
    },
  },
  mounted() {
    if (!this.isAuthenticated()) {
      this.toggleLoginModal();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.info {
  margin-bottom: 3rem;
  ul {
    div.field {
      padding: 1rem 0;

      select {
        font-size: 1em;
        width: 100%;
        receiverground-color: $white;
        border: none;
        border-bottom: 1px solid $border-color;
        // border-radius: $border-radius;
        line-height: 0.9em;
        color: $text !important;
        margin-top: 0.3rem;
        padding-bottom: 0.9em;
        option {
          padding-left: 0 1rem;
          &.placeholder {
            color: #ccc !important;
          }
        }
      }
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    li {
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      padding: 1rem 0;
      &:last-child {
        margin-bottom: 1rem;
        border-bottom: none;
      }
      input,
      textarea {
        width: 100%;
        receiverground-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        font-size: 1rem;
        color: $text;
        margin-top: 0.3rem;
      }
      input {
        padding: 0 1rem;
        height: 45px;
      }
      textarea {
        padding: 1rem;
      }
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
      .redirect-con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        & > img {
          width: 15px;
        }
      }
      .redirect {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
      }

      .redirect img {
        width: 25px;
        margin-right: 1rem;
      }

      .redirect span {
        font-size: 1rem;
        text-transform: capitalize;
        line-height: 0.9;
      }
    }
  }
}
.add-photo {
  width: 100%;
  height: auto;
  margin: 0.5rem 0;
  padding: 1.5rem 0;
  border: 2px dashed $text;
  border-radius: $border-radius;
  display: grid;
  align-content: center;
  justify-items: center;
  input {
    display: none;
  }
  img {
    width: 50px;
    opacity: 0.5;
  }
  p {
    margin: 0.5rem 0;
  }
  label {
    border-bottom: 1px solid $text;
    font-weight: 500;
  }
  .photos {
    width: 100%;
    border-top: 1px solid $border-color;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
    .img-con {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        object-position: center;
        object-fit: cover;
      }
      .actions {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5rem;
        .action {
          width: 30px;
          height: 30px;
          receiverground-color: $white;
          border-radius: 4px;
          margin-left: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.301);
          img {
            position: relative;
            transform: scale(0.6);
          }
        }
      }
    }
    .add-img {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      border: 2px dashed $text;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
      }
    }
    @include for-large {
      grid-template-columns: repeat(3, 1fr);
      .img-con {
        height: 200px;
      }
      .add-img {
        height: 200px;
      }
    }
  }
}
.btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  .btn {
    width: fit-content;
  }
  > a.link {
    font-size: 1rem;
    height: fit-content;
    line-height: 1.1;
    border-bottom: 1px solid $primary-color;
  }
}
</style>
