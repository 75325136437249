<template>
  <div class="home-admin-page">
    <div class="con">
      <h1>Hello Elvine, nice to see you again.</h1>
      <div class="home-menu">
        <form class="search">
          <img src="../assets/icons/search_black_24dp.svg" alt="" />
          <input type="text" name="" placeholder="Search" id="" />
        </form>
        <div class="menu-btn">
          <img src="../assets/icons/tune_black_24dp.svg" alt="" />
        </div>
      </div>
      <div class="admin-sub">
        <h3>New Users Today</h3>
        <div class="stat-cards">
          <div class="stat-card black">
            <p><span>Providers</span></p>
            <div class="number">+1.385</div>
          </div>
          <div class="stat-card yellow">
            <p><span>Users</span></p>
            <div class="number">+521</div>
          </div>
        </div>
      </div>
      <div class="admin-sub">
        <div class="flex">
          <h3>Notifications</h3>
          <a href="" class="link">See All</a>
        </div>
        <div class="notification">
          <img
            src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
            alt=""
          />
          <div class="info">
            <div class="name">Gablrielle Winn</div>
            <p>Sent you a message</p>
          </div>
        </div>
        <div class="notification">
          <img
            src="../assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
            alt=""
          />
          <div class="info">
            <div class="name">Gablrielle Winn</div>
            <p>Sent you a message</p>
          </div>
        </div>
      </div>
      <div class="admin-sub">
        <div class="flex">
          <h3>Block Request</h3>
          <a href="" class="link">See All</a>
        </div>
        <div class="services">
          <div class="block-user-card">
            <div class="top">
              <img
                src="@/assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
                alt=""
              />
              <div class="info">
                <div class="name">Joshua Wilson</div>
                <p>Member since 2021</p>
              </div>
            </div>
            <div class="btns">
              <a href="" class="btn btn-light">
                <img src="../assets/icons/place_black_24dp.svg" alt="" />
                <span>Details</span>
              </a>
              <a href="" class="btn btn-light">
                <img src="../assets/icons/place_black_24dp.svg" alt="" />
                <span>Block User</span>
              </a>
            </div>
          </div>
          <div class="block-user-card">
            <div class="top">
              <img
                src="@/assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
                alt=""
              />
              <div class="info">
                <div class="name">Joshua Wilson</div>
                <p>Member since 2021</p>
              </div>
            </div>
            <div class="btns">
              <a href="" class="btn btn-light">
                <img src="../assets/icons/place_black_24dp.svg" alt="" />
                <span>Details</span>
              </a>
              <a href="" class="btn btn-light">
                <img src="../assets/icons/place_black_24dp.svg" alt="" />
                <span>Block User</span>
              </a>
            </div>
          </div>
          <div class="block-user-card">
            <div class="top">
              <img
                src="@/assets/images/billy-freeman-koRmF1nz8iw-unsplash.jpg"
                alt=""
              />
              <div class="info">
                <div class="name">Joshua Wilson</div>
                <p>Member since 2021</p>
              </div>
            </div>
            <div class="btns">
              <a href="" class="btn btn-light">
                <img src="../assets/icons/place_black_24dp.svg" alt="" />
                <span>Details</span>
              </a>
              <a href="" class="btn btn-light">
                <img src="../assets/icons/place_black_24dp.svg" alt="" />
                <span>Block User</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

.home-admin-page {
  .con {
    h1 {
      font-size: 2rem;
      line-height: 1;
      margin: 1rem 0;
    }
    .home-menu {
      margin-bottom: 1rem;
    }
    .admin-sub {
      margin-bottom: 2rem;
      .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        h3 {
          margin-bottom: 0 !important;
        }
      }
      h3 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .link {
        color: $text;
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 1px solid $text;
      }
      .stat-cards {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        .stat-card {
          width: 49%;
          height: auto;
          padding: 1rem;
          border-radius: 10px;
          p {
            font-size: 1.1rem;
            span {
              font-weight: 500;
            }
          }
          .number {
            font-size: 2rem;
            font-weight: 600;
            margin-top: 0.5rem;
          }
          &.black {
            background-color: $text;
            color: $white;
          }
          &.yellow {
            background-color: $yellow;
            color: $text;
          }
        }
      }
      .notification {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.3rem;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        margin-bottom: 1rem;
        img {
          width: 70px;
          height: 70px;
          border-radius: $border-radius;
          object-fit: cover;
          object-position: center;
          margin-right: 1rem;
        }
        .info {
          .name {
            font-size: 1.1rem;
            color: $text;
          }
          p {
            font-size: 1rem;
            color: $light-text;
          }
        }
      }
    }
  }
}
</style>
