<template>
  <div
    v-for="(service, index) in services"
    :key="index"
    class="service-card owner"
  >
    <div class="sub">
      <img :src="service.thumbnail ?? thumbnailPlaceholder" alt="" />
      <div class="content">
        <div class="top">
          <Reviews :service_id="service?.id ?? null" />
          <Likes
            :user_id="user?.id ?? null"
            :now="service?.likes ?? []"
            :service_id="service?.id ?? null"
            :numberOfLikes="service?.like_count"
          />
        </div>
        <router-link
          :to="{
            path: `/services/${service.slug}`,
            props: { service: service },
          }"
          class="name"
        >
          {{ service?.name }}
        </router-link>
        <ServiceCategoryDisplay :subcategories="service.sub_categories" />
        <ServiceLocationDisplay :location_id="service.location_id" />
      </div>
    </div>
    <div v-if="page" class="btns">
      <a
        href="javascript:void(0)"
        @click="editService(service?.slug)"
        class="btn btn-light"
      >
        <img src="../assets/icons/edit_black_24dp.svg" alt="" /> Edit</a
      >
      <a href="" class="btn btn-light">
        <img src="../assets/icons/delete_black_24dp.svg" alt="" /> Delete</a
      >
    </div>
  </div>
  <div v-if="services?.length === 0"><p>No service found</p></div>
</template>

<script>
import { mapState } from "vuex";
import Likes from "./Likes.vue";
import router from "../router";
import Reviews from "./Reviews.vue";
import ServiceLocationDisplay from "./ServiceLocationDisplay.vue";
import ServiceCategoryDisplay from "./ServiceCategoryDisplay.vue";

export default {
  props: {
    services: {
      // type: [],
      required: true,
    },
    page: {
      // type: String,
      required: false,
    },
  },
  data() {
    return {
      allServices: [],
      reviews: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    editService(slug) {
      router.push({
        path: `/edit-service/${slug}`,
      });
    },
  },
  mounted() {},
  components: {
    Likes,
    Reviews,
    ServiceLocationDisplay,
    ServiceCategoryDisplay,
  },
};
</script>
