<template>
  <div class="login-modal" ref="resetSuccess">
    <div class="login">
      <div class="con">
        <a
          href="javascript:void(0)"
          @click="toggleResetSuccessModal"
          class="close"
        >
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </a>
        <img class="check-email" src="../assets/images/congrats.png" alt="" />
        <h1>Congratulations</h1>
        <p>Your password has been reseted</p>
        <a href="javascript:void(0)" @click="toggleLoginModal" class="sign-in">
          <span>Sign In</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
  data() {
    return {};
  },
  methods: {
    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    toggleResetSuccessModal() {
      this.$store.commit("toggleResetSuccessModal");
    },
    hideModal(event) {
      const resetSuccess = this.$refs.resetSuccess;
      const target = event.target;
      if (resetSuccess && resetSuccess === target) {
        this.toggleResetSuccessModal()
      }
    },
    closeModal(){
      this.$store.commit("toggleResetSuccessModal",true);

    }
  },
  created() {
    document.addEventListener("click", this.hideModal);
    window.addEventListener('unload', this.closeModal);
  },

};
</script>
