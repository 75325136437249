import AuthService from "../../services/auth.service";
import router from "@/router";

const state = {
  user: null,
  token: null,
};
let localStorage = window.localStorage;
const getters = {
  isAuthenticated: (state) => !!state.user,
  user: (state) => state.user,
};
const actions = {
  async register({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      AuthService.register(credentials)
        .then((response) => {
          commit("setUser", null);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async logIn({ commit }, credentials) {
    return new Promise((resolve, reject) => {
      AuthService.login(credentials)
        .then((response) => {
          commit("setToken", response.data.data.access_token);
          commit("setUser", response.data.data.user);
          localStorage.setItem(
            "serviceHubAuthToken",
            response.data.data.access_token
          );
            if (response.data.data.user?.user_role && response.data.data.user?.user_role.name === "Admin"){
                commit("setHasAdminRole", true,{root:true});
            }else{
                commit("setHasAdminRole", false,{root:true});
            }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async socialSignin({ commit }, data) {
    return new Promise((resolve, reject) => {
      AuthService.socialSignin(data)
        .then((response) => {
          commit("setToken", response.data.data.access_token);
          commit("setUser", response.data.data.user);
          if (response.data.data.user?.user_role && response.data.data.user?.user_role.name === "Admin"){
              commit("setHasAdminRole", true,{root:true});
          }else{
              commit("setHasAdminRole", false,{root:true});
          }
          localStorage.setItem(
            "serviceHubAuthToken",
            response.data.data.access_token
          );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async forgotPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      AuthService.forgotPassword(data)
        .then((response) => {
          console.log(response.data.data.user);
          // commit("setToken", response.data.data.access_token);
          commit("setUser", response.data.data.user);
          // localStorage.setItem(
          //   "serviceHubAuthToken",
          //   response.data.data.access_token
          // );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async resetPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      AuthService.resetPassword(data)
        .then((response) => {
          console.log(response.data.data.user);
          // commit("setToken", response.data.data.access_token);
          commit("setUser", response.data.data.user);
          // localStorage.setItem(
          //   "serviceHubAuthToken",
          //   response.data.data.access_token
          // );
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async logOut(commit ) {
    await commit("setToken", null);
    await commit("setUser", null);
    localStorage.setItem("serviceHubAuthToken", null);
    localStorage.removeItem("serviceHubAuthToken");
  },
    async logOut2({commit}){
        this.reset();
        localStorage.setItem("serviceHubAuthToken", null);
        localStorage.removeItem("serviceHubAuthToken");
        localStorage.setItem("chat", null);
        localStorage.removeItem("chat");
        localStorage.setItem('homePageReload',null)
        localStorage.removeItem('homePageReload')
        router.push({
            path: '/',
        });
        commit("toggleLoginModal",false,{ root: true });
    },
    async emailVerification({ commit ,state}, data) {
        return new Promise((resolve, reject) => {
            AuthService.emailVerification(data)
                .then((response) => {
                    if (!state.user){
                        commit("setUser", null);
                    }
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async resendVerification({ commit }, data) {
        return new Promise((resolve, reject) => {
            AuthService.resendVerification(data)
                .then((response) => {
                    console.log(response.data.data);
                    commit("setUser", null);
                    // commit("setUser", response.data.data.user);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

};
const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setUser(state, user) {
    state.user = user;
  },
  logOut(state) {
    state.loggedIn = null;
    state.token = null;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
