import APIService from "../../services/api.service";

const state = {
  locations: null,
};

const actions = {
  async getAllLocations({ commit }) {
    return new Promise((resolve, reject) => {
      APIService.getAllLocations()
        .then((response) => {
          commit("setLocations", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
const getters = {
  // ...
  getLocationFromStore: (state) => (id) => {
    return state.locations.find((location) => location.id === id);
  },
};
const mutations = {
  setLocations(state, payload) {
    state.locations = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
