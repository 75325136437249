<template>
  <div class="confirmation-page">
    <div class="con">
      <img src="../assets/icons/check.svg" alt="" />
      <h1>Congratulations</h1>
      <p>
        Your account has been verified, now its time for the next step, Decide
        which of our specially tailored are perfect for you and unlock full
        potential of Service-Hub Africa
      </p>

      <div class="buttons">
        <router-link
            :to="{
                path: '/pricing',
              }"
            class="btn btn-yellow"
        >
          Check Plans
        </router-link>
        <router-link
            :to="{
                path: '/',
              }"
            class="go-to"
        >
          Not now
        </router-link>

      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";

/* ------------------------------ favorite page ----------------------------- */
.confirmation-page {
  .con {
    text-align: center;
    img {
      width: 250px;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }
    p {
      margin-top: 1rem;
      font-size: 1rem;
      color: $light-text;
      @include for-large {
        width: 50%;
        margin: 0 auto;
        margin-top: 1rem;
      }
    }
    .buttons {
      width: 100%;
      margin-top: 2rem;
      .btn-yellow {
        width: 100%;
        cursor: pointer;
        @include for-large {
          width: 40%;
          margin: 0 auto;
        }
      }
      a {
        &:last-child {
          color: $text;
          border-bottom: 1px solid $text;
          margin-top: 1rem;
          display: inline-block;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
