

<template>
  <div ref="notificationModal" class="login-modal">
    <div class="login">
      <div class="con">
        <a @click="hideModal" href="javascript:void(0)" class="close">
          <img src="@/assets/icons/icon-close.svg" alt="" />
        </a>
        <h1 v-if="notification.notification_title">{{notification.notification_title}}</h1>
        <div>{{notification.notification_content }}</div>
        <p>by:{{notification.notification_type}} notification</p>
        <p>{{notification.created_at}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Notification",
  emits: ['hideNotificationModal'],
  props: {
    notification: {
      required: true,
    },
  },
  refs: ['notificationModal'],
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setNotificationStatus"]),
    hideModal() {
      this.$emit('hideNotificationModal')
    },
    onClickModal(event) {
      const paymentModal = this.$refs.notificationModal;
      const target = event.target;
      if (paymentModal && paymentModal === target) {
        this.hideModal()
      }
    },
    setStatus(){
      const data ={
        notification_id:this.notification?.id,
        read:"read"
      }
      this.setNotificationStatus(data).then((response)=>{
        this.$toast.success(response.data.message, {
          position: "top",
        });
      }).catch((error)=>{
        console.log(error)
        this.$toast.error(error.response.data.message, {
          position: "top",
        });
      })
    }
  },
  mounted() {
    this.setStatus()
  },
  created() {
    document.addEventListener("click", this.onClickModal);
  },
};
</script>
