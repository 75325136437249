import {http} from "./http-common";

function setAuth(){
      http.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
        "serviceHubAuthToken"
    )}`;
}

class ServiceHubAPI {
  getAllCategories(limit) {
    setAuth()
    return http.get(`/categories?limit=${limit}`);
  }

  getUserDetails() {
    setAuth()
    return http.get("/user-details");
  }

  getFeaturedCategories() {
    setAuth()
    return http.get("/categories?type=featured");
  }

  getSubCategories(id) {
    setAuth()
    return http.get(`/categories/sub/${id}`);
  }

  getAllServices() {
    setAuth()
    return http.get("/services");
  }
  getSubCategoryServices(sub_category_id) {
    setAuth()
    return http.get(`/services?sub_category_id=${sub_category_id}`);
  }

  getAllLocations() {
    setAuth();

    return http.get("/locations");
  }

  createService(data) {
    setAuth()
    return http.post("/services", data);
  }

  createServiceRequest(data) {
    setAuth()
    return http.post("/services/requests", data);
  }

  createCategory(data) {
    setAuth()
    return http.post("/categories", data);
  }

  createSubCategory({ category_id, data }) {
    setAuth()
    return http.post(`/categories/sub/${category_id}`, data);
  }

  getAllUsers() {
    setAuth()
    return http.get("/users");
  }

  getUserById(user_id) {
    setAuth()
    return http.get(`/users/${user_id}`);
  }

  reportProvider(data) {
    setAuth()
    // return http.post("/user/report", data);
    return http.post("/blocked-users-requests", data);
  }

  userReports() {
    setAuth()
    return http.get("/blocked-users-requests");
  }

  getServiceReviews(service_id) {
    setAuth()
    return http.get(`/reviews?service_id=${service_id}`);
  }

  createReview(data) {
    setAuth()
    return http.post("/reviews", data);
  }

  getServiceLikes({ service_id, data }) {
    setAuth()
    return http.post(`/services/${service_id}/likes`, data);
  }
  makePayment(data) {
    setAuth()
    return http.post("/plans/subscribe", data);
  }
  sendMessage(data) {
    setAuth()
    return http.post("/chats/new", data);
  }

  searchAction({search,order}) {
    setAuth()
    return http.get(`/search?search=${search}&order=${order}`);
  }
  getStats({ user_id }) {
    setAuth()
    return http.get(
      `/statistics?user_id=${user_id}`
    );
  }
  getAllRoles() {
    setAuth()
    return http.get("/roles");
  }
  getPaymentPlans() {
    setAuth()
    return http.get('/plans/get');
  }
  // getUserPlan(userId) {
  //   console.log("userId")
  //   console.log(userId)
  //   setAuth()
  //   return http.post('/plans/is_subscribed',{
  //     "plan": "free,basic,premium",
  //     "user_id": userId
  //   });
  // }
  getPaymentStatus(reference) {
    setAuth()
    return http.get(`/payments/status/campay/${reference}`);
  }
  getRoom( {receiver_id,sender_id}) {
    setAuth()
    return http.get(`/chats/get-room?sender_id=${sender_id}&receiver_id=${receiver_id}`);
  }
  getRoomChats( room_id) {
    setAuth()
    return http.get(`/chats/messages/${room_id}`);
  }
  getFavourites( {user_id,limit,order})
{
  setAuth()
  return http.get(`/services/favorite?user_id=${user_id}&limit=${limit}&order=${order}`);
}
  getStatistics(duration) {
    setAuth()
    return http.get(`/statistics?duration=${duration}`);

  }
  getNotifications({user_id,notification_type}) {
    setAuth()
    return http.get(`/notification/get?user_id=${user_id}&notification_type=${notification_type}`);
  }
  setNotificationStatus({read,notification_id}) {
    setAuth()
    return http.get(`/notification/is-read?read=${read}&notification_id=${notification_id}`);
  }
  getUserChats(sender_id) {
    setAuth()
    return http.get(`/chats/user/${sender_id}`);
  }

  getBlockRequest(request_id) {
    setAuth()
    return http.get(`/blocked-users-requests/${request_id}`);
  }


  updateUser({user_id,data}) {
    setAuth()
    delete data["id"];
    return http.post(`/users/${user_id}`, data);
  }

  blockUser({user_id,data}) {
    setAuth()
    return http.post(`/users/${user_id}/block`,data);
  }

}

const API = new ServiceHubAPI();

export default API;
export { API as ServiceHubAPI };
