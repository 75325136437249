<template>
  <div class="home-menu">
    <form class="search" @submit.prevent="getSearchItems">
      <img src="../assets/icons/search_black_24dp.svg" alt="" />
      <input
        type="text"
        name="home-search-bar"
        v-model="search"
        placeholder="Search"
        id=""
      />
      <span v-if="searching"  class="search-filter-searching"></span>
    </form>
    <div ref="searchFilter" class="menu-btn">
      <img src="@/assets/icons/tune_black_24dp.svg" alt="" class="event-none" />
      <div class="filter" v-if="showFilter">
        <p>sort by:</p>
        <form action="">
          <div class="field">
            <label for="check-3">
            <input type="radio" name="check" id="check-3" value="desc" v-model="order" @change="orderChange" />
              <div class="check" :class="{'checked':order==='desc'}"></div>
              <span>Newest to oldest</span>
            </label>
          </div>
          <div class="field">
            <label for="check-4">
            <input type="radio" name="check" value="asc" v-model="order" id="check-4" @change="orderChange" />
              <div class="check" :class="{'checked':order==='asc'}"></div>
              <span>Oldest to newest</span>
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions } from "vuex";
export default {
  name: "SearFilter",
  props: {
    page: {
      // type: String,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      showFilter: false,
      order:'desc',
      result: null,
      searching:false,
    };
  },
  watch: {
    search: function (current) {
      if (current) {
        this.getSearchItems(current);
      } else {
        this.result = null;
        if (this.page === "users") {
         this.$emit("searchData", this.result);
        }
      }
    },
  },
  computed: {},
  methods: {
    ...mapActions(["searchAction"]),

     getSearchItems() {
      this.searching = true;
      this.searchAction({search:this.search,order:this.order})
        .then((response) => {
          if(this.search){
            if (this.page === "users") {
              this.result = response.data.data.result.users;
            } else if (this.page === "home") {
              this.result = response.data.data.result;
            }
          }else{
            this.result = null;
          }

          this.$emit("searchData", this.result);
          this.searching = false;
        })
        .catch((error) => {
          this.searching = false;
          console.log(error.response);
        });
    },

    toggleFilter: function (event) {
      const searchFilter = this.$refs.searchFilter;
      const target = event.target;
      if (searchFilter && (searchFilter === target) && searchFilter.contains(target)){
        this.showFilter = !this.showFilter;
      }else if(searchFilter && (searchFilter !== target) && !searchFilter.contains(target)){
        this.showFilter = false
      }
    },
    orderChange(){
      this.showFilter = false
    }
  },

  mounted() {},
  created() {
    document.addEventListener("click", this.toggleFilter);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.home-header {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 1rem 0 1rem 0;
}
/* for extra small screens and lower  */
@include for-extra-small {
  .home-header {
    font-size: 1.5rem;
  }
}
.menu-btn img {
  position: relative;
  transform: rotateZ(90deg);
}
.event-none{
  pointer-events: none;
}
.menu-btn {
  position: relative;
  .filter {
    position: absolute;
    width: 200px;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    display: grid;
    right: 0;
    top: 110%;
    padding: 1rem;
    p {
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 500;
    }
    form {
      width: 100%;
      margin: 0;
      height: auto;
      display: block;
      padding: 0;
      border: none;
      .field {
        border: none;
        &:last-child {
          margin: 0;
        }
        label {
          display: flex;
          align-items: center;
          cursor: pointer;
          .check {
            width: 20px;
            height: 20px;
            margin-right: 0.5rem;
            border-radius: 5px;
            border: 2px solid $border-color;
            position: relative;
            &::before {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: $border-color;
              opacity: 0;
            }
            &.checked{
              border: 2px solid $yellow;
              &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: $yellow;
                opacity: 0;
              }
            }

          }
        }
        input {
          display: none;
          &:checked {
            + label {
              .check {
                border: 2px solid $text;
                &::before {
                  background-color: $text !important;
                  transform: scale(0.8) !important;
                  border-radius: 5px;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
.search-filter-searching{
margin-left: auto;
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
span.search-filter-searching {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #fddb00;
  border-radius: 50%;
  //content: "";
  background: #0000;
  height: 15px;
  width: 15px;
  will-change: transform;
}


</style>
