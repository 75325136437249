import APIService from "../../services/api.service";

const state = {
  reviews: null,
};

const actions = {
  async getAllServiceReviews({ commit }, service_id) {
    return new Promise((resolve, reject) => {
      APIService.getServiceReviews(service_id)
        .then((response) => {
          commit("setReviews", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async createReviewAction({ commit }, data = {}) {
    return new Promise((resolve, reject) => {
      APIService.createReview(data)
        .then((response) => {
          commit("setReview", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const getters = {
  getAllReviewsFromStore: (state) => () => {
    return state.services.data;
  },
  // ...
};

const mutations = {
  setReviews(state, payload) {
    state.reviews = payload;
  },
  setReview(state, payload) {
    state.review = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
