<template>
  <main>
    <div class="review-page">
      <Loader v-if="showLoader" />
      <div class="con">
        <form class="info" @submit.prevent="createService">
          <ul>
            <div class="field">
              <div class="left">
                <div class="name">Category</div>
                <select v-model="category" @change="getSubCategoryList">
                  <option class="placeholder" value="" disabled selected>
                    Select Category
                  </option>
                  <option
                    v-for="(category, index) in categories"
                    :key="index"
                    :value="category?.id"
                  >
                    <span>{{ category.name }}</span>
                  </option>
                </select>
              </div>
            </div>
            <div class="field">
              <div class="left">
                <div class="name">Sub Category</div>
                 <select v-model="subCategory" multiple>
                <option
                  v-for="(category, index) in subCategories"
                  :key="index"
                  :value="category"
                >
                  <span>{{ category }}</span>
                </option>
                 </select>
              </div>
            </div>
            <div class="field">
              <div class="left">
                <div class="name">Location</div>
                <select v-model="location">
                  <option class="placeholder" value="" disabled selected>
                    <span>Select Location</span>
                  </option>
                  <option
                    v-for="(location, index) in locations"
                    :key="index"
                    :value="location.id"
                  >
                    <span>{{ location.name }}</span>
                  </option>
                </select>
              </div>
            </div>
            <li>
              <div class="left">
                <div class="name">Service Title</div>
                <input
                  v-model="serviceTitle"
                  type="text"
                  name=""
                  placeholder="Add title"
                  id=""
                />
              </div>
            </li>
            <li>
              <div class="left">
                <div class="name">Service Description</div>
                <textarea
                  v-model="serviceDescription"
                  name=""
                  placeholder="Be precise in service description in order to make users understand the scope of the service..."
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </li>
            <li>
              <div class="left">
                <div class="name">Add photos</div>
                <div v-if="!photos.length" class="add-photo">
                  <img src="../assets/icons/image_black_24dp.svg" alt="" />
                  <input
                    @change="handleFiles"
                    type="file"
                    name="photos"
                    id="photos"
                    data-max-size="509054"
                    ef="file"
                    multiple="multiple"
                    accept="image/gif, image/jpeg, image/png"
                  />
                  <p>Add at least 2 photos</p>
                  <label for="photos">Upload photo from device</label>
                </div>
                <div v-else class="add-photo">
                  <div class="photos">
                    <div
                      class="img-con"
                      v-for="(file, index) in photos"
                      :key="index"
                    >
                      <img :src="file.url" alt="" />
                      <div class="actions">
                        <a
                          href="javascript:void(0)"
                          @click="removeSelectedPhoto(index, file)"
                          class="action"
                        >
                          <img
                            src="../assets/icons/delete_black_24dp.svg"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <input
                    @change="handleFiles"
                    type="file"
                    name="photos"
                    id="photos"
                    ef="file"
                    data-max-size="32154"
                    multiple="multiple"
                    accept="image/gif, image/jpeg, image/png"
                  />
                  <label for="photos">Upload more photo(s)</label>
                </div>
              </div>
            </li>
          </ul>
          <div class="btn-flex">
            <a href="" class="link">Cancel</a>
            <button type="submit" class="btn btn-yellow">Add Service</button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { processFiles } from "../store/helpers";
import Loader from "../components/Loader.vue";
import router from "../router";

export default {
  name: "Add Service",
  components: { Loader },
  data() {
    return {
      category: null,
      subCategory: [],
      location: null,
      serviceTitle: "",
      serviceDescription: "",
      photos: [],
      categories: [],
      subCategories: [],
      locations: [],
      // showLoader: false,
      // user: {},
    };
  },
  validations() {
    return {
      location: { required },
      serviceTitle: { required },
      isSubmitted: false,
      errorSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      showLoader: (state) => state.modals.showLoader,

    }),
  },
  methods: {
    ...mapActions([
      "getAllCategories",
      "getAllLocations",
      "createServiceAction",
      "getAllSubCategories",
    ]),
    ...mapGetters(["isAuthenticated"]),

    async createService() {
      // this.showLoader = true;
      this.$store.commit("setLoader",true);
      let data = new FormData();
      data.append("name", this.serviceTitle);
      data.append("description", this.serviceDescription);
      data.append("user_id", Number(this.user?.id));
      data.append("location_id", Number(this.location));
      data.append("photos_count", this.photos.length);
      data.append("subcategories", this.subCategory);
      if (this.photos.length > 0) {
        data.append("thumbnail", this.photos[0]);
      }
      //Assigned to Fenn (Don't touch): fix this not to upload thumbnail again
      for (let i = 1; i <= this.photos.length; i++) {
        data.append(`photo_${i}`, this.photos[i - 1]);
      }
      this.isSubmitted = true;
      return this.createServiceAction(data)
        .then((response) => {
          // this.showLoader = false;
          this.$store.commit("setLoader",false);
          this.$toast.success(`Service ${this.serviceTitle} created`, {
            position: "top",
          });
          router.push({
            path: `/services/${response.data.data.slug}`,
          });
        })
        .catch((error) => {
          // this.showLoader = false;
          this.$store.commit("setLoader",false);
          this.$toast.error(error, {
            position: "top",
          });
          console.log(error);
        });
    },

    async handleFiles(e) {
      this.photos = await processFiles(e, this.photos);
    },

    async removeSelectedPhoto(index, file) {
      try {
        this.photos.splice(index, 1);
        this.$toast.success(`${file.name} removed`, {
          position: "top",
        });
      } catch (e) {
        this.$toast.error(`${file.name} removed`, {
          position: "top",
        });
      }
      //  this.$delete(this.finds, index)
    },

    toggleLoginModal() {
      this.$store.commit("toggleLoginModal");
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },

    async getSubCategoryList() {
      this.getAllSubCategories(Number(this.category))
        .then((response) => {
          const subCategories = response.data.data.data;
          this.$store.commit("setSubCategories", subCategories);
          this.subCategories = subCategories.map((subcategory) => {
            return { label: subcategory.name, value: subcategory.id };
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    init() {
      if (!this.isAuthenticated()) {
        this.toggleLoginModal();
      }
      this.getAllCategories(100)
        .then((response) => {
          this.categories = response.data.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });

      this.getAllLocations()
        .then((response) => {
          this.locations = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_utils.scss";
.info {
  margin-bottom: 3rem;
  ul {
    div.field {
      padding: 1rem 0;

      select {
        font-size: 1em;
        width: 100%;
        receiverground-color: $white;
        border: none;
        border-bottom: 1px solid $border-color;
        // border-radius: $border-radius;
        line-height: 0.9em;
        color: $text !important;
        margin-top: 0.3rem;
        padding-bottom: 0.5em;
        option {
          padding-left: 0 1rem;
          &.placeholder {
            color: #ccc !important;
          }
        }
      }

      #sub-category.multiselect {
        font-size: 1em;
        width: 100%;
        receiverground-color: $white;
        border: none;
        border-bottom: 1px solid $border-color;
        // border-radius: $border-radius;
        line-height: 0.9em;
        color: $text !important;
        margin-top: 0.3rem;
        padding-bottom: 0.9em;
        & div.multiselect-tags-search-wrapper {
          position: unset !important;
          & input.multiselect-tags-search {
            position: unset !important;
          }
        }
        option {
          padding-left: 0 1rem;
          &.placeholder {
            color: #ccc !important;
          }
        }
      }
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    li {
      justify-content: space-between;
      border-bottom: 1px solid $border-color;
      padding: 1rem 0;
      &:last-child {
        margin-bottom: 1rem;
        border-bottom: none;
      }
      input,
      textarea {
        width: 100%;
        receiverground-color: $white;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        font-size: 1rem;
        color: $text;
        margin-top: 0.3rem;
      }
      input {
        padding: 0 1rem;
        height: 45px;
      }
      textarea {
        padding: 1rem;
      }
      .name {
        font-size: 1rem;
        font-weight: 600;
      }
      .redirect-con {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        & > img {
          width: 15px;
        }
      }
      .redirect {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
      }

      .redirect img {
        width: 25px;
        margin-right: 1rem;
      }

      .redirect span {
        font-size: 1rem;
        text-transform: capitalize;
        line-height: 0.9;
      }
    }
  }
}
.add-photo {
  width: 100%;
  height: auto;
  margin: 0.5rem 0;
  padding: 1.5rem 0;
  border: 2px dashed $text;
  border-radius: $border-radius;
  display: grid;
  align-content: center;
  justify-items: center;
  input {
    display: none;
  }
  img {
    width: 50px;
    opacity: 0.75;
  }
  p {
    margin: 0.5rem 0;
  }
  label {
    border-bottom: 1px solid $text;
    font-weight: 500;
  }
  .photos {
    width: 100%;
    border-top: 1px solid $border-color;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    padding: 1rem;
    .img-con {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: $border-radius;
        object-position: center;
        object-fit: cover;
      }
      .actions {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5rem;
        .action {
          width: 30px;
          height: 30px;
          receiverground-color: $white;
          border-radius: 4px;
          margin-left: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.301);
          img {
            position: relative;
            transform: scale(0.6);
          }
        }
      }
    }
    .add-img {
      width: 100%;
      height: 150px;
      border-radius: $border-radius;
      border: 2px dashed $text;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
      }
    }
    @include for-large {
      grid-template-columns: repeat(3, 1fr);
      .img-con {
        height: 200px;
      }
      .add-img {
        height: 200px;
      }
    }
  }
}
.btn-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  .btn {
    width: fit-content;
  }
  > a.link {
    font-size: 1rem;
    height: fit-content;
    line-height: 1.1;
    border-bottom: 1px solid $primary-color;
  }
}
div#sub-category div.multiselect-tags div.multiselect-tags-search-wrapper {
  position: unset !important;
}
</style>
