// import { createStore } from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import modals from "./modules/modals";
import categories from "./modules/categories";
import services from "./modules/services";
import locations from "./modules/locations";
import review from "./modules/review";
import search from "./modules/search";
import roles from "./modules/roles";
import payment from "@/store/modules/payment";
import createPersistedState from "vuex-persistedstate";
import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
export default createStore(Store,{
  modules: {
    auth,
    users,
    modals,
    categories,
    services,
    locations,
    review,
    search,
    roles,
    payment
  },
  plugins: [createPersistedState()],
});
